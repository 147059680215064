import { Form, Modal, ModalProps, Spin } from 'antd';
import React, { memo } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';

import { useUpdateSection } from '../api';
import SectionForm, { SectionFormValues } from '../SectionForm';
import { Section } from '../types';

export type UpdateSectionModalPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entityType: string;
    section: Section;
  };

const UpdateSectionModal = ({
  entityType,
  section,
  onOk,
  onToggle,
  onFail,
  ...props
}: UpdateSectionModalPropTypes) => {
  const [updateSection, { loading }] = useUpdateSection(entityType);

  const [form] = Form.useForm<SectionFormValues>();

  return (
    <Modal
      title="Edit Section"
      onOk={async () => {
        try {
          onOk?.();
          await form.validateFields().then((values) => {
            return updateSection(section.key, values);
          });

          form.resetFields();
          onToggle?.();
        } catch {
          onFail?.();
        }
      }}
      width={400}
      {...props}
    >
      <Spin spinning={loading}>
        <SectionForm
          form={form}
          initialValues={section}
          entityType={entityType}
        />
      </Spin>
    </Modal>
  );
};

export default memo(UpdateSectionModal);
