import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

const UserProvider = (props) => {
  const { children } = props;

  const [user] = useState(null);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: undefined,
};

export default UserProvider;
