import React, { memo } from 'react';
import MonacoEditor from 'react-monaco-editor';

import './styles.scss';

const options = {
  selectOnLineNumbers: true,
};

const MonacoEdit = ({ defaultValue, onChange }) => {
  return (
    <MonacoEditor
      width="100%"
      height="600"
      language="javascript"
      theme="vs-dark"
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export default memo(MonacoEdit);
