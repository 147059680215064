import {
  Tree as BaseTree,
  Dropdown,
  Input,
  Spin,
  Tag,
  TreeProps,
  Typography,
} from 'antd';
import React, { Key, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { Entity } from '@marketreach/model/entity/definition/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  getApiIdField,
  getLeafs,
  useSearch,
  useTreeData,
} from '@marketreach/utils/common';

import './index.less';

const { Search } = Input;

export type TreePropTypes = TreeProps & {
  totalCountLabel?: string;
  title?: React.ReactNode;
  searchEnabled?: boolean;
  isLoading: boolean;
  entities: NormalizedEntityData[];
  entityType: string;
  containerClass: string;
  showLeafIcon: boolean;
  entityInfo: Entity;
  contextMenuComponent?: React.ReactElement<any>;
};

export const Tree = ({
  totalCountLabel,
  title,
  searchEnabled,
  contextMenuComponent,
  isLoading,
  entities = [],
  containerClass = 'tree-content',
  showLeafIcon = false,
  titleRender = (entity) => entity.title,
  entityType,
  entityInfo,
  ...baseTreeProps
}: TreePropTypes) => {
  const client = useSelectedClient();

  const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
  // The entity ID where the context menu is pointing towards
  const [contextMenuId, setContextMenuId] = useState<Key | null>(null);

  const [searchString, setSearch] = useState('');

  // Debt: Calculate data outside of this component so we can use the data there
  const [treeDataResult] = useTreeData(
    entities,
    getApiIdField(client, entityType) || 'name'
  );
  const treeData = useSearch(treeDataResult, searchString);

  const amount = searchString ? getLeafs(treeData).length : entities.length;

  const titleRenderer = (entity: any) => {
    const compiledTitle = titleRender ? titleRender(entity) : entity.title;
    if (searchString) {
      const match = compiledTitle.match(new RegExp(searchString, 'ig'));

      return compiledTitle
        .split(new RegExp(searchString, 'ig'))
        .reduce((acc: any, rec: any, index: number) => {
          return acc === null
            ? [rec]
            : [
                ...acc,
                <span className="hightlight-search">{match[index - 1]}</span>,
                rec,
              ];
        }, null);
    }
    return compiledTitle;
  };

  return (
    <Spin spinning={isLoading}>
      <div
        className={containerClass}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {title && (
          <div>
            <div className="content-tree-title">{title}</div>
            <div className="tree-total-count">
              <Typography>
                {amount ?? entities?.length ?? 0}
                {entityInfo?.label_plural || totalCountLabel || entityType}
              </Typography>
            </div>
          </div>
        )}
        {searchEnabled && (
          <>
            <Search
              placeholder="input search text"
              onSearch={setSearch}
              style={{ width: 200 }}
            />{' '}
            {searchString !== '' && (
              <Tag closable onClose={() => setSearch('')}>
                {searchString}
              </Tag>
            )}
          </>
        )}
        {!title && (
          <div className="tree-total-count">
            <Typography>
              {amount ?? entities?.length ?? 0}{' '}
              {entityInfo?.label_plural || totalCountLabel || entityType}
            </Typography>
          </div>
        )}
        <Dropdown
          overlayClassName="drop-shadow"
          overlay={
            contextMenuComponent ? (
              React.cloneElement(contextMenuComponent, {
                id: contextMenuId,
                data: entities,
                toggleVisible: () => setContextMenuVisible((prev) => !prev),
              })
            ) : (
              <></>
            )
          }
          trigger={['contextMenu']}
          visible={contextMenuVisible}
          onVisibleChange={(visible) => {
            setContextMenuVisible(visible);

            if (!visible) {
              setContextMenuId(null);
            }
          }}
        >
          <div style={{ height: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <div style={{ height, width }}>
                  <BaseTree
                    showIcon={!baseTreeProps.checkable}
                    {...baseTreeProps}
                    showLine={showLeafIcon}
                    titleRender={titleRenderer}
                    treeData={treeData}
                    height={height}
                    style={{
                      overflow: 'auto',
                    }}
                    onRightClick={({ node }) => {
                      setContextMenuId(node.key);
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </div>
        </Dropdown>
      </div>
    </Spin>
  );
};
