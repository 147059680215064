import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout, Popover, Spin, Tree } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { memo, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { CLIENTS } from '@marketreach/services/apollo/clients';
import {
  GET_ROLES,
  LINK_USER_WITH_CLIENTS,
  SET_PERMISSIONS,
  SET_ROLES,
  USERS_LIST,
  USERS_LIST_QUERY,
} from '@marketreach/services/apollo/user';
import {
  CLIENTS_GET_ALL_PERMISSION,
  hasPermission,
  SETTINGS_TREE,
} from '@marketreach/utils/permissioins';
import { sortTable } from '@marketreach/utils/products';

const UserManagement = () => {
  const [clientsLinkInProcess, setClientsLinkInProcess] = useState(false);
  const [rolesLinkInProcess, setRolesLinkInProcess] = useState(false);
  const [permissionsLinkInProcess, setPermissionsLinkInProcess] =
    useState(false);

  const { user } = useAuth0();

  const { loading, data } = useQuery(USERS_LIST, {
    fetchPolicy: 'no-cache',
  });
  const { loading: loadingClients, data: clientRes } = useQuery(CLIENTS);

  const { loading: rolesLoading, data: rolesList } = useQuery(GET_ROLES, {
    fetchPolicy: 'no-cache',
  });

  const [linkClients] = useMutation(LINK_USER_WITH_CLIENTS);
  const [setPermissions] = useMutation(SET_PERMISSIONS);
  const [setRoles] = useMutation(SET_ROLES);

  const clientsPopoverContent = (row_user) => {
    const onCheck = async (checkedKeys) => {
      setClientsLinkInProcess(true);
      await linkClients({
        variables: {
          userId: row_user.user_id,
          clients: checkedKeys,
        },
        refetchQueries: [USERS_LIST_QUERY],
        awaitRefetchQueries: true,
      });
      setClientsLinkInProcess(false);
    };

    return (
      <Spin spinning={loading || clientsLinkInProcess}>
        <Tree
          className="categories-content-tree-content"
          checkable
          defaultExpandedKeys={[]}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          checkedKeys={row_user?.app_metadata?.clients || []}
          treeData={clientRes.clients.data.map((item) => {
            return {
              key: item.apiId,
              title: item.name,
            };
          })}
          onCheck={onCheck}
        />
      </Spin>
    );
  };

  const rolesPopoverContent = (row_user) => {
    const onCheck = (checkedKeys) => {
      setRolesLinkInProcess(true);
      setRoles({
        variables: {
          userId: row_user.user_id,
          roles: checkedKeys.map(
            (role) =>
              rolesList.getRolesList.data.find((item) => item.name === role)._id
          ),
        },
        refetchQueries: [USERS_LIST_QUERY],
        awaitRefetchQueries: true,
      }).then(() => {
        setRolesLinkInProcess(false);
      });
    };

    return (
      <Spin spinning={loading || rolesLinkInProcess || rolesLoading}>
        <Tree
          className="categories-content-tree-content"
          checkable
          defaultExpandedKeys={[]}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          checkedKeys={row_user?.app_metadata?.authorization?.roles || []}
          treeData={
            rolesList?.getRolesList.data.map((role) => {
              return {
                key: role.name,
                title: role.name,
              };
            }) || []
          }
          onCheck={onCheck}
        />
      </Spin>
    );
  };

  const permissionsPopoverContent = (row_user) => {
    const onCheck = (checkedKeys) => {
      setPermissionsLinkInProcess(true);
      setPermissions({
        variables: {
          userId: row_user.user_id,
          permissions: checkedKeys,
        },
        refetchQueries: [USERS_LIST_QUERY],
        awaitRefetchQueries: true,
      }).then(() => {
        setPermissionsLinkInProcess(false);
      });
    };
    return (
      <Spin spinning={loading || permissionsLinkInProcess || rolesLoading}>
        <Tree
          className="categories-content-tree-content"
          checkable
          disabled
          defaultExpandedKeys={SETTINGS_TREE.map((item) => item.key)}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          checkedKeys={row_user?.app_metadata?.authorization?.permissions || []}
          treeData={SETTINGS_TREE}
          onCheck={onCheck}
        />
      </Spin>
    );
  };

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'picture',
      render: (text, row) => <img src={row.picture} alt={row.name} />,
    },
    {
      title: 'First Name',
      dataIndex: 'given_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'family_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Clients',
      dataIndex: 'clients',
      render: (text, row) => {
        return hasPermission(user, CLIENTS_GET_ALL_PERMISSION) ? (
          <Popover
            content={clientsPopoverContent(row)}
            trigger="click"
            title="Clients"
          >
            <button className="ant-btn-link link">
              {row?.app_metadata?.clients?.length || 0}
            </button>
          </Popover>
        ) : (
          <Text>{row?.app_metadata?.clients?.length || 0}</Text>
        );
      },
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: (text, row) => (
        <Popover
          content={rolesPopoverContent(row)}
          trigger="click"
          title="Roles"
        >
          <button className="ant-btn-link link">
            {row.app_metadata?.authorization?.roles?.join(', ') || '---'}
          </button>
        </Popover>
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      render: (text, row) => (
        <Popover
          content={permissionsPopoverContent(row)}
          trigger="click"
          title="Roles"
        >
          <button className="ant-btn-link link">
            {row.app_metadata?.authorization?.permissions?.length || 0}
          </button>
        </Popover>
      ),
    },
    {
      title: 'Last login',
      dataIndex: 'last_login',
    },
    {
      title: 'Last IP',
      dataIndex: 'last_ip',
    },
  ].map((item) => {
    item.sorter = true;
    item.key = item.dataIndex;
    item.sorter = (a, b) => {
      if (item.dataIndex === 'permissions') {
        return sortTable(
          a?.app_metadata?.authorization?.permissions.length || 0,
          b?.app_metadata?.authorization?.permissions.length || 0
        );
      }
      if (item.dataIndex === 'clients') {
        return sortTable(
          a?.app_metadata?.clients?.length || 0,
          b?.app_metadata?.clients?.length || 0
        );
      }
      return sortTable(a[item.dataIndex] ?? '', b[item.dataIndex] ?? '');
    };

    return item;
  });

  return (
    <div className="ant-pro-grid-content rules-content">
      <Layout className="rules-content-main">
        <Spin spinning={loading || loadingClients || rolesLoading}>
          {!loading && !loadingClients && (
            <CustomProTable
              className="rules-content-main-table"
              dataSource={data.getUsersList.data}
              rowKey="user_id"
              columns={columns}
              // toolbar={toolbar}
              pagination={{ defaultPageSize: 20 }}
              selectable={false}
            />
          )}
        </Spin>
      </Layout>
    </div>
  );
};

UserManagement.propTypes = {};

UserManagement.defaultProps = {};

export default memo(UserManagement);
