import { Form, InputNumber } from 'antd';
import React from 'react';

const Integer = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={parseInt(preDefinedVal, 10) || null}
      hidden={hide ?? false}
      hasFeedback
    >
      <span>
        <InputNumber
          disabled={disabled}
          readOnly={readOnly}
          type="number"
          precision={0}
          defaultValue={parseInt(preDefinedVal, 10) || null}
          onChange={onChange}
        />
      </span>
    </Form.Item>
  );
};

export default React.memo(Integer);
