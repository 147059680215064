import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Layout, message, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import CustomEntityModal from '@marketreach/pages/auth/settings/components/CustomEntityModal';
import {
  CLIENTS_ACTIONS,
  useClientsDispatch,
  useSelectedClient,
} from '@marketreach/providers/ClientsProvider';
import {
  ADD_CUSTOM_ENTITY,
  CLIENTS_QUERY,
  GET_SINGLE_CLIENT_QUERY,
  REMOVE_CUSTOM_ENTITY,
  UPDATE_CUSTOM_ENTITY,
} from '@marketreach/services/apollo/clients';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import { buildSlug } from '@marketreach/utils/common';

import './styles.scss';

export const SYSTEM_COLLECTIONS = [
  'attributes',
  'custom_entity',
  'files',
  'history',
  'product',
  'rules',
  'virtuals',
];

/**
 * Prepare columns to table display of custom entities list
 *
 * @param handleEdit
 * @param handleDelete
 * @return {({dataIndex: string, title: string}|{dataIndex: string, title: string}|{dataIndex: string, title: string}|{dataIndex: string, title: string}|{dataIndex: string, title: string})[]}
 */
const getColumns = (handleEdit, handleDelete) => [
  { title: 'Name', dataIndex: 'label' },
  { title: 'Name (Plural)', dataIndex: 'label_plural' },
  { title: 'Name (Navigation)', dataIndex: 'label_navigation' },
  { title: 'Description (optional)', dataIndex: 'description' },
  { title: 'API ID', dataIndex: 'slug' },
  { title: 'API ID Field', dataIndex: 'apiID' },
  {
    title: 'Operation',
    dataIndex: 'operation',
    sorter: false,
    width: 10,
    render: (text, row) => [
      <Button
        key="edit"
        type="text"
        shape="circle"
        onClick={() => handleEdit(row)}
      >
        <EditOutlined />
      </Button>,
      <Button
        key="delete"
        type="text"
        shape="circle"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleDelete(row);
        }}
      >
        <DeleteOutlined />
      </Button>,
    ],
  },
];

const CustomEntity = () => {
  const client = useSelectedClient();
  const clientsDispatch = useClientsDispatch();

  const initFormState = {
    label: '',
    label_plural: '',
    slug: '',
    label_navigation: '',
    description: '',
    allowTableView: true,
    allowTreeView: false,
  };

  const [clientCreationInProgress, setClientCreationInProgress] =
    useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState(undefined);
  const [entityOldSlug, setEntityOldSlug] = useState(undefined);

  const [addCustomEntity, { loading: creationLoading }] =
    useMutation(ADD_CUSTOM_ENTITY);
  const [updateCustomEntity, { loading: updateLoading }] =
    useMutation(UPDATE_CUSTOM_ENTITY);
  const [removeCustomEntity, { loading: removingLoading }] =
    useMutation(REMOVE_CUSTOM_ENTITY);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(entityToEdit);
    setEntityOldSlug(entityToEdit?.slug);
  }, [entityToEdit]);

  const handleTriggerAddEntity = () => {
    setEntityToEdit(undefined);
    form.setFieldsValue(initFormState);
    setShowAddNewModal(!showAddNewModal);
  };

  const toolbar = () => [
    <Button type="primary" onClick={handleTriggerAddEntity}>
      <PlusOutlined /> Add New
    </Button>,
  ];

  const isFormNotValid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const handleCreateCustomEntity = () => {
    if (!isFormNotValid()) {
      setClientCreationInProgress(true);

      if (entityToEdit) {
        updateCustomEntity({
          variables: {
            clientCode: client?.apiId,
            entity: form.getFieldsValue(),
            oldSlug: entityOldSlug,
          },
          refetchQueries: [
            GET_SINGLE_CLIENT_QUERY,
            ENTITY_DATA_QUERY,
            CLIENTS_QUERY,
          ],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Custom entity was updated');
            form.setFieldsValue(initFormState);
            setShowAddNewModal(false);
            setClientCreationInProgress(false);
            setEntityToEdit(undefined);
            clientsDispatch({
              type: CLIENTS_ACTIONS.setSelected,
              selectedClient: client._id,
            });
          })
          .catch(() => {
            setClientCreationInProgress(false);
          });
      } else {
        addCustomEntity({
          variables: {
            clientCode: client?.apiId,
            entity: form.getFieldsValue(),
          },
          refetchQueries: [GET_SINGLE_CLIENT_QUERY, CLIENTS_QUERY],
          awaitRefetchQueries: true,
        })
          .then(() => {
            message.info('Custom entity was created');
            form.setFieldsValue(initFormState);
            setShowAddNewModal(false);
            setClientCreationInProgress(false);
            clientsDispatch({
              type: CLIENTS_ACTIONS.setSelected,
              selectedClient: client._id,
            });
          })
          .catch(() => {
            setClientCreationInProgress(false);
          });
      }
    }
  };

  const handleDelete = (row) => {
    if (window.confirm('Do you really want to remove custom entity?')) {
      removeCustomEntity({
        variables: {
          clientCode: client?.apiId,
          slug: row.slug,
        },
        refetchQueries: [GET_SINGLE_CLIENT_QUERY, CLIENTS_QUERY],
      }).then(() => {
        message.info('Custom entity was removed');
      });
    }
  };

  const onNameChange = (ent) => {
    form.setFieldsValue({
      slug: buildSlug(ent.target.value),
      label_plural: ent.target.value,
      label_navigation: ent.target.value,
    });
    form.validateFields();
  };

  const handleEdit = (row) => {
    setEntityToEdit(row);
    setShowAddNewModal(true);
  };

  const columns = getColumns(handleEdit, handleDelete);

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4 className="capitalized">Custom entities</h4>

          <Spin spinning={creationLoading || removingLoading || updateLoading}>
            {client?.entities?.length > 0 && (
              <CustomProTable
                dataSource={client?.entities}
                columns={columns}
                toolbar={toolbar}
                onRow={(row) => ({
                  onClick: () => handleEdit(row),
                })}
                rowKey={'slug'}
              />
            )}
            {!(client?.entities?.length > 0) && (
              <Layout>
                <div className="toolbar">
                  <div className="right">{toolbar()}</div>
                </div>
              </Layout>
            )}
          </Spin>
        </div>
      </Layout>
      <CustomEntityModal
        client={client}
        entityToEdit={entityToEdit}
        form={form}
        handleCreateCustomEntity={handleCreateCustomEntity}
        onNameChange={onNameChange}
        handleTriggerAddEntity={handleTriggerAddEntity}
        clientCreationInProgress={clientCreationInProgress}
        showAddNewModal={showAddNewModal}
      />
    </div>
  );
};

CustomEntity.propTypes = {};

CustomEntity.defaultProps = {};

export default memo(CustomEntity);
