import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useContext, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import EntityGridView from '@marketreach/pages/share/entity/EntityGridView';
import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import {
  CLIENT_DATA,
  CLIENT_DATA_QUERY,
  SAVE_CLIENT_DATA,
} from '@marketreach/services/apollo/clients';
import { sortByOrder } from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

const ClientsPage = () => {
  // States and queries start
  const client = useSelectedClient();
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const { loading, data } = useQuery(CLIENT_DATA, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const [saveClientData] = useMutation(SAVE_CLIENT_DATA);

  const [showManageProperties, setShowManageProperties] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState({});

  // States and queries end

  const clientDataList = data?.clientData?.data.map((item) => {
    return { ...item.properties, _id: item._id };
  });
  console.log('clientDataList: ', clientDataList);

  const sections = (client ? client?.sections?.client || [] : [])
    .slice()
    .sort(sortByOrder);
  const columns = getSectionColumns(sections);

  const handleShowDrawer = (value) => {
    if (value) {
      setPropertyManager({
        ...propertyManager,
        type: 'client',
      });
    }
    setShowManageProperties(value);
  };

  const addItem = (value) => {
    setEntityToEdit({});
    setShowEditor(value);
  };

  const toolbar = () => {
    return (
      <div>
        <Button type="button" onClick={() => addItem(true)}>
          Add item
        </Button>
        <Button type="link" onClick={() => handleShowDrawer(true)}>
          Manage properties
        </Button>
      </div>
    );
  };

  const handleSaveClick = async (values: any) => {
    console.log('handleSaveClick values 11: ', values, entityToEdit);
    await saveClientData({
      variables: {
        id: entityToEdit?._id,
        clientCode: client?.apiId,
        data: values,
      },
      refetchQueries: [CLIENT_DATA_QUERY],
    });
  };

  const handleRowClick = (row) => {
    setEntityToEdit(row);
    setShowEditor(true);
  };

  return (
    <div className="ant-pro-grid-content client-content">
      <h1>Clients data</h1>
      <CustomProTable
        className="products-table"
        dataSource={clientDataList}
        columns={columns}
        loading={loading}
        toolbar={toolbar}
        onRow={(row) => ({
          onClick: () => handleRowClick(row),
        })}
        // pagination={{
        //   current: pageNumber,
        //   total: totalCount,
        //   pageSize,
        //   showSizeChanger: true,
        //   onChange: (page, size) => {
        //     handlePageChange(page, size);
        //   },
        //   // showTotal: false,
        //   showTotal: (total, range) => (
        //     <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
        //   ),
        // }}
        searchable
        // handleExpandRow={handleExpandRow}
        // handleSearch={handleSearch}
        // handleSortChange={handleSortChange}
      />
      {showManageProperties && (
        <PropertiesManager
          visible={showManageProperties}
          entityType={'client'}
        />
      )}
      {showEditor && (
        <EntityGridView
          visible={showEditor}
          title={'Add entity'}
          entity={entityToEdit}
          type={'client'}
          handleShowGridView={addItem}
          handleSaveClick={handleSaveClick}
        />
      )}
    </div>
  );
};

ClientsPage.propTypes = {
  client: PropTypes.object,
};

ClientsPage.defaultProps = {
  client: null,
};

export default memo(ClientsPage);
