import { DownOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Empty, Menu, Pagination } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';

import CustomGallery from '@marketreach/components/gallery';
import CustomLoader from '@marketreach/components/loader';

import './styles.scss';

const ProductsImageView = (props) => {
  const {
    loading,
    showDrawer,
    nested,
    products,
    totalCount,
    pageNumber,
    pageSize,
    previewSwitch,
    productColumns,
    handleImageView,
    handlePageChange,
    handleShowDrawer,
    handleCheckNest,
    handleClickBack,
  } = props;

  const images =
    products.length > 0
      ? products.map((product) => ({
          key: product._id,
          url: product.image,
        }))
      : [];

  const onClickTableView = () => {
    handleImageView(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="2" onClick={onClickTableView}>
        Table List
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item key="1">CSV</Menu.Item>
    </Menu>
  );

  // Selected product image details (product)
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Handle image click to get product details
  const handleClickImage = (key) => {
    const selected = products.find((product) => product._id === key);
    if (selected) {
      setSelectedProduct(selected);
    }
  };

  // Back to products table view
  const onClickBack = () => {
    handleClickBack();
  };

  return (
    <div className="products-image-view-content">
      <div className="products-image-view-content-toolbar">
        <Button type="link" onClick={onClickBack}>
          <RollbackOutlined /> Back
        </Button>
        {previewSwitch}
        <Checkbox
          className="products-image-view-content-toolbar-item"
          style={{ minWidth: 200 }}
        >
          Unassigned products only
        </Checkbox>
        <Checkbox
          className="products-image-view-content-toolbar-item"
          style={{ minWidth: 150 }}
          checked={nested}
          onChange={handleCheckNest}
        >
          Group by Product ID
        </Checkbox>
        <Dropdown
          className="products-image-view-content-toolbar-item"
          overlay={menu}
        >
          <Button>
            Image View <DownOutlined />
          </Button>
        </Dropdown>
        <Dropdown
          className="products-image-view-content-toolbar-item"
          overlay={exportMenu}
        >
          <Button type="link">
            Export <DownOutlined />
          </Button>
        </Dropdown>
        {showDrawer && (
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
        )}
      </div>
      {loading ? (
        <CustomLoader loading={loading} />
      ) : (
        <>
          <Pagination
            className="products-image-view-content-pagination"
            showQuickJumper
            total={totalCount}
            current={pageNumber}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
          <div className="products-image-view-content-images">
            <div className="products-image-view-content-images-carousel">
              <CustomGallery
                selected={selectedProduct?._id}
                images={images}
                handleClickImage={handleClickImage}
              />
            </div>
            <div className="products-image-view-content-images-details">
              {selectedProduct ? (
                <>
                  <div className="products-image-view-content-images-details-item">
                    <div className="products-image-view-content-images-details-item-key">
                      Key
                    </div>
                    <div className="products-image-view-content-images-details-item-value-title">
                      Value
                    </div>
                  </div>
                  {productColumns.map((column) => (
                    <div
                      className="products-image-view-content-images-details-item"
                      key={column?.dataIndex}
                    >
                      <div className="products-image-view-content-images-details-item-key">
                        {column?.dataIndex}
                      </div>
                      <div className="products-image-view-content-images-details-item-value">
                        {typeof selectedProduct[column?.dataIndex] === 'string'
                          ? selectedProduct[column?.dataIndex]
                          : selectedProduct[column?.dataIndex]?.toString()}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <Empty
                  className="position-center"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Select a product to view details"
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ProductsImageView.propTypes = {
  loading: PropTypes.bool,
  showDrawer: PropTypes.bool,
  nested: PropTypes.bool,
  previewSwitch: PropTypes.node,
  products: PropTypes.arrayOf(Object),
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  productColumns: PropTypes.arrayOf(Object),
  handleImageView: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleShowDrawer: PropTypes.func,
  handleCheckNest: PropTypes.func,
  handleClickBack: PropTypes.func,
};

ProductsImageView.defaultProps = {
  loading: false,
  showDrawer: false,
  nested: false,
  previewSwitch: '',
  products: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 30,
  productColumns: [],
  handleImageView: () => {},
  handlePageChange: () => {},
  handleShowDrawer: () => {},
  handleCheckNest: () => {},
  handleClickBack: () => {},
};

export default memo(ProductsImageView);
