import { ApartmentOutlined, TableOutlined } from '@ant-design/icons';
import { Menu, Row } from 'antd';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getEntityInfo } from '@marketreach/model/entity/definition/dataAccess';
import TableView from '@marketreach/pages/entities/tabs/TableView';
import TreeView from '@marketreach/pages/entities/tabs/TreeView';
import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import { getApiIdField, sortByOrder } from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

import './styles.scss';

export const ENTITY_PAGE_SIMPLE_VIEW = 'simple';
export const ENTITY_PAGE_TREE_VIEW = 'tree';

const EntitiesPage = () => {
  const params = useParams<'type'>();
  const type = params.type ?? '';

  const client = useSelectedClient();
  const sections =
    client &&
    ('sections' in client ? client.sections[type as string] || [] : [])
      .slice()
      .sort(sortByOrder);

  const entityInfo = useMemo(
    () => getEntityInfo(client?.entities, type),
    [client?.entities, type]
  );
  const apiIdField = useMemo(() => getApiIdField(client, type), [client, type]);

  // Whether we should prompt them to select an API ID
  const [showInvite, setShowInvite] = useState(false);

  /**
   * Check if tab enabled to current entity type
   *
   * @param {string} tabName
   * @return {boolean|boolean}
   */
  const allowTab = (tabName: string) => {
    switch (tabName) {
      case ENTITY_PAGE_SIMPLE_VIEW:
        return entityInfo?.allowTableView || false;
      case ENTITY_PAGE_TREE_VIEW:
        return entityInfo?.allowTreeView || false;
      default:
        return false;
    }
  };

  /**
   * Check allowed tab to set as default
   */
  const defaultSelectedTab = () => {
    if (allowTab(ENTITY_PAGE_SIMPLE_VIEW)) {
      return ENTITY_PAGE_SIMPLE_VIEW;
    } else if (allowTab(ENTITY_PAGE_TREE_VIEW)) {
      return ENTITY_PAGE_TREE_VIEW;
    }
  };

  // Current mode SIMPLE or TREE
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab());

  /**
   * Check if we switch to another entity type
   */
  useEffect(() => {
    setSelectedTab(defaultSelectedTab());
  }, [type]);

  /* ---------------------------- Handle Properties --------------------------- */
  const [showManageProperties, setShowManageProperties] = useState(false);
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );
  useEffect(() => {
    if (!showManageProperties) {
      const baseColumns = getSectionColumns(sections);
      if (baseColumns.length === 0) {
        setShowInvite(true);
      } else {
        setShowInvite(false);
      }
    } else {
      setShowInvite(false);
    }
  }, [sections, setShowInvite, showManageProperties]);

  const handleShow = (value: boolean) => {
    setShowManageProperties(value);
  };

  /**
   * Show Manage properties
   *
   * @param value
   */
  const handleShowDrawer = (value: boolean) => {
    if (value) {
      setPropertyManager({
        ...propertyManager,
        type,
      });
    }
    setShowManageProperties(value);
    setShowInvite(false);
  };
  /* -------------------------- End Handle Properties ------------------------- */

  return (
    <>
      <div className="ant-pro-grid-content entity-content">
        <Row align="middle" className="entities-header-panel">
          <h1>
            {entityInfo?.label_plural || entityInfo?.label || 'Entity editor'}
          </h1>

          <Menu
            onClick={(e) => setSelectedTab(e.key)}
            selectedKeys={[selectedTab]}
            mode="horizontal"
          >
            {allowTab(ENTITY_PAGE_SIMPLE_VIEW) && (
              <Menu.Item key={ENTITY_PAGE_SIMPLE_VIEW} icon={<TableOutlined />}>
                Table View
              </Menu.Item>
            )}
            {allowTab(ENTITY_PAGE_TREE_VIEW) && (
              <Menu.Item
                key={ENTITY_PAGE_TREE_VIEW}
                icon={<ApartmentOutlined />}
              >
                Tree View
              </Menu.Item>
            )}
          </Menu>
        </Row>

        {allowTab(ENTITY_PAGE_SIMPLE_VIEW) &&
          selectedTab === ENTITY_PAGE_SIMPLE_VIEW && (
            <TableView
              key={type}
              entityInfo={entityInfo}
              showInvite={showInvite}
              handleShowDrawer={handleShowDrawer}
            />
          )}
        {allowTab(ENTITY_PAGE_TREE_VIEW) &&
          selectedTab === ENTITY_PAGE_TREE_VIEW && (
            <TreeView
              key={type}
              entityInfo={entityInfo}
              showInvite={showInvite}
              handleShowDrawer={handleShowDrawer}
            />
          )}
      </div>
      {showManageProperties && (
        <PropertiesManager
          visible={showManageProperties}
          handleShow={handleShow}
          entityType={type}
          apiIdField={apiIdField}
        />
      )}
    </>
  );
};

export default memo(EntitiesPage);
