import { useQuery } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { OldTree } from '@marketreach/components/tree/OldTree';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';
import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
} from '@marketreach/utils/common';

const TaxonomyEntityTree = ({
  preview,
  onPreviewEntityChange,
  query,
  entities,
  setEntities,
  entityName,
  baseEntity,
  onRemoveAssociation,
  onAddAssociation,
}) => {
  const client = useSelectedClient();

  const [, setLoading] = useState(false);
  const [expandedKeys, onExpand] = useState([]);
  const [attributesList, setAttributesList] = useState([]);

  const {
    loading: loadingEntities,
    data: entityRes,
    refetch: refetchEntities,
  } = useQuery(query, {
    variables: { clientCode: client?.apiId },
  });

  const { data: attributesRes } = useQuery(ATTRIBUTES, {
    variables: { clientCode: client?.apiId },
    skip: !baseEntity || baseEntity?.core?.type !== ENTITY_TYPE_ATTRIBUTE,
  });

  useEffect(() => {
    if (client) {
      refetchEntities();
    }
  }, [client, refetchEntities]);

  useEffect(() => {
    const entitiesData = entityRes ? entityRes?.[entityName]?.data : [];

    if (!_.isEqual(entities, entitiesData)) {
      setEntities(entitiesData);
    }
  }, [entityRes, setEntities]);

  useEffect(() => {
    if (attributesRes && attributesRes?.attributes?.data) {
      setAttributesList(attributesRes.attributes.data);
    }
  }, [attributesRes, setAttributesList]);

  const checkedEntities = useMemo(
    () => [...new Set(baseEntity?.associations?.[entityName] || [])],
    [baseEntity, entityName]
  );

  const [previewCheckedEntities, setPreviewCheckedEntities] = useState({
    checked: [],
    halfChecked: [],
  });

  const checkChildSelected = useCallback(
    (attribute) => {
      const childs = entities.filter(
        (item) => item?.parentId === attribute._id
      );

      if (childs.length === 0) return false;

      for (const child of childs) {
        if (checkedEntities.indexOf(child._id) !== -1) {
          return true;
        }

        if (checkChildSelected(child)) {
          return true;
        }
      }

      return false;
    },
    [checkedEntities, entities]
  );

  useEffect(() => {
    setLoading(loadingEntities || false);
  }, [loadingEntities]);

  const remappedEntities = useMemo(() => {
    const mapped = entities.map((it) => ({
      name: it.name,
      ...it.core,
      _id: it._id,
    }));
    if (!preview) {
      return mapped;
    }

    let checkedCats = mapped.filter(
      (item) => checkedEntities.indexOf(item._id) !== -1
    );
    let stack = [...checkedCats];
    const stackIt = (it) => it.parentId;
    while (stack.length > 0) {
      const parents = mapped.filter((it2) =>
        stack.map(stackIt).includes(it2._id)
      );
      checkedCats = [...checkedCats, ...parents];
      stack = [...parents].filter(Boolean);
    }

    return checkedCats.filter((it, index) => checkedCats.indexOf(it) === index);
  }, [entities, preview, checkedEntities]);

  const onSelect = useCallback(() => {}, []);

  const onCheckInPreviewMode = useCallback(
    (checkedKeys) => {
      setPreviewCheckedEntities(checkedKeys);
      onPreviewEntityChange(checkedKeys);
    },
    [onPreviewEntityChange, setPreviewCheckedEntities]
  );

  const onCheck = useCallback(
    (checkedKeys, info) => {
      if (baseEntity) {
        if (preview) {
          return onCheckInPreviewMode(checkedKeys, info);
        }

        if (info?.checked) {
          onAddAssociation(checkedKeys);
        } else {
          onRemoveAssociation(checkedKeys);
        }
      }
    },
    [onCheckInPreviewMode, baseEntity, preview]
  );

  const titleRenderer = (entity) => {
    if (baseEntity && baseEntity.core.type === ENTITY_TYPE_CATEGORY) {
      // That is attributes tree
      if (entity.children.length > 0) {
        return entity.title;
      }

      const skus = entities.find((attr) => attr._id === entity.key)?.skus || [];
      return `${entity.title} (${
        _.intersection(
          [...new Set(baseEntity?.skus)],
          [...new Set(skus.flat())]
        )?.length || 0
      })`;
    }
    if (baseEntity && baseEntity.core.type === ENTITY_TYPE_ATTRIBUTE) {
      // That is categories tree
      // console.log('category tree');
      const skus = entities
        .filter(
          (cat) => cat._id === entity.key || cat.core.parentId === entity.key
        )
        .map((cat) => cat.skus)
        .flat();

      const attributesSkus = attributesList
        .filter(
          (attr) =>
            attr._id === baseEntity._id || attr.core.parentId === baseEntity._id
        )
        .map((attr) => attr.skus)
        .flat();

      // console.log('skus: ', skus);
      // console.log('attributesList: ', attributesList);

      return `${entity.title} (${
        _.intersection(skus, attributesSkus)?.length || 0
      })`;
    }
  };

  return (
    <OldTree
      checkable
      checkedKeys={preview ? previewCheckedEntities : checkedEntities}
      containerClass="entities-content-tree"
      entities={remappedEntities}
      expandedKeys={expandedKeys}
      isLoading={false}
      onCheck={onCheck}
      onExpand={onExpand}
      onSelect={onSelect}
      searchEnabled
      title={baseEntity?.name}
      totalCountLabel={`${entityName
        .slice(0, 1)
        .toUpperCase()}${entityName.slice(1)}`}
      titleRender={titleRenderer}
    />
  );
};

TaxonomyEntityTree.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  preview: PropTypes.bool,
  onPreviewEntityChange: PropTypes.func,
};

TaxonomyEntityTree.defaultProps = {
  attributes: [],
  preview: false,
  onPreviewEntityChange: () => {},
};

export default memo(TaxonomyEntityTree);
