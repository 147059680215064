import { Menu } from 'antd';
import React, { Key, useCallback } from 'react';

import DisclosureToggle from '@marketreach/components/disclosureToggle';
import AddEntityDataDrawer from '@marketreach/model/entity/data/disclosure/AddEntityDataDrawer';
import DeleteEntityDataModal from '@marketreach/model/entity/data/disclosure/DeleteEntityDataModal';
import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { getAllChildren } from '@marketreach/utils/common';

export type EntityContextMenuPropTypes = {
  // Provided by Tree component
  id?: Key;
  toggleVisible?: () => void;
  data: NormalizedEntityData[];

  // Manually provided
  entityType: string;
  label?: string;
  onExpandChildren?: (id: Key) => void;
  onCollapseChildren?: (id: Key) => void;
};

export const EntityContextMenu = React.memo(
  ({
    id,
    toggleVisible,
    data,

    entityType,
    label = 'Categories',
    onExpandChildren,
    onCollapseChildren,
  }: EntityContextMenuPropTypes) => {
    const handleExpandChildren = useCallback(() => {
      id && onExpandChildren?.(id);
      toggleVisible?.();
    }, [id, onExpandChildren, toggleVisible]);

    const handleCollapseChildren = useCallback(() => {
      id && onCollapseChildren?.(id);
      toggleVisible?.();
    }, [id, onCollapseChildren, toggleVisible]);

    return (
      <Menu>
        <DisclosureToggle
          toggler={({ onToggle }) => (
            <Menu.Item
              eventKey="bulk_create"
              key="bulk_create"
              onClick={() => {
                onToggle();
                toggleVisible?.();
              }}
            >
              Create New {label}
            </Menu.Item>
          )}
        >
          <AddEntityDataDrawer
            parentId={id?.toString()}
            entityType={entityType}
          />
        </DisclosureToggle>

        {id && (
          <>
            <Menu.Item key="expand_children" onClick={handleExpandChildren}>
              Expand Children
            </Menu.Item>
            <Menu.Item key="collapse_children" onClick={handleCollapseChildren}>
              Collapse Children
            </Menu.Item>
            <DisclosureToggle
              toggler={({ onToggle }) => (
                <Menu.Item
                  eventKey="delete"
                  key="delete"
                  onClick={() => {
                    onToggle();
                    toggleVisible?.();
                  }}
                >
                  Delete
                </Menu.Item>
              )}
            >
              <DeleteEntityDataModal
                entityType={entityType}
                id={id.toString()}
                childCountWarning={
                  getAllChildren(id.toString(), data).length ?? 0
                }
              />
            </DisclosureToggle>
          </>
        )}
      </Menu>
    );
  }
);
