import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Input, message, Typography } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PROFILE_QUERY,
  UPDATE_PROFILE,
} from '@marketreach/services/apollo/user';

import './styles.scss';

const { Text } = Typography;

const ProfileForm = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth0();

  const [formState, setFormState] = useState({
    email: user.email,
    password: '',
    newPassword: '',
    firstName: user.firstName,
    lastName: user.lastName,
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    variables: {
      email: formState.email,
      password: formState.password,
      firstName: formState.firstName,
      lastName: formState.lastName,
      newPassword: formState.newPassword,
    },
    refetchQueries: [PROFILE_QUERY],
  });

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [form] = Form.useForm();

  const [error, setError] = useState('');
  const onFinish = async (values) => {
    setFormState({
      ...formState,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.currentPassword,
      newPassword: values.newPassword,
    });
    const result = await updateProfile();

    if (result.data.updateProfile.error) {
      setError(result.data.updateProfile.error);
    } else {
      setError('');
      message.info('Updated successfully');
      // setUser(result.data.updateProfile.data[0].user);
    }
  };

  const handleSignOut = () => {
    logout({ returnTo: window.location.origin });
    // localStorage.removeItem(AUTH_TOKEN);
    // setUser(null);
    navigate('/login');
  };

  const gotoBack = () => {
    navigate(-1);
  };

  return (
    <div className="profile-content">
      <Form
        form={form}
        name="profile"
        className="profile-form"
        onFinish={onFinish}
        scrollToFirstError
      >
        {error && <Text type="danger">{error}</Text>}
        <Form.Item name="firstName" initialValue={user.given_name}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" initialValue={user.family_name}>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          initialValue={user.email}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="profile-form-button"
            onClick={gotoBack}
          >
            Back to Dashboard
          </Button>
          <Button
            type="primary"
            className="profile-form-button"
            onClick={handleSignOut}
          >
            Sign out
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(ProfileForm);
