import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, message, Modal, Radio, Select, Typography } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { PROPERTY_TYPE_IMAGE } from '@marketreach/pages/taxonomy/properties/manage/add/AddNewProperty';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import { buildSlug } from '@marketreach/utils/common';
import { getPropertyValidations } from '@marketreach/utils/properties';

import PropertySettings from './PropertySettings';
import PropertyValidation from './PropertyValidation';
import './styles.scss';

const { Option } = Select;
const { Text } = Typography;

const EditPropertyModal = (props) => {
  const { sections, open, handleCancel, data, entityType, apiIdField } = props;

  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const { toEdit: property } = propertyManager;

  const [state, setState] = useState(() => property);
  const [error, setError] = useState({
    key: false,
    label: false,
  });

  useEffect(() => {
    if (property) {
      if (!_.isEqual(property, state)) {
        setState(property);
      }
    }
  }, [property]);

  const handleError = (field, value) => {
    setError({
      ...error,
      [field]: value,
    });
  };

  const handleUnique = (field, value, fieldSettings) => {
    for (const section of sections) {
      const fields = section.properties.filter(
        (item) =>
          item.settings.key === value &&
          item.section.key !== fieldSettings.section.key &&
          item.order !== fieldSettings.order
      );

      if (fields.length > 0) {
        handleError(field, { description: 'Should be unique' });
        return false;
      }
    }
    return true;
  };

  const handleChangeState = (part, field, value, type) => {
    if (field === 'key' || field === 'label') {
      handleError(field, !value);
    }
    if (_.isEqual(state[part][field], value)) {
      return;
    }
    const newState = {
      ...state,
      [part]: {
        ...state[part],
        [field]: value,
      },
    };

    if (type) {
      newState[part][`${field}Type`] = type;
    }

    if (field === 'label') {
      // if label was changed - update apiID
      newState[part].key = buildSlug(value);
    }

    setState(newState);

    if (field === 'entity') {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'All stored data will be cleared. Do you sure with that action?',
        okText: 'Yes',
        cancelText: 'No',

        onCancel: () => {
          setState(property);
        },
      });
    }
  };

  const handleSelectSection = (value) => {
    handleError('section', false);
    const section = sections.find((sec) => sec.key === value);

    setState({
      ...state,
      // remove recursive include of settings properties
      section: { ...section, properties: [] },
    });
  };

  const [part, setPart] = useState('settings');
  const handleChangePart = (e) => {
    setPart(e.target.value);
  };

  const onOk = () => {
    if (!handleUnique('key', state.settings.key, state) || error.key) {
      return;
    }
    setError({
      key: !state.settings.key,
      label: !state.settings.label,
      section: !state.section,
    });

    if (!state.settings.key || !state.settings.label || !state.section) {
      message.error('Please input required fields');
      return;
    }

    setPropertyManager({
      ...propertyManager,
      toEdit: state,
      needSave: true,
    });
  };

  const onCancel = () => {
    handleCancel();
  };

  const validations = getPropertyValidations(property?.propertyType?.title);

  return (
    <Modal
      className="taxonomy-add-property-modal"
      title="Edit Property"
      visible={open}
      onOk={onOk}
      closeIcon={
        property?.propertyType && (
          <div className="custom-close-x">
            {property?.propertyType?.title}
            <img
              src={PROPERTY_TYPE_IMAGE.get(property?.propertyType?.title)}
              alt={property?.propertyType?.title}
            />
          </div>
        )
      }
      onCancel={onCancel}
      width={700}
    >
      <div className="section-select">
        <Select
          showSearch
          style={{
            width: 200,
            marginRight: 12,
          }}
          placeholder="Select section"
          optionFilterProp="children"
          value={state?.section?.key}
          onChange={handleSelectSection}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {sections.length > 0 &&
            _.orderBy(sections, 'name').map((section) => (
              <Option key={section.key} value={section.key}>
                {section.name}
              </Option>
            ))}
        </Select>
        {error?.section && <Text type="danger">Section is required.</Text>}
      </div>
      <Radio.Group
        className="taxonomy-add-property-modal-radio"
        value={part}
        onChange={handleChangePart}
      >
        <Radio.Button value="settings">Settings</Radio.Button>
        <Radio.Button value="validation">Validation</Radio.Button>
        <Radio.Button value="advanced">Advanced</Radio.Button>
      </Radio.Group>
      {part === 'settings' && (
        <>
          <PropertySettings
            type={property?.propertyType}
            error={error}
            state={state}
            handleChangeState={handleChangeState}
            data={data}
            entityType={entityType}
            apiIdField={apiIdField}
          />
        </>
      )}
      {part === 'validation' && (
        <>
          {validations.length > 0 &&
            validations.map((validation, index) => (
              <PropertyValidation
                key={`validation_${index}`}
                type={validation}
                state={state}
                handleChangeState={handleChangeState}
              />
            ))}
        </>
      )}
      {part === 'advanced' && (
        <Checkbox
          className="taxonomy-add-property-modal-item"
          checked={state.advanced.hideField}
          onChange={(e) =>
            handleChangeState('advanced', 'hideField', e.target.checked)
          }
        >
          <Typography className="taxonomy-add-property-modal-item-title">
            Hide field
          </Typography>
          <Typography>
            This field will only be available in the API, an not visible to
            content editors.
          </Typography>
        </Checkbox>
      )}
    </Modal>
  );
};

EditPropertyModal.propTypes = {
  sections: PropTypes.arrayOf(Object),
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  entityType: PropTypes.string,
  apiIdField: PropTypes.string,
};

EditPropertyModal.defaultProps = {
  sections: [],
  open: false,
  handleCancel: () => {},
  entityType: null,
  apiIdField: null,
};

export default memo(EditPropertyModal);
