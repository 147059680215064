import { Entity } from '../entity/definition/types';
import { Section } from '../section/types';
import { ApiId, PrimaryKey } from '../types';

/**
 * The main client data
 *
 * Each client can have many entities.
 * In database terms, an entity is a table
 * Each entity can have multiple sections. Section can have many properties.
 * In database terms, these are the columns
 */
export type Client = {
  _id: PrimaryKey;
  name: string;
  apiId: ApiId;
  /** A mapping of entity to the corresponding sections */
  sections: Record<Entity['slug'], Section[]>;
  /** Metadata of the entity itself */
  entities: Entity[];
};

export const defaultEmptyClient: Client = {
  _id: '',
  name: '',
  apiId: '',
  entities: [],
  sections: {},
};
