import { Form } from 'antd';
import React from 'react';

import EntitySelect from '@marketreach/pages/taxonomy/properties/extra/EntitySelect';

/**
 * Select relation control
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Relations = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, relationType, entity, entityLabelField, entityLabelFieldType } =
    settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal}
      hidden={hide ?? false}
      hasFeedback
    >
      <EntitySelect
        type={entity}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={preDefinedVal}
        onChange={onChange}
        multiple={relationType === 'one_to_many'}
        labelField={entityLabelField}
        labelFieldType={entityLabelFieldType}
      />
    </Form.Item>
  );
};

export default React.memo(Relations);
