import { gql } from '@apollo/client';

export const RULES = gql`
  query Rules(
    $clientCode: String!
    $ruleIds: [ID]
    $searchParams: JSON
    $sortParams: JSON
  ) {
    rules(
      clientCode: $clientCode
      ruleIds: $ruleIds
      searchParams: $searchParams
      sortParams: $sortParams
    ) {
      data
    }
  }
`;

export const RULES_QUERY = 'Rules';

export const RULES_BY_PAGE = gql`
  query GetRulesByPage(
    $clientCode: String!
    $filterParams: JSON
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    getRulesByPage(
      clientCode: $clientCode
      filterParams: $filterParams
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      data
    }
  }
`;

export const RULES_BY_PAGE_QUERY = 'GetRulesByPage';

export const CREATE_RULE = gql`
  mutation CreateRule(
    $clientCode: String!
    $categoryIds: [ID]
    $attributeIds: [ID]
    $type: String
    $basis: String
    $match: String
    $word: String
    $case_sensitive: Boolean
    $isTokenized: Boolean
    $refer: String
    $key: String
    $criteria: [String]
    $criteriaTemplate: String
    $scope: Int
    $associations: JSON
    $productIds: [String]
    $skus: [String]
    $selectedRuleId: ID
    $simpleMode: Boolean
    $advancedTree: JSON
    $advancedQuery: String
  ) {
    createRule(
      clientCode: $clientCode
      categoryIds: $categoryIds
      attributeIds: $attributeIds
      type: $type
      basis: $basis
      match: $match
      word: $word
      case_sensitive: $case_sensitive
      refer: $refer
      key: $key
      criteria: $criteria
      criteriaTemplate: $criteriaTemplate
      scope: $scope
      associations: $associations
      productIds: $productIds
      skus: $skus
      selectedRuleId: $selectedRuleId
      simpleMode: $simpleMode
      advancedTree: $advancedTree
      advancedQuery: $advancedQuery
      isTokenized: $isTokenized
    ) {
      success
      error
      data
    }
  }
`;

export const UPDATE_RULE = gql`
  mutation UpdateRule(
    $clientCode: String!
    $_id: ID!
    $type: String
    $basis: String
    $match: String
    $word: String
    $case_sensitive: Boolean
    $refer: String
    $key: String
    $criteria: [String]
    $criteriaTemplate: String
    $scope: Int
    $associations: JSON
    $productIds: [String]
    $skus: [String]
    $simpleMode: Boolean
    $advancedTree: JSON
    $advancedQuery: String
  ) {
    updateRule(
      clientCode: $clientCode
      _id: $_id
      type: $type
      basis: $basis
      match: $match
      word: $word
      case_sensitive: $case_sensitive
      refer: $refer
      key: $key
      criteria: $criteria
      criteriaTemplate: $criteriaTemplate
      scope: $scope
      associations: $associations
      productIds: $productIds
      skus: $skus
      simpleMode: $simpleMode
      advancedTree: $advancedTree
      advancedQuery: $advancedQuery
    ) {
      success
      error
      data
    }
  }
`;

export const DELETE_RULE = gql`
  mutation DeleteRule(
    $clientCode: String!
    $categoryId: ID
    $attributeId: ID
    $_id: ID!
    $hardDelete: Boolean
  ) {
    deleteRule(
      clientCode: $clientCode
      categoryId: $categoryId
      attributeId: $attributeId
      _id: $_id
      hardDelete: $hardDelete
    ) {
      success
      error
      data
    }
  }
`;

export const RECALCULATE_RULES = gql`
  mutation RecalculateRules($clientCode: String!) {
    recalculateRules(clientCode: $clientCode) {
      success
      error
      data
    }
  }
`;

export const TOTAL_RECALCULATE_RULES = gql`
  mutation TotalRecalculateRules($clientCode: String!) {
    totalRuleRecalculation(clientCode: $clientCode) {
      success
      error
      data
    }
  }
`;
