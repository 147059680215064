import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { Empty, Layout, Spin } from 'antd';
import hash from 'object-hash';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { Entity } from '@marketreach/model/entity/definition/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import { getApiIdField, normalizeEntity } from '@marketreach/utils/common';

import ManagePropertiesControl from '../../components/ManagePropertiesControl';
import { EntityContent } from './EntityContent';
import { LeftSider } from './LeftSider';

export type TreeViewPropTypes = {
  entityInfo: Entity;
  handleShowDrawer: (val: boolean) => void;
  showInvite: boolean;
};

/**
 * Tree view for custom entities
 * @param entityInfo
 * @param handleShowDrawer
 * @param showInvite
 * @constructor
 */
const TreeView = ({
  entityInfo,
  handleShowDrawer,
  showInvite,
}: TreeViewPropTypes) => {
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  const client = useSelectedClient();
  const { type } = useParams();

  const { loading, data: rawData } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: 1,
      pageSize: 0,
      fullMode: false,
    },
    fetchPolicy: 'cache-and-network',
    skip: !type,
  });

  const data: NormalizedEntityData[] = useMemo(() => {
    return rawData?.entityData?.data
      ? normalizeEntity(rawData?.entityData?.data, true)
      : [];
  }, [rawData?.entityData?.data]);

  const [collapsed, setCollapsed] = useState(false);
  const handleToggleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const apiIdField = useMemo(() => getApiIdField(client, type), [client, type]);

  const onSelect = useCallback((val) => {
    if (val.length > 0) {
      setSelectedEntityId(val[0]);
    } else {
      setSelectedEntityId(null);
    }
  }, []);

  return (
    <div className="ant-pro-grid-content">
      <Flex justifyContent="flex-end" m={10}>
        <ManagePropertiesControl
          showInvite={showInvite}
          handleShowDrawer={handleShowDrawer}
        />
      </Flex>
      <Spin spinning={loading}>
        {!apiIdField && (
          <Empty>To work with tree view, please configure api id field</Empty>
        )}
        {apiIdField && (
          <Layout style={{ height: 'calc(100vh - 150px)' }}>
            <Layout.Sider
              width={350}
              collapsedWidth={15}
              collapsible
              collapsed={collapsed}
              onCollapse={handleToggleClick}
              className="side-bar-content"
            >
              {collapsed ? (
                <div className="collapsed-sider-content" />
              ) : (
                <LeftSider
                  data={data}
                  entityInfo={entityInfo}
                  selectedKey={selectedEntityId}
                  onSelect={onSelect}
                />
              )}
            </Layout.Sider>
            <Layout.Content
              style={{ overflow: 'auto' }}
              key={`${selectedEntityId}=${hash(data.toString())}`}
            >
              <EntityContent data={data} selectedEntityId={selectedEntityId} />
            </Layout.Content>
          </Layout>
        )}
      </Spin>
    </div>
  );
};

export default TreeView;
