import { Form } from 'antd';
import React from 'react';

import MonacoEdit from '@marketreach/components/monaco';

/**
 * Code editor controll
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const CodeEdit = (props) => {
  const { preDefinedVal, hide, rules, settings, onChange } = props;
  const { key } = settings;

  /**
   * Prepare default value
   *
   * @return {string|undefined}
   */
  const getDefaultValue = () => {
    if (preDefinedVal !== null) {
      switch (typeof preDefinedVal) {
        case 'object':
          return JSON.stringify(preDefinedVal);
        case 'undefined':
          return '';
        default:
          return preDefinedVal?.toString();
      }
    }

    return '';
  };

  // Run getDefaultValue once
  const preparedDefaultValue = getDefaultValue();

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preparedDefaultValue}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(data) => {
        return data;
      }}
    >
      <MonacoEdit defaultValue={preparedDefaultValue} onChange={onChange} />
    </Form.Item>
  );
};

export default React.memo(CodeEdit);
