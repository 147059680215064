import { Table, TableProps } from 'antd';
import classnames from 'classnames';
import React, { memo } from 'react';

import { useColumnSearch } from './addons/columnSearch';
import { useDraggableRow } from './addons/draggableRow';
import { useRowSelection } from './addons/rowSelection';
import './styles.scss';

export type CustomProTablePropTypes = TableProps<any> & {
  /** Table Classname */
  className: string;
  /** ProTable toolbar configuration */
  toolbar: () => React.ReactNode;
  /** Flag that rows can be selected */
  selectable?: boolean;
  /** Flag that rows can be dragged */
  draggable?: {
    /** Sorting data handler. Called after sorting */
    handleSortedData: (oldIndex: number, newIndex: number) => void;
  };
  /** Enable search in table */
  searchable?: boolean;
};

const CustomProTable = (props: CustomProTablePropTypes) => {
  const {
    className,
    title,
    columns,
    dataSource,
    toolbar,
    selectable = true,
    draggable,
    searchable,
    ...restProps
  } = props;
  const { selectedRowKeys, ...rowSelectionProps } = useRowSelection({
    selectable,
  });
  const draggableRowProps = useDraggableRow({
    columns,
    dataSource,
    draggable,
  });
  const columnSearchProps = useColumnSearch({
    columns: draggableRowProps.columns,
    searchable,
  });

  return (
    <div className="marketReachTable">
      <div className="toolbar">
        <div className="left">
          <h3>{title}</h3>
        </div>
        <div className="right">{toolbar ? toolbar() : ''}</div>
      </div>

      <Table
        className={classnames(
          'custom-pro-table',
          selectedRowKeys.length > 0 ? '' : 'custom-pro-table-no-selected',
          className
        )}
        scroll={{ x: 'max-content' }}
        {...rowSelectionProps}
        {...draggableRowProps}
        {...columnSearchProps}
        {...restProps}
      />
    </div>
  );
};

export default memo(CustomProTable);
