import { Button, Popover, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

export type ManagePropertiesControlPropTypes = {
  showInvite: boolean;
  handleShowDrawer: (val: boolean) => void;
};

/**
 * Display "Manage properties" button with popover
 * Popover used to display to user message, that api key is yet not set
 */
const ManagePropertiesControl = (props: ManagePropertiesControlPropTypes) => {
  const { showInvite, handleShowDrawer } = props;
  return (
    <Popover
      placement="leftTop"
      title={<Text type="warning">Add property</Text>}
      content={'Please add first property'}
      visible={showInvite}
    >
      <Button type="link" onClick={() => handleShowDrawer(true)}>
        Manage properties
      </Button>
    </Popover>
  );
};

export default ManagePropertiesControl;
