import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const RadioButton = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key, list_options } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
    >
      <span>
        <Radio.Group
          buttonStyle={'solid'}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          defaultValue={preDefinedVal?.toString()}
        >
          {list_options?.map((item) => (
            <Radio.Button value={item.value?.toString()}>
              {item.icon && (
                <img className={'radioButtonIcon'} src={item.icon} />
              )}
              {item.label?.toString()}
            </Radio.Button>
          ))}
        </Radio.Group>
      </span>
    </Form.Item>
  );
};

RadioButton.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

RadioButton.defaultProps = {
  props: {},
};

export default React.memo(RadioButton);
