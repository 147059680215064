import { useQuery } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { TaxonomySidebarContextMenu } from '@marketreach/components/contextMenus/TaxonomySidebarContextMenu';
import { OldTree } from '@marketreach/components/tree/OldTree';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { normalizeEntity } from '@marketreach/utils/common';

import './styles.scss';

const SidebarEntityTree = ({
  selectedKeys,
  expandedKeys,
  handleExpandedKeys,
  handleSelectedKeys,
  handleClickSubmenu,
  entityQuery,
  entities,
  selectedEntityId,
  setSelectedEntityId,
  setEntities,
  entityName,
  bulkAction,
  setBulkAction,
}) => {
  const client = useSelectedClient();

  const [entityDataList, setEntityDataList] = useState();

  const {
    loading: loadingentities,
    data: entityRes,
    refetch: refetchEntities,
  } = useQuery(entityQuery, {
    variables: { clientCode: client?.apiId },
  });

  useEffect(() => {
    if (client) {
      refetchEntities();
    }
  }, [client, refetchEntities]);

  useEffect(() => {
    const entityData = entityRes?.[entityName]?.data ?? [];

    if (!_.isEqual(entityData, entities)) {
      setEntities(entityData);
    }
  }, [entityRes, entities]);

  const handleSelectEntity = useCallback(
    (event) => {
      setSelectedEntityId(event.node.key);
    },
    [setSelectedEntityId]
  );

  useEffect(() => {
    setEntityDataList(normalizeEntity(entities));
  }, [entities]);

  const onSelect = useCallback(
    (selectedKeysData, info) => {
      handleSelectEntity(info);
      handleSelectedKeys(selectedKeysData);
    },
    [handleSelectedKeys, handleSelectEntity]
  );

  return (
    <OldTree
      containerClass="taxonomy-sidebar-tree-content"
      entities={entityDataList}
      totalCountLabel={entityName}
      isLoading={loadingentities}
      onSelect={onSelect}
      onExpand={handleExpandedKeys}
      defaultSelectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      overlayComponent={TaxonomySidebarContextMenu}
      selectedKeys={[selectedEntityId]}
      handleClickSubmenu={handleClickSubmenu}
      showLeafIcon
      bulkAction={bulkAction}
      setBulkAction={setBulkAction}
    />
  );
};

SidebarEntityTree.propTypes = {
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  handleSelectedKeys: PropTypes.func,
  handleExpandedKeys: PropTypes.func,
  handleClickSubmenu: PropTypes.func,
};

SidebarEntityTree.defaultProps = {
  selectedKeys: [],
  expandedKeys: [],
  handleSelectedKeys: () => {},
  handleExpandedKeys: () => {},
  handleClickSubmenu: () => {},
};

export default memo(SidebarEntityTree);
