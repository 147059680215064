import { Table, TableProps } from 'antd';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import { useState } from 'react';

import { CustomProTablePropTypes } from '../';

export type UseRowSelectionProps = Pick<CustomProTablePropTypes, 'selectable'>;

export const useRowSelection = ({
  selectable,
}: UseRowSelectionProps): Partial<TableProps<any>> & {
  selectedRowKeys: Key[];
} => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter(
            (key, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return {
    rowSelection: selectable ? rowSelection : undefined,
    selectedRowKeys,
  };
};
