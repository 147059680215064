import { useMutation } from '@apollo/client';
import { message, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { PropertyManagerContext } from '@marketreach/providers/PropertyManagerProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTE_QUERY_NAME } from '@marketreach/services/apollo/attributes';
import { CATEGORY_QUERY_NAME } from '@marketreach/services/apollo/categories';
import {
  CLIENTS_QUERY,
  GET_SINGLE_CLIENT_QUERY,
  UPDATE_CLIENTS,
} from '@marketreach/services/apollo/clients';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import { CREATE_HISTORY } from '@marketreach/services/apollo/history';
import { capitalize, sortByOrder } from '@marketreach/utils/common';

import ManageProperties from './manage/ManageProperties';
import './styles.scss';

const PropertiesManager = (props) => {
  const { visible, handleShow, entityType, apiIdField } = props;
  const client = useSelectedClient();
  const { selectedType } = useContext(TaxonomyContext);
  const { propertyManager, setPropertyManager } = useContext(
    PropertyManagerContext
  );

  const { type: pmType } = propertyManager;

  const type = entityType || pmType || selectedType;

  const [updateClients, { loading: loadSaveClients }] =
    useMutation(UPDATE_CLIENTS);

  const [createHistory] = useMutation(CREATE_HISTORY);
  const [showManage, setShowManage] = useState(visible);

  const handleShowDrawer = (value) => {
    setShowManage(value);
    handleShow(value);
  };

  useEffect(() => {
    setShowManage(visible);
  }, [visible, setShowManage]);

  const propertiesActions = [];

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  const saveSections = (newSections) => {
    const clientSections = JSON.parse(JSON.stringify(client.sections));

    clientSections[type] = newSections.sort(sortByOrder);
    const newClient = {
      ...client,
      sections: clientSections,
    };

    updateClients({
      variables: { clients: [newClient], propertiesActions },
      refetchQueries: [
        GET_SINGLE_CLIENT_QUERY,
        ATTRIBUTE_QUERY_NAME,
        CATEGORY_QUERY_NAME,
        ENTITY_DATA_QUERY,
        CLIENTS_QUERY,
      ],
      awaitRefetchQueries: true,
    })
      .then((data) => {
        if (data?.data?.updateClients?.success === true) {
          message.info('Updated successfully');
        } else {
          message.error(
            `Error on update: ${data?.data?.updateClients?.error}`,
            process.env.REACT_APP_MESSAGE_ERROR_TIMING
          );
        }
        return true;
      })
      .catch((e) => {
        message.error(e.toString());
      });
  };

  const handleAddProperty = (property) => {
    const sectionOrder = property.section.order;
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperty = {
      ...property,
      order: newSections[sectionIndex].properties.length,
    };
    newSections[sectionIndex].properties = [
      ...newSections[sectionIndex].properties,
      newProperty,
    ];

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          property?.settings?.label
        } was added in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toAdd && propertyManager.needAdd) {
      handleAddProperty(propertyManager.toAdd);
      setPropertyManager({
        ...propertyManager,
        needAdd: false,
        toAdd: null,
      });
    }
  }, [propertyManager.toAdd, propertyManager.needAdd]);

  const handleDeleteProperty = (sectionOrder, propertyOrder) => {
    const sectionIndex = sections.findIndex(
      (section) => section.order === sectionOrder
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const oldProperties = newSections[sectionIndex].properties;
    const oldProperty = oldProperties.find(
      (property) => property.order === propertyOrder
    );

    propertiesActions.push({
      action: 'delete',
      api_id: oldProperty?.settings?.key,
      type,
    });

    newSections[sectionIndex].properties = oldProperties
      .filter((property) => property.order !== propertyOrder)
      .sort(sortByOrder)
      .map((property, index) => ({
        ...property,
        order: index,
      }));

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          oldProperty?.settings?.label
        } was deleted from ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);

    setPropertyManager({
      ...propertyManager,
      toDelete: null,
    });
  };

  useEffect(() => {
    if (propertyManager.toDelete) {
      handleDeleteProperty(
        propertyManager.toDelete.sectionOrder,
        propertyManager.toDelete.propertyOrder
      );
    }
  }, [propertyManager.toDelete]);

  const getPropertyByKey = (key) => {
    for (const section of sections) {
      for (const property of section?.properties) {
        if (property?.settings?.key === key) {
          return property;
        }
      }
    }
  };

  const handleEditProperty = (newProperty) => {
    const sectionIndex = sections.findIndex(
      (section) => section.key === newProperty?.section?.key
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const newProperties = JSON.parse(
      JSON.stringify(newSections[sectionIndex].properties)
    );

    let oldProperty = getPropertyByKey(newProperty?.settings?.key);
    if (!oldProperty) {
      // Key was changed - try to get oldProperty by index
      if (
        sections[sectionIndex] &&
        sections[sectionIndex].properties[newProperty?.order]
      ) {
        oldProperty = sections[sectionIndex].properties[newProperty?.order];
      }
    }

    if (
      oldProperty &&
      oldProperty?.settings?.key !== newProperty?.settings?.key
    ) {
      propertiesActions.push({
        action: 'update',
        type,
        old_api_id: oldProperty?.settings?.key,
        new_api_id: newProperty?.settings?.key,
      });
    }

    if (
      oldProperty &&
      oldProperty?.section?.key !== newProperty?.section?.key
    ) {
      // section was changed - remove property from from previous section and add to new section
      const oldSectionIndex = sections.findIndex(
        (section) => section.key === oldProperty?.section?.key
      );

      const oldSectionProperties = sections[oldSectionIndex]?.properties || [];

      const propertyIndex = oldSectionProperties.findIndex(
        (property) => property.settings.key === oldProperty?.settings.key
      );
      newSections[oldSectionIndex].properties.splice(propertyIndex, 1);

      newProperty.order = newProperties.length;
      newProperties.push(newProperty);
    } else {
      const propertyIndex = oldProperty
        ? newProperties.findIndex(
            (property) => property.settings.key === oldProperty?.settings?.key
          )
        : newProperties.findIndex(
            (property) => property.order === oldProperty?.order
          );

      newProperties[propertyIndex] = newProperty;
    }
    newSections[sectionIndex].properties = newProperties;

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Property ${
          oldProperty?.settings?.label
        } was edited in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);
  };

  useEffect(() => {
    if (propertyManager.toEdit && propertyManager.needSave) {
      handleEditProperty(propertyManager.toEdit);
      setPropertyManager({
        ...propertyManager,
        needSave: false,
        toEdit: null,
      });
    }
  }, [propertyManager.toEdit, propertyManager.needSave]);

  const handleChangeSectionOrder = (section, delta) => {
    const newSections = JSON.parse(JSON.stringify(sections));
    const sectionIndex = sections.findIndex(
      (sec) => sec.order === section.order
    );

    const newOrder = section.order + delta;
    const originalSectionIndex = sections.findIndex(
      (sec) => sec.order === newOrder
    );
    if (originalSectionIndex !== -1) {
      newSections[sectionIndex].order = newOrder;
      newSections[originalSectionIndex].order = section.order;
    }

    createHistory({
      variables: {
        clientCode: client?.apiId,
        action: `Section order was changed from ${
          section.order
        } to ${newOrder} in ${capitalize(type)}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type,
      },
    });

    saveSections(newSections);
  };

  const handleUpdateSection = (section) => {
    const sectionIndex = sections.findIndex(
      (sec) => sec.key === (section.oldKey ? section.oldKey : section.key)
    );

    const newSections = JSON.parse(JSON.stringify(sections));
    const oldSection = newSections[sectionIndex];

    // if (section.name !== oldSection.name || section.key !== oldSection.key) {
    //   // key or name was changed - we should update all related properties\
    const properties = section.properties || oldSection.properties;
    oldSection.properties = properties.map((item) => {
      return {
        ...item,
        section: {
          ...item.section,
          name: section.name,
          key: section.key,
        },
      };
    });
    // }
    oldSection.name = section.name;
    oldSection.key = section.key;

    newSections[sectionIndex] = oldSection;

    saveSections(newSections);
  };

  return (
    <Spin spinning={loadSaveClients}>
      <ManageProperties
        handleShowDrawer={handleShowDrawer}
        visible={showManage}
        sections={sections}
        handleChangeSectionOrder={handleChangeSectionOrder}
        handleUpdateSection={handleUpdateSection}
        entityType={entityType}
        apiIdField={apiIdField}
      />
    </Spin>
  );
};

PropertiesManager.propTypes = {
  visible: PropTypes.bool,
  handleShow: PropTypes.func,
  entityType: PropTypes.string,
  apiIdField: PropTypes.string,
};

PropertiesManager.defaultProps = {
  visible: false,
  handleShow: () => {},
  entityType: null,
  apiIdField: null,
};

export default memo(PropertiesManager);
