import { MenuOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import {
  arrayMove,
  SortableContainer as sortableContainer,
  SortableContainerProps,
  SortableElement as sortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { CustomProTablePropTypes } from '../';
import { buildDataSource } from '../helpers';

export type UseDraggableRowProps = Pick<
  CustomProTablePropTypes,
  'columns' | 'dataSource' | 'draggable'
>;

export const useDraggableRow = ({
  columns,
  dataSource,
  draggable,
}: UseDraggableRowProps): Partial<TableProps<any>> => {
  const [sortedData, setSortedData] = useState(dataSource);

  useEffect(() => {
    if (draggable) {
      // Reset state when data changes
      setSortedData(dataSource);
    }
  }, [dataSource, draggable]);

  const onSortEnd: SortableContainerProps['onSortEnd'] = ({
    oldIndex,
    newIndex,
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [...(sortedData ?? [])],
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setSortedData(newData);
      draggable?.handleSortedData(oldIndex, newIndex);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = sortedData?.findIndex(
      (x) => x.apiId === restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };

  return {
    ...(draggable
      ? {
          columns: addDragColumn(columns),
          components: {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          },
          dataSource: buildDataSource(sortedData),
        }
      : { columns, dataSource: buildDataSource(dataSource) }),
  };
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'pointer',
      color: '#999',
    }}
  />
));
const SortableItem = sortableElement((props: any) => <tr {...props} />);
const SortableContainer = sortableContainer((props: any) => (
  <tbody {...props} />
));

const addDragColumn = (columns: UseDraggableRowProps['columns']) => [
  {
    title: '',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  ...(columns ?? []),
];
