import { useMutation } from '@apollo/client';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  ENTITY_DATA_QUERY,
  REMOVE_ENTITY_DATA,
  SAVE_ENTITY_DATA,
} from '@marketreach/services/apollo/entity';

type DeleteEntityVariables = { id: string; clientCode: string; type: string };

export const useDeleteEntityData = (entityType: string) => {
  const client = useSelectedClient();

  const [mutate, mutationState] = useMutation<any, DeleteEntityVariables>(
    REMOVE_ENTITY_DATA,
    {
      refetchQueries: [ENTITY_DATA_QUERY],
    }
  );

  const deleteEntity = (vars: Pick<DeleteEntityVariables, 'id'>) =>
    mutate({
      variables: { clientCode: client.apiId, type: entityType, ...vars },
    });

  return [deleteEntity, mutationState] as const;
};

type SaveEntityVariables = {
  data: Record<string, any>;
  parentId?: string;
  clientCode: string;
  type: string;
};
export const useSaveEntityData = (entityType: string) => {
  const client = useSelectedClient();

  const [mutate, mutationState] = useMutation<any, SaveEntityVariables>(
    SAVE_ENTITY_DATA,
    {
      refetchQueries: [ENTITY_DATA_QUERY],
    }
  );

  const saveEntity = (vars: Pick<SaveEntityVariables, 'data' | 'parentId'>) =>
    mutate({
      variables: { clientCode: client.apiId, type: entityType, ...vars },
    });

  return [saveEntity, mutationState] as const;
};

type UpdateEntityVariables = {
  id: string;
  data: Record<string, any>;
  clientCode: string;
  type: string;
};
export const useUpdateEntityData = (entityType: string) => {
  const client = useSelectedClient();

  const [mutate, mutationState] = useMutation<any, UpdateEntityVariables>(
    SAVE_ENTITY_DATA,
    {
      refetchQueries: [ENTITY_DATA_QUERY],
    }
  );

  const updateEntity = (vars: Pick<UpdateEntityVariables, 'id' | 'data'>) =>
    mutate({
      variables: { clientCode: client.apiId, type: entityType, ...vars },
    });

  return [updateEntity, mutationState] as const;
};
