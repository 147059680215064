import { useAuth0 } from '@auth0/auth0-react';
import React, { memo, useEffect, useRef } from 'react';

import './styles.scss';

const LoginForm = () => {
  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return isAuthenticated ? (
    <div>
      Hello {user.name}{' '}
      <button onClick={() => logout({ returnTo: window.location.origin })}>
        Log out
      </button>
    </div>
  ) : (
    <div className="login-content">
      <button onClick={loginWithRedirect}>Log in</button>
    </div>
  );
};

export default memo(LoginForm);
