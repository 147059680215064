import { useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Modal,
  ModalProps,
  Row,
  Select,
  Spin,
  Transfer,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import { normalizeEntity } from '@marketreach/utils/common';

export type EntityTableViewExportModalPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> &
  EntityTableViewExportModalInnerPropTypes;

const EntityTableViewExportModal = ({
  type,
  ...props
}: EntityTableViewExportModalPropTypes) => {
  return (
    <Modal title="Export" footer={null} width={600} {...props}>
      <EntityTableViewExportModalInner type={type} onToggle={props.onToggle} />
    </Modal>
  );
};

export type EntityTableViewExportModalInnerPropTypes = {
  type: string;
  onToggle?: DisclosureToggleComponentProps['onToggle'];
};

const EntityTableViewExportModalInner = ({
  type,
  onToggle,
}: EntityTableViewExportModalInnerPropTypes) => {
  const client = useSelectedClient();
  const sections = client.sections[type];
  const entityInfo = client.entities.find((entity) => entity.slug === type);
  const propertyList = sections
    .map((section) => section.properties)
    .reduce((acc, val) => [...acc, ...val], [])
    .map((property) => ({
      key: property.settings.key,
      title: property.settings.label,
    }));

  const [form] = useForm();

  // Once the modal is loaded, we want to load the data immediately
  const { loading, data: rawData } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: 1,
      // Get all
      pageSize: 0,
    },
    fetchPolicy: 'cache-and-network',
  });

  // Which columns and what order they should take (Order TBD)
  const [targetKeys, setTargetKeys] = useState<string[]>(
    propertyList.map((val) => val.key)
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  // Then process it to array of arrays (AOA). This is supported on other formats like Excel
  const data = useMemo(() => {
    if (!rawData) return [];

    const normalizedData = normalizeEntity(rawData.entityData.data);

    const header = targetKeys.map(
      (item) => propertyList.find((property) => property.key === item)?.title
    );

    const content = normalizedData.map((entity: any) =>
      targetKeys.map((targetKey) => entity[targetKey])
    );

    return [header, ...content];
  }, [propertyList, rawData, targetKeys]);

  const fileName = useMemo(() => {
    const today = moment();
    return `${entityInfo?.slug}-${today.format('MM/DD/YYYY')}.csv`;
  }, [entityInfo?.slug]);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      initialValues={{ exportFormat: 'csv' }}
    >
      <Form.Item label="Export Format" name="exportFormat">
        <Select>
          <Select.Option value="csv">CSV</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Included Columns">
        <Transfer
          rowKey={(record) => record.key}
          dataSource={propertyList}
          titles={['Excluded', 'Included']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={setTargetKeys}
          onSelectChange={onSelectChange}
          render={(item) => item.title}
        />
      </Form.Item>

      <Row justify="center">
        <Spin spinning={loading}>
          <CSVLink data={data} target="_blank" filename={fileName}>
            <Button type="primary" onClick={onToggle}>
              Download
            </Button>
          </CSVLink>
        </Spin>
      </Row>
    </Form>
  );
};

export default EntityTableViewExportModal;
