import { Button, Divider } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import CustomDrawer from '@marketreach/components/drawer';
import PropertyItem from '@marketreach/components/section/PropertyItem';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { sortByOrder } from '@marketreach/utils/common';

import './styles.scss';

const ProductGridView = (props) => {
  const { type, title, product, visible, handleShowGridView } = props;

  const client = useSelectedClient();

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  const footer = (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button
        onClick={() => handleShowGridView(false)}
        style={{ marginRight: 8 }}
      >
        Cancel
      </Button>
    </div>
  );

  const content = (
    <div className="properties-content">
      {sections.length > 0 &&
        sections.map((section, index) => (
          <div key={`section_${index}`}>
            <Divider orientation="left">{section.name}</Divider>
            <div className="properties-fields">
              {section.properties &&
                section.properties.length > 0 &&
                section.properties
                  .slice()
                  .sort(sortByOrder)
                  .filter((item) => product && product[item?.settings?.key])
                  .map((data, indexData) => (
                    <PropertyItem
                      data={data}
                      readOnly
                      type={data?.propertyType?.title}
                      defaultValue={product[data?.settings?.key] || ''}
                      usedValues={product}
                      key={`property_item_${indexData}`}
                    />
                  ))}
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <CustomDrawer
      title={title}
      className="properties-config"
      footer={footer}
      content={content}
      visible={visible}
      handleShowDrawer={handleShowGridView}
    />
  );
};

ProductGridView.propTypes = {
  type: PropTypes.string,
  title: PropTypes.node,
  product: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  handleShowGridView: PropTypes.func,
};

ProductGridView.defaultProps = {
  type: 'product',
  title: '',
  product: null,
  visible: false,
  handleShowGridView: () => {},
};

export default memo(ProductGridView);
