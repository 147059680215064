import { Form, Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

const Text = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
    >
      <span>
        <TextArea
          autoSize={{ minRows: 1 }}
          onChange={onChange}
          defaultValue={preDefinedVal?.toString()}
          readOnly={readOnly}
          disabled={disabled}
        />
      </span>
    </Form.Item>
  );
};

export default React.memo(Text);
