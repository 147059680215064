import React, { createContext, useEffect, useMemo, useState } from 'react';

const LOCAL_STORAGE_KEY = 'taxonomy_tree';
const stateStr = localStorage.getItem(LOCAL_STORAGE_KEY);
let initialAttributeId = null;
let initialCategoryId = null;

try {
  ({
    attributeId: initialAttributeId = null,
    categoryId: initialCategoryId = null,
  } = JSON.parse(stateStr));
} catch {
  // Intentionally left empty
}

export const TaxonomyContext = createContext(null);

const TaxonomyProvider = (props) => {
  const { children } = props;
  const [selectedType, setSelectedType] = useState('category');
  const [selectedCategoryId, setSelectedCategoryId] =
    useState(initialCategoryId);
  const [selectedAttributeId, setSelectedAttributeId] =
    useState(initialAttributeId);
  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        categoryId: selectedCategoryId,
        attributeId: selectedAttributeId,
      })
    );
  }, [selectedCategoryId, selectedAttributeId]);

  const values = useMemo(() => {
    return {
      selectedType,
      setSelectedType,
      selectedCategoryId,
      setSelectedCategoryId,
      selectedAttributeId,
      setSelectedAttributeId,
      setSelectedAttribute,
      selectedAttribute,
      selectedCategory,
      setSelectedCategory,
      attributes,
      setAttributes,
      categories,
      setCategories,
      associations,
      setAssociations,
    };
  }, [
    selectedType,
    selectedCategoryId,
    selectedAttributeId,
    selectedAttribute,
    selectedCategory,
    attributes,
    categories,
    associations,
  ]);

  return (
    <TaxonomyContext.Provider value={values}>
      {children}
    </TaxonomyContext.Provider>
  );
};

export default TaxonomyProvider;
