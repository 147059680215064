import { Form, InputNumber } from 'antd';
import numeral from 'numeral';
import React from 'react';

const Decimal = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;

  const formattedData = numeral(parseFloat(preDefinedVal)).format(
    settings?.inputMask ?? '0.00'
  );

  const { key } = settings;
  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={formattedData}
      hidden={hide ?? false}
      hasFeedback
    >
      <span>
        <InputNumber
          disabled={disabled}
          readOnly={readOnly}
          type="number"
          defaultValue={formattedData}
          onChange={onChange}
          formatter={(value) =>
            numeral(value).format(settings?.inputMask ?? '0.00')
          }
        />
      </span>
    </Form.Item>
  );
};

export default React.memo(Decimal);
