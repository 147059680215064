import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from 'antd';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import TopBar from '@marketreach/components/topbar';
import LoginForm from '@marketreach/pages/auth/login/index';
import ProfileForm from '@marketreach/pages/auth/profile';
import RegistrationForm from '@marketreach/pages/auth/register';
import SettingsPage from '@marketreach/pages/auth/settings';
import ClientsPage from '@marketreach/pages/clients';
import Dashboard from '@marketreach/pages/dashboard';
import EntitiesPage from '@marketreach/pages/entities';
import FeedsPage from '@marketreach/pages/feeds';
import ProductsPage from '@marketreach/pages/products';
import RulesPage from '@marketreach/pages/rules';
import Taxonomy from '@marketreach/pages/taxonomy';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import PrivateRoute from '@marketreach/routes/PrivateRoute';
import PublicRoute from '@marketreach/routes/PublicRoute';
import {
  hasPermission,
  SETTINGS_LOCALES_MANAGE_PERMISSIONS,
} from '@marketreach/utils/permissioins';

import './App.less';

const App = (props) => {
  const { user } = useAuth0();

  const client = useSelectedClient();

  const pageProps = {
    ...props,
    user,
  };

  return (
    <div className="App">
      <TopBar />
      <Layout>
        <div className="page-content">
          <Routes>
            <Route exact path="/" element={<Navigate to="/taxonomy" />} />
            <Route
              path="/settings"
              element={
                <Navigate
                  to={`/settings/${
                    user &&
                    hasPermission(user, SETTINGS_LOCALES_MANAGE_PERMISSIONS)
                      ? 'clients'
                      : 'actions'
                  }`}
                />
              }
            />
            <Route
              exact
              path="/login"
              element={
                <PublicRoute>
                  <LoginForm {...pageProps} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/callback"
              element={
                <PublicRoute>
                  <LoginForm {...pageProps} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/sign-up"
              element={
                <PublicRoute>
                  <RegistrationForm {...pageProps} />
                </PublicRoute>
              }
            />
            <Route
              exact
              path="/settings/:type"
              element={
                <PrivateRoute>
                  <SettingsPage {...pageProps} />
                </PrivateRoute>
              }
            />
            {client && (
              <>
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <ProfileForm {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route exact path="/taxonomy">
                  <Route
                    path=":tab"
                    element={
                      <PrivateRoute>
                        <Taxonomy {...pageProps} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    index
                    element={<Navigate to="/taxonomy/properties" />}
                  />
                </Route>
                <Route
                  exact
                  path="/products"
                  element={
                    <PrivateRoute>
                      <ProductsPage {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/feeds"
                  element={
                    <PrivateRoute>
                      <FeedsPage {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/rules"
                  element={
                    <PrivateRoute>
                      <RulesPage {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/clients"
                  element={
                    <PrivateRoute>
                      <ClientsPage {...pageProps} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/entities/:type"
                  element={
                    <PrivateRoute>
                      <EntitiesPage {...pageProps} />
                    </PrivateRoute>
                  }
                />
              </>
            )}
          </Routes>
        </div>
      </Layout>
    </div>
  );
};

export default App;
