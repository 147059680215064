import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';

/**
 * Toggle input controll
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Toggle = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, disabled } = props;
  const { key } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal?.toString()?.toLowerCase()?.trim() === 'true'}
      hidden={hide ?? false}
      hasFeedback
    >
      <Switch
        disabled={disabled}
        readOnly={readOnly}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={
          preDefinedVal?.toString()?.toLowerCase()?.trim() === 'true'
        }
      />
    </Form.Item>
  );
};

export default React.memo(Toggle);
