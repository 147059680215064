import { Button, Drawer, Form, ModalProps, Row, Space, Spin } from 'antd';
import React, { memo, useCallback } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';

import { useUpdateEntityData } from '../api';
import EntityDataForm from '../EntityDataForm';
import { NormalizedEntityData } from '../types';

export type UpdateEntityDataDrawerPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entity: NormalizedEntityData | null;
    entityType: string;
  };

const UpdateEntityDataDrawer = ({
  entity,
  entityType,
  onOk,
  onToggle,
  onFail,
  ...props
}: UpdateEntityDataDrawerPropTypes) => {
  const client = useSelectedClient();

  const [form] = Form.useForm();

  const [saveEntityData, { loading }] = useUpdateEntityData(entityType);

  const onSave = useCallback(async () => {
    try {
      onOk?.();
      await form.validateFields().then((values) => {
        return saveEntityData({ id: entity?._id ?? '', data: values });
      });

      form.resetFields();
      onToggle?.();
    } catch {
      onFail?.();
    }
  }, [entity?._id, form, onFail, onOk, onToggle, saveEntityData]);

  return (
    <Drawer
      title={`Update ${
        client.entities.find((val) => val.slug === entityType)?.label
      }`}
      onClose={onToggle}
      width={1100}
      footer={
        <>
          <Row justify="end">
            <Space>
              <Button onClick={onToggle}>Cancel</Button>
              <Button type="primary" onClick={onSave}>
                Save
              </Button>
            </Space>
          </Row>
        </>
      }
      zIndex={99}
      {...props}
    >
      <Spin spinning={loading}>
        {entity && (
          <EntityDataForm form={form} entity={entity} entityType={entityType} />
        )}
      </Spin>
    </Drawer>
  );
};

export default memo(UpdateEntityDataDrawer);
