import React, { memo } from 'react';

import OperationalEffect from './OperationalEffect';
import Payments from './Payments';
import './styles.scss';
import TotalSales from './TotalSales';
import Visits from './Visits';

const Overview = () => (
  <div className="ant-row row-content">
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <TotalSales />
    </div>
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <Visits />
    </div>
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <Payments />
    </div>
    <div className="custom-card-container ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <OperationalEffect />
    </div>
  </div>
);

export default memo(Overview);
