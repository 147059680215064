import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const { TabPane } = Tabs;

const CustomTabs = (props) => {
  const {
    className,
    tabs,
    tabContent,
    extraContent,
    handleChange: handleChangeRoot,
    activeTab,
  } = props;

  const handleChange = React.useCallback(
    (title) => {
      handleChangeRoot(tabs.find((it) => it.title === title));
    },
    [handleChangeRoot, tabs]
  );

  return (
    <div className={className}>
      <Tabs
        tabBarExtraContent={extraContent}
        onChange={handleChange}
        activeKey={activeTab}
      >
        {tabs.length > 0 &&
          tabs.map((tab) => {
            return (
              <TabPane
                active={tab.url === activeTab}
                tab={tab.title}
                key={tab.title}
              >
                {tabContent}
              </TabPane>
            );
          })}
      </Tabs>
    </div>
  );
};

CustomTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tabContents: PropTypes.arrayOf(PropTypes.any),
  tabContent: PropTypes.node,
  extraContent: PropTypes.node,
  handleChange: PropTypes.func,
};

CustomTabs.defaultProps = {
  className: '',
  tabs: [],
  activeTab: 0,
  tabContents: [],
  tabContent: null,
  extraContent: undefined,
  handleChange: () => {},
};

export default memo(CustomTabs);
