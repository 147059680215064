import { gql } from '@apollo/client';

export const FIX_DEAD_ASSOCIATIONS = gql`
  mutation FixDeadLinks($clientCode: String!) {
    fixDeadLinks(clientCode: $clientCode) {
      success
      error
      data
    }
  }
`;
export const FIX_DEAD_ASSOCIATIONS_TOTAL = gql`
  mutation FixDeadLinksTotal {
    fixDeadLinksTotal {
      success
      error
      data
    }
  }
`;
