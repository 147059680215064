import { gql } from '@apollo/client';

export const COMPLETE_TEXT = gql`
  query CompleteText($clientCode: String!, $phrase: String!) {
    completeText(phrase: $phrase, clientCode: $clientCode) {
      data
    }
  }
`;

export const GET_OPENAI_ENGINES_LIST = gql`
  query GetOpenAIEnginesList {
    getOpenAIEnginesList {
      data
    }
  }
`;
