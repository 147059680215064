import { onError } from '@apollo/client/link/error';
import { message as antdMessage } from 'antd';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

    antdMessage.error('Sorry, an error occurred. Please try again later.');
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);

    antdMessage.error(
      'Unable to connect to the server. Please try again later.'
    );
  }
});
