import { Modal, ModalProps, Spin } from 'antd';
import React, { memo } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';

import { useDeleteSection } from '../api';
import { Section } from '../types';

export type DeleteSectionModalPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entityType: string;
    section: Section;
  };

const DeleteSectionModal = ({
  entityType,
  section,
  onOk,
  onToggle,
  onFail,
  ...props
}: DeleteSectionModalPropTypes) => {
  const [deleteSection, { loading }] = useDeleteSection(entityType);

  return (
    <Modal
      title="Delete Section"
      onOk={async () => {
        try {
          onOk?.();
          await deleteSection(section);
          onToggle?.();
        } catch {
          onFail?.();
        }
      }}
      width={400}
      {...props}
    >
      <Spin spinning={loading}>
        <div>Are sure you want to delete this section?</div>
      </Spin>
    </Modal>
  );
};

export default memo(DeleteSectionModal);
