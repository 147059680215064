import { Button, Drawer, Form, ModalProps, Row, Space, Spin } from 'antd';
import React, { memo, useCallback } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { getLabelByEntityType } from '@marketreach/utils/common';

import { useSaveEntityData } from '../api';
import EntityDataForm from '../EntityDataForm';

export type AddEntityDataDrawerPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entityType: string;
    parentId?: string;
  };

const AddEntityDataDrawer = ({
  entityType,
  parentId,
  onOk,
  onToggle,
  onFail,
  ...props
}: AddEntityDataDrawerPropTypes) => {
  const client = useSelectedClient();

  const [form] = Form.useForm();

  const [saveEntityData, { loading }] = useSaveEntityData(entityType);

  const onSave = useCallback(async () => {
    try {
      onOk?.();
      await form.validateFields().then((values) => {
        return saveEntityData({ data: values, parentId });
      });

      form.resetFields();
      onToggle?.();
    } catch {
      onFail?.();
    }
  }, [form, onFail, onOk, onToggle, parentId, saveEntityData]);

  return (
    <Drawer
      title={`Add ${getLabelByEntityType(entityType, client)}`}
      onClose={onToggle}
      width={1100}
      footer={
        <>
          <Row justify="end">
            <Space>
              <Button onClick={onToggle}>Cancel</Button>
              <Button type="primary" onClick={onSave}>
                Save
              </Button>
            </Space>
          </Row>
        </>
      }
      zIndex={99}
      {...props}
    >
      <Spin spinning={loading}>
        <EntityDataForm form={form} entity={null} entityType={entityType} />
      </Spin>
    </Drawer>
  );
};

export default memo(AddEntityDataDrawer);
