import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';

const dateFormat = 'MM/DD/YYYY HH:mm:ss';

/**
 * Market karma date picker wrapper
 *
 * @param value
 * @param defaultValue
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const MKDatePicker = ({ value, defaultValue, ...props }) => {
  return (
    <DatePicker
      {...props}
      format={dateFormat}
      defaultValue={defaultValue}
      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
    />
  );
};

/**
 * Select date control
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const DateItem = (props) => {
  const { preDefinedVal, hide, readOnly, rules, settings, onChange, disabled } =
    props;
  const { key } = settings;

  return (
    <Form.Item
      name={key}
      normalize={(val) => {
        return val?.format(dateFormat);
      }}
      initialValue={
        moment(preDefinedVal, dateFormat).isValid()
          ? moment(preDefinedVal, dateFormat)
          : null
      }
      rules={rules}
      hidden={hide ?? false}
      hasFeedback
      disabled={disabled}
    >
      <MKDatePicker
        readOnly={readOnly}
        onChange={(e) => {
          onChange(e?.format(dateFormat));
        }}
        defaultValue={preDefinedVal ? moment(preDefinedVal) : null}
      />
    </Form.Item>
  );
};

export default React.memo(DateItem);
