import { Tree as BaseTree, Dropdown, Input, Spin, Tag, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultContextMenu } from '@marketreach/components/contextMenus/DefaultContextMenu';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  getAllExpandableChildrenIds,
  getApiIdField,
  getLeafs,
  useSearch,
  useTreeData,
} from '@marketreach/utils/common';

import './index.less';

const { Search } = Input;

// This is a copy of the old tree component used for taxonomy.
// This is mostly because the difference in API & it's implication to the contextMenu
// Once those are aligned, we will remove this component
export const OldTree = ({
  totalCountLabel,
  title,
  selectedKeys,
  searchEnabled,
  overlayComponent: OverlayMenu = DefaultContextMenu,
  onSelect,
  onExpand,
  onCheck,
  isLoading,
  handleClickSubmenu,
  expandedKeys,
  entities = [],
  defaultSelectedKeys = [],
  defaultExpandedKeys = [],
  defaultCheckedKeys = [],
  containerClass = 'tree-content',
  checkedKeys = [],
  checkable = false,
  showLeafIcon = false,
  bulkAction,
  setBulkAction,
  titleRender = (entity) => entity.title,
  entityType,
  entityInfo,
}) => {
  const client = useSelectedClient();
  const [contextMenuId, setContextMenuId] = useState(null);
  const [searchString, setSearch] = useState('');

  const tryHandleContextMenuClick = useCallback(
    (e) => {
      const nodeElement = [
        e.target,
        e.target.parentElement,
        e.target.parentElement.parentElement,
      ].find((t) => t.classList.contains('ant-tree-treenode'));
      if (nodeElement) {
        setContextMenuId(nodeElement.getAttribute('data-treenode-id'));
      }
    },
    [setContextMenuId]
  );

  const [treeDataResult, treeNodesDictionary] = useTreeData(
    entities,
    getApiIdField(client, entityType) || 'name'
  );
  const treeData = useSearch(treeDataResult, searchString);

  const amount = searchString ? getLeafs(treeData).length : entities.length;

  useEffect(() => {
    if (bulkAction === null) return;
    if (bulkAction === 'collapseAll') {
      onExpand([]);
    } else if (bulkAction === 'expandAll') {
      onExpand(
        Object.values(treeNodesDictionary)
          .filter((it) => it.children.length !== 0)
          .map((c) => c.key)
      );
    }
    if (typeof setBulkAction !== 'undefined') {
      setBulkAction(null);
    }
  }, [bulkAction, onExpand, onExpand, treeNodesDictionary]);

  const defaultHandleClickSubmenu = useCallback(
    (command, id) => {
      if (command === 'select_all') {
        onCheck(
          entities.map((it) => it._id),
          { checked: true }
        );
      }

      if (command === 'deselect_all') {
        onCheck([], { checked: false });
      }

      if (command === 'collapse_children') {
        const idsTofilter = [
          id,
          ...getAllExpandableChildrenIds(treeNodesDictionary[id]),
        ];
        const newIds = expandedKeys.filter(
          (idToCheck) => idsTofilter.indexOf(idToCheck) === -1
        );
        onExpand(newIds);
      }

      if (command === 'expand_children') {
        const newIds = [
          ...new Set([
            ...expandedKeys,
            id,
            ...getAllExpandableChildrenIds(treeNodesDictionary[id]),
          ]),
        ];
        onExpand(newIds);
      }

      if (typeof handleClickSubmenu !== 'undefined') {
        return handleClickSubmenu(command, id, treeNodesDictionary[id]);
      }
    },
    [onCheck, handleClickSubmenu, onExpand, expandedKeys, treeNodesDictionary]
  );

  const props = useMemo(() => {
    const baseParams = {
      selectedKeys,
      expandedKeys,
      checkedKeys,
      onExpand: (args, event) => {
        if (!event.expanded) {
          const nids = getAllExpandableChildrenIds(
            treeNodesDictionary[event.node.key]
          );
          onExpand(args.filter((it) => nids.indexOf(it) === -1));
        } else {
          onExpand(args);
        }
      },
      onSelect,
      onCheck,
    };

    return Object.keys(baseParams).reduce((acc, rec) => {
      if (typeof baseParams[rec] === 'undefined') {
        return acc;
      }
      return {
        ...acc,
        [rec]: baseParams[rec],
      };
    }, {});
  }, [selectedKeys, expandedKeys, checkedKeys, onExpand, onSelect, onCheck]);

  const titleRenderer = (entity) => {
    const compiledTitle = titleRender ? titleRender(entity) : entity.title;
    if (searchString) {
      const match = compiledTitle.match(new RegExp(searchString, 'ig'));

      return compiledTitle
        .split(new RegExp(searchString, 'ig'))
        .reduce((acc, rec, index) => {
          return acc === null
            ? [rec]
            : [
                ...acc,
                <span className="hightlight-search">{match[index - 1]}</span>,
                rec,
              ];
        }, null);
    }
    return compiledTitle;
  };

  return (
    <div className={containerClass}>
      <Spin spinning={isLoading}>
        {title && (
          <div>
            <div className="content-tree-title">{title}</div>
            <div className="tree-total-count">
              <Typography>
                {amount ?? entities?.length ?? 0}
                {entityInfo?.label_plural || totalCountLabel || entityType}
              </Typography>
            </div>
          </div>
        )}
        {searchEnabled && (
          <>
            <Search
              placeholder="input search text"
              onSearch={setSearch}
              style={{ width: 200 }}
            />{' '}
            {searchString !== '' && (
              <Tag closable onClose={() => setSearch('')}>
                {searchString}
              </Tag>
            )}
          </>
        )}
        {!title && (
          <div className="tree-total-count">
            <Typography>
              {amount ?? entities?.length ?? 0}{' '}
              {entityInfo?.label_plural || totalCountLabel || entityType}
            </Typography>
          </div>
        )}
        <Dropdown
          overlayClassName="drop-shadow"
          overlay={
            Boolean(OverlayMenu) && (
              <OverlayMenu
                handleClickSubmenu={defaultHandleClickSubmenu}
                id={contextMenuId}
                label={entityInfo?.label ?? entityType}
              />
            )
          }
          trigger="contextMenu"
          onContextMenu={tryHandleContextMenuClick}
        >
          <BaseTree
            checkable={checkable}
            {...props}
            defaultCheckedKeys={defaultCheckedKeys}
            defaultExpandedKeys={defaultExpandedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            showIcon={!checkable}
            showLine={showLeafIcon}
            titleRender={titleRenderer}
            treeData={treeData}
            style={{
              height: '75vh',
              overflow: 'auto',
            }}
          />
        </Dropdown>
      </Spin>
    </div>
  );
};
