import {
  ClearOutlined,
  RetweetOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Layout, message, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { useEffect, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import ActionGenerator, {
  ACTION_TYPE_CLIENT_REFRESH,
  ACTION_TYPE_CLIENT_REFRESH_TOTAL,
  ACTION_TYPE_FIX_DEAD_ASSOCIATIONS,
  ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL,
  ACTION_TYPE_PRODUCT_SCHEMA_REFRESH,
  ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL,
  ACTION_TYPE_RE_APPLY_TEMPLATES,
  ACTION_TYPE_RE_APPLY_TEMPLATES_TOTAL,
  ACTION_TYPE_RULE_RECALCULATION,
  ACTION_TYPE_RULE_RECALCULATION_TOTAL,
} from '@marketreach/pages/auth/settings/actions/ActionGenerator';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  CLEAR_ALL_QUEUES,
  CLEAR_QUEUE,
  GET_QUEUE_STATS,
  RETRY_ALL_FAILED_JOBS,
  RETRY_FAILED_JOBS,
} from '@marketreach/services/apollo/queue';
import {
  QUEUE_FIX_DEAD_ASSOCIATIONS,
  QUEUE_REFRESH_CLIENT,
  QUEUE_RULES_RECALCULATION,
  QUEUE_SCHEMA_REFRESH,
  QUEUE_TEMPLATES_APPLY,
} from '@marketreach/utils/queues';

import './styles.scss';

const EXTRA_ACTIONS = {
  build(type, setLoading) {
    if (Object.keys(this.actionByQueueName).includes(type)) {
      return this.actionByQueueName[type](setLoading);
    }
    return false;
  },
  actionByQueueName: {
    [QUEUE_RULES_RECALCULATION]: (setLoading) => (
      <>
        <ActionGenerator
          type={ACTION_TYPE_RULE_RECALCULATION}
          setLoading={setLoading}
          buttonType={'default'}
        />
        <ActionGenerator
          type={ACTION_TYPE_RULE_RECALCULATION_TOTAL}
          setLoading={setLoading}
          buttonType={'default'}
        />
      </>
    ),
    [QUEUE_SCHEMA_REFRESH]: (setLoading) => (
      <>
        <ActionGenerator
          type={ACTION_TYPE_PRODUCT_SCHEMA_REFRESH}
          setLoading={setLoading}
          buttonType={'default'}
        />
        <ActionGenerator
          type={ACTION_TYPE_PRODUCT_SCHEMA_REFRESH_TOTAL}
          setLoading={setLoading}
          buttonType={'default'}
        />
      </>
    ),
    [QUEUE_TEMPLATES_APPLY]: (setLoading) => (
      <>
        <ActionGenerator
          type={ACTION_TYPE_RE_APPLY_TEMPLATES}
          setLoading={setLoading}
          buttonType={'default'}
        />
        <ActionGenerator
          type={ACTION_TYPE_RE_APPLY_TEMPLATES_TOTAL}
          setLoading={setLoading}
          buttonType={'default'}
        />
      </>
    ),
    [QUEUE_FIX_DEAD_ASSOCIATIONS]: (setLoading) => (
      <>
        <ActionGenerator
          type={ACTION_TYPE_FIX_DEAD_ASSOCIATIONS}
          setLoading={setLoading}
          buttonType={'default'}
        />
        <ActionGenerator
          type={ACTION_TYPE_FIX_DEAD_ASSOCIATIONS_TOTAL}
          setLoading={setLoading}
          buttonType={'default'}
        />
      </>
    ),
    [QUEUE_REFRESH_CLIENT]: (setLoading) => (
      <>
        <ActionGenerator
          type={ACTION_TYPE_CLIENT_REFRESH}
          setLoading={setLoading}
          buttonType={'default'}
        />
        <ActionGenerator
          type={ACTION_TYPE_CLIENT_REFRESH_TOTAL}
          setLoading={setLoading}
          buttonType={'default'}
        />
      </>
    ),
  },
};

const columns = (handleClearQueue, handleRetryFailedJobs, setExtraLoading) => [
  {
    title: 'Queue Name',
    dataIndex: 'queue',
  },
  {
    title: 'Waiting',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.waiting}</Text>,
  },
  {
    title: 'Active',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.active}</Text>,
  },
  {
    title: 'Completed',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.completed}</Text>,
  },
  {
    title: 'Failed',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.failed}</Text>,
  },
  {
    title: 'Delayed',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.delayed}</Text>,
  },
  {
    title: 'Paused',
    dataIndex: 'stat',
    render: (text, row) => <Text>{row.stat.paused}</Text>,
  },
  {
    title: 'Actions',
    dataIndex: 'stat',
    render: (text, row) => (
      <>
        <Button onClick={() => handleClearQueue(row.queue)}>
          <ClearOutlined /> Clear
        </Button>
        <Button onClick={() => handleRetryFailedJobs(row.queue)}>
          <RetweetOutlined />
          Retry failed
        </Button>
        {EXTRA_ACTIONS.build(row.queue, setExtraLoading)}
      </>
    ),
  },
];

const TaskManager = () => {
  const client = useSelectedClient();

  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    loading: queueStatsLoading,
    data,
    refetch,
  } = useQuery(GET_QUEUE_STATS, {
    variables: {
      clientCode: client?.apiId,
    },
    skip: !client,
    pollInterval: 5000,
  });

  const [clearQueue, { loading: clearQueueLoading }] = useMutation(CLEAR_QUEUE);
  const [retryFailedJobs, { loading: retryFailedJobsLoading }] =
    useMutation(RETRY_FAILED_JOBS);
  const [clearAllQueues, { loading: clearAllQueuesLoading }] =
    useMutation(CLEAR_ALL_QUEUES);
  const [retryAllFailedJobs, { loading: retryAllFailedJobsLoading }] =
    useMutation(RETRY_ALL_FAILED_JOBS);

  useEffect(() => {
    if (!queueStatsLoading && data?.getStats?.data) {
      setStats(data.getStats.data);
    }
  }, [data, setStats]);

  useEffect(() => {
    setLoading(
      queueStatsLoading ||
        clearQueueLoading ||
        clearAllQueuesLoading ||
        retryFailedJobsLoading ||
        retryAllFailedJobsLoading
    );
  }, [
    setLoading,
    queueStatsLoading,
    clearQueueLoading,
    clearAllQueuesLoading,
    retryFailedJobsLoading,
    retryAllFailedJobsLoading,
  ]);

  /**
   * Handler to call action for clear jobs in specific queue
   *
   * @param {string} queueName
   */
  const handleClearQueue = (queueName) => {
    clearQueue({
      variables: {
        queueName,
      },
    })
      .then(() => {
        refetch().then(() => {
          message.info('Queue was cleared');
        });
      })
      .catch(() => {
        message.error("Can't clear queue");
      });
  };

  /**
   * Handler to call action for retry failed jobs in specific queue
   *
   * @param {string} queueName
   */
  const handleRetryFailedJobs = (queueName) => {
    retryFailedJobs({
      variables: {
        queueName,
      },
    })
      .then(() => {
        refetch().then(() => {
          message.info('Failed jobs was added to queue');
        });
      })
      .catch(() => {
        message.error("Can't retry failed jobs");
      });
  };

  /**
   * Handler to call action for clear all jobs in all queues
   */
  const handleClearAllQueues = () => {
    clearAllQueues()
      .then(() => {
        refetch().then(() => {
          message.info('All queues was cleared');
        });
      })
      .catch(() => {
        message.error("Can't clear queues");
      });
  };

  /**
   * Handler to call action for retry failed jobs in all queues
   */
  const handleRetryAllFailedJobs = () => {
    retryAllFailedJobs()
      .then(() => {
        refetch().then(() => {
          message.info('All failed jobs was added to queue');
        });
      })
      .catch(() => {
        message.error("Can't retry all failed jobs");
      });
  };

  const setExtraLoading = (flag) => {
    refetch();
    setLoading(flag);
  };

  const refreshData = async () => {
    await refetch();
  };

  const toolbar = () => [
    <Button type="primary" onClick={refreshData}>
      <SyncOutlined spin={loading} /> Refresh
    </Button>,
    <Button type="primary" onClick={handleClearAllQueues}>
      <ClearOutlined spin={clearAllQueuesLoading} /> Clear All Queues
    </Button>,
    <Button type="primary" onClick={handleRetryAllFailedJobs}>
      <RetweetOutlined spin={retryAllFailedJobsLoading} /> Retry All Failed Jobs
    </Button>,
  ];

  return (
    <div className="ant-pro-grid-content rules-content">
      <Layout className="rules-content-main">
        <Spin spinning={loading}>
          <CustomProTable
            className="rules-content-main-table"
            dataSource={stats}
            columns={columns(
              handleClearQueue,
              handleRetryFailedJobs,
              setExtraLoading
            )}
            toolbar={toolbar}
            pagination={{ defaultPageSize: 20 }}
            selectable={false}
            rowKey={'queue'}
          />
        </Spin>
      </Layout>
    </div>
  );
};

export default TaskManager;
