import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { ColumnType, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { LegacyRef } from 'react';

export type FilterDropdownPropTypes = Pick<
  FilterDropdownProps,
  'confirm' | 'selectedKeys' | 'setSelectedKeys' | 'clearFilters'
> & {
  searchInput: LegacyRef<Input>;
  dataIndex: ColumnType<any>['dataIndex'];
};

const FilterDropDown = (props: FilterDropdownPropTypes) => {
  const {
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    searchInput,
    dataIndex,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters?.()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropDown;
