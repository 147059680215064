import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';

const Action = (props) => {
  const {
    setLoading,
    query,
    confirmationLabel,
    finishLabel,
    buttonLabel,
    buttonType,
  } = props;

  const client = useSelectedClient();

  const [runAction, { loading }] = useMutation(query);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleRunAction = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: confirmationLabel,
      okText: 'Yes',
      cancelText: 'No',

      onOk: () => {
        runAction({
          variables: {
            clientCode: client.apiId,
          },
        }).then(() => {
          message.info(finishLabel);
        });
      },
    });
  };

  return (
    <Button
      className="settings-content-rule-initiate-btn"
      type={buttonType || 'primary'}
      onClick={handleRunAction}
    >
      {buttonLabel}
    </Button>
  );
};

Action.propTypes = {
  setLoading: PropTypes.func,
  query: PropTypes.object,
  confirmationLabel: PropTypes.string,
  finishLabel: PropTypes.string,
  buttonLabel: PropTypes.object,
  buttonType: PropTypes.string,
};

Action.defaultProps = {
  setLoading: () => null,
  query: null,
  confirmationLabel: null,
  finishLabel: null,
  buttonLabel: null,
  buttonType: 'primary',
};

export default Action;
