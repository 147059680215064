import {
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Button, Radio } from 'antd';
import React, { memo, useCallback } from 'react';

import './styles.scss';

export const TaxonomySidebarHeader = memo(
  ({
    handleChangeBulkExpand,
    bulkExpand,
    selectedType,
    setOpenModal,
    onChangeState,
  }) => {
    const handleOpenModal = useCallback(() => {
      setOpenModal(true);
    }, []);

    return (
      <>
        <Radio.Group
          className="radio-group-container"
          value={selectedType}
          onChange={onChangeState}
        >
          <Radio.Button className="radio-group-button" value="category">
            Categories
          </Radio.Button>
          <Radio.Button className="radio-group-button" value="attribute">
            Attributes
          </Radio.Button>
        </Radio.Group>
        <Button
          className="categories-tree-add-btn"
          type="dashed"
          onClick={handleOpenModal}
        >
          <PlusOutlined />{' '}
          {selectedType === 'category'
            ? 'Add Root Category'
            : 'Add Attribute Group'}
        </Button>
        <Radio.Group
          className="radio-group-container radio-group-container"
          value={bulkExpand}
          onChange={handleChangeBulkExpand}
        >
          <Radio.Button
            className="radio-group-button radio-group-button-no-border"
            value="expandAll"
          >
            <PlusSquareOutlined /> Expand All
          </Radio.Button>
          <Radio.Button
            className="radio-group-button radio-group-button-no-border"
            value="collapseAll"
          >
            <MinusSquareOutlined /> Collapse All
          </Radio.Button>
        </Radio.Group>
      </>
    );
  }
);
