import { ModalProps } from 'antd';
import React, { useCallback, useState } from 'react';

/**
 * This is the minimum props the disclosure component needs to have
 */
export type DisclosureToggleComponentProps = {
  visible: ModalProps['visible'];
  confirmLoading: ModalProps['confirmLoading'];
  onCancel: () => void;
  onOk: () => void;

  /** Function to call from modal component to toggle `visible` */
  onToggle?: () => void;
  /** Function to call from modal component to cancel `confirmLoading` */
  onFail?: () => void;
};

/**
 * These props will be exposed to the toggler function
 */
export type DisclosureToggleTogglerFunctionProps = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onToggle: () => void;
};

export type DisclosureTogglePropTypes = {
  /** The function returning ReactNode that will toggle the Disclosure component */
  toggler: (prop: DisclosureToggleTogglerFunctionProps) => React.ReactNode;
  /** The Disclosure component (Eg: Modal, Drawer) */
  children: React.FunctionComponentElement<DisclosureToggleComponentProps>;
};

/**
 * This component lets us render a disclosure component alongside a custom toggler
 * Doing this offloads the `visible` state to this component, reducing unnecessary re-renders
 */
const DisclosureToggle = ({ toggler, children }: DisclosureTogglePropTypes) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onToggle = useCallback(() => {
    setVisible((prev) => !prev);
    setConfirmLoading(false);
  }, []);

  const onFail = useCallback(() => {
    setConfirmLoading(false);
  }, []);

  return (
    <>
      {toggler({ setVisible, onToggle })}
      {React.cloneElement(children, {
        visible,
        confirmLoading,
        onOk: () => {
          children.props?.onOk?.();
          setConfirmLoading(true);
        },
        onCancel: () => {
          children.props?.onCancel?.();
          setVisible(false);
        },
        onToggle,
        onFail,
      })}
    </>
  );
};

export default React.memo(DisclosureToggle);
