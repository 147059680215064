import { Radio } from 'antd';
import Text from 'antd/es/typography/Text';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { basisCell } from '@marketreach/utils/rules';

import '../../rules/Rules.styles.scss';

const ModalAutoGenerationFilter = (props) => {
  const { autoGenerationState, handleAutoGenerationChange } = props;
  return (
    <div className="rules-content-edit-settings">
      <div className="rules-content-edit-settings-field">
        <Text className="rules-content-edit-settings-label">Basis:</Text>
        <Radio.Group
          value={autoGenerationState.basis}
          onChange={(e) => handleAutoGenerationChange('basis', e.target.value)}
          buttonStyle="solid"
          className="radio-group-container"
        >
          <Radio.Button
            className="radio-group-button basis-button-include"
            value="include"
          >
            {basisCell('Include')}
          </Radio.Button>
          <Radio.Button
            className="radio-group-button basis-button-exclude"
            value="exclude"
          >
            {basisCell('Exclude')}
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="rules-content-edit-settings-field">
        <Text className="rules-content-edit-settings-label">Word:</Text>
        <Radio.Group
          buttonStyle="solid"
          className="radio-group-container"
          value={autoGenerationState.wordMatch}
          onChange={(e) =>
            handleAutoGenerationChange('wordMatch', e.target.value)
          }
        >
          <Radio.Button className="radio-group-button" value="whole">
            Whole
          </Radio.Button>
          <Radio.Button className="radio-group-button" value="partial">
            Partial
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="rules-content-edit-settings-field">
        <Text className="rules-content-edit-settings-label">
          Case Sensitive:
        </Text>
        <Radio.Group
          buttonStyle="solid"
          className="radio-group-container"
          value={autoGenerationState.caseSensitive}
          onChange={(e) =>
            handleAutoGenerationChange(
              'caseSensitive',
              e.target.value.toString() === 'true'
            )
          }
        >
          <Radio.Button className="radio-group-button" value>
            Yes
          </Radio.Button>
          <Radio.Button className="radio-group-button" value={false}>
            No
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="rules-content-edit-settings-field">
        <Text className="rules-content-edit-settings-label">Match:</Text>
        <Radio.Group
          buttonStyle="solid"
          className="radio-group-container"
          value={autoGenerationState.match}
          onChange={(e) => handleAutoGenerationChange('match', e.target.value)}
        >
          <Radio.Button className="radio-group-button" value="all">
            All
          </Radio.Button>
          <Radio.Button className="radio-group-button" value="any">
            Any
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

ModalAutoGenerationFilter.propTypes = {
  autoGenerationState: PropTypes.objectOf(PropTypes.any),
  handleAutoGenerationChange: PropTypes.func,
};

ModalAutoGenerationFilter.defaultProps = {
  autoGenerationState: {},
  handleAutoGenerationChange: () => null,
};

export default memo(ModalAutoGenerationFilter);
