import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import CustomDrawer from '@marketreach/components/drawer';
import PropertyItem from '@marketreach/components/section/PropertyItem';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { sortByOrder } from '@marketreach/utils/common';

import './styles.scss';

/**
 * Grid view for entity
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const EntityGridView = (props) => {
  const {
    type,
    title,
    entity,
    visible,
    handleShowGridView,
    handleSaveClick,
    drawer,
  } = props;

  const [form] = Form.useForm();

  const client = useSelectedClient();

  const sections = (client ? client?.sections[type] || [] : [])
    .slice()
    .sort(sortByOrder);

  const footer = (
    <Row gutter={16}>
      {!drawer && handleSaveClick && (
        <Form.Item>
          <button className="properties-save-button" type="submit">
            <SaveOutlined />
          </button>
        </Form.Item>
      )}
      {drawer && (
        <>
          <Col className="gutter-row" span={6} offset={12}>
            <Form.Item>
              <Button onClick={() => handleShowGridView(false)}>Cancel</Button>
            </Form.Item>
          </Col>
          {handleSaveClick && (
            <Col className="gutter-row">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          )}
        </>
      )}
    </Row>
  );

  /**
   * Action on form submit form
   * @param values
   */
  const onFinish = (values) => {
    handleSaveClick(values);
  };

  const content = (
    <div className="properties-content">
      {sections.length > 0 &&
        sections.map((section, index) => (
          <div key={`section_${index}`}>
            <Divider orientation="left">{section.name}</Divider>
            <div className="properties-fields">
              {section.properties &&
                section.properties.length > 0 &&
                section.properties
                  .slice()
                  .sort(sortByOrder)
                  .map((data, indexData) => (
                    <PropertyItem
                      data={data}
                      readOnly={data?.settings?.template}
                      type={data?.propertyType?.title}
                      entityType={type}
                      defaultValue={
                        entity[`${data?.settings?.key}_keys`] ||
                        entity[data?.settings?.key] ||
                        ''
                      }
                      usedValues={entity}
                      dataKey={data?.settings?.key}
                      key={`property_item_${indexData}`}
                      entityData={entity}
                    />
                  ))}
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={onFinish}
      scrollToFirstError
    >
      {drawer && (
        <CustomDrawer
          title={title}
          className="properties-config"
          footer={footer}
          content={content}
          visible={visible}
          handleShowDrawer={handleShowGridView}
        />
      )}
      {!drawer && (
        <>
          {footer}
          {content}
        </>
      )}
    </Form>
  );
};

EntityGridView.propTypes = {
  type: PropTypes.string,
  title: PropTypes.node,
  entity: PropTypes.objectOf(PropTypes.any),
  visible: PropTypes.bool,
  handleShowGridView: PropTypes.func,
  handleSaveClick: PropTypes.func,
  drawer: PropTypes.bool,
};

EntityGridView.defaultProps = {
  type: 'product',
  title: '',
  entity: {},
  visible: false,
  handleShowGridView: () => {},
  handleSaveClick: null,
  drawer: true,
};

export default memo(EntityGridView);
