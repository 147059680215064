import { FolderViewOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Layout, List, Spin } from 'antd';
import React, { memo, useCallback, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';

import { SchemaValuesView } from './components/schemaValuesView';
import './styles.scss';

const SchemaViewer = () => {
  const client = useSelectedClient();

  const [keyToView, setKeyToView] = useState();

  const { loading, data } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client?.apiId,
    },
  });

  const viewValuesHandler = (key) => {
    setKeyToView(key);
  };

  const onClose = useCallback(() => setKeyToView(null), []);

  const columns = [
    { title: 'Key', dataIndex: 'key' },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text) => (
        <List
          size="small"
          dataSource={text}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      ),
    },
    { title: 'Values Counts', dataIndex: 'valueCounts' },
    {
      title: 'View values',
      dataIndex: 'viewValues',
      render: (text, row) => (
        <Button
          type="text"
          shape="circle"
          onClick={() => viewValuesHandler(row.key)}
        >
          <FolderViewOutlined /> View values
        </Button>
      ),
    },
  ];

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4>Schema Viewer</h4>
          <Spin spinning={loading}>
            <CustomProTable
              dataSource={data?.getProductKeys?.data}
              columns={columns}
              pagination={{ defaultPageSize: 20 }}
              searchable
              title={`${data?.getProductKeys?.data?.length || 0} keys`}
            />
            <SchemaValuesView keyToView={keyToView} onClose={onClose} />
          </Spin>
        </div>
      </Layout>
    </div>
  );
};

SchemaViewer.propTypes = {};

SchemaViewer.defaultProps = {};

export default memo(SchemaViewer);
