import { Divider, Form, FormInstance, FormProps } from 'antd';
import React from 'react';

import PropertyItem from '@marketreach/components/section/PropertyItem';
import { Section } from '@marketreach/model/section/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { sortByOrder } from '@marketreach/utils/common';

import { NormalizedEntityData } from './types';

// Note: This form is slightly different from the other forms
// Data is passed through `entity` instead of `initialValues`
export type EntityDataFormPropTypes = FormProps & {
  form: FormInstance<any>;
  entity: NormalizedEntityData | null;
  entityType: string;
};

const EntityDataForm = ({
  form,
  entity,
  entityType,
  ...props
}: EntityDataFormPropTypes) => {
  const client = useSelectedClient();

  const sections = client?.sections[entityType] ?? [];

  return (
    <Form form={form} labelCol={{ span: 4 }} scrollToFirstError {...props}>
      {sections.map((section, index) => (
        <SectionPart
          key={index}
          entity={entity}
          entityType={entityType}
          section={section}
        />
      ))}
    </Form>
  );
};

export default EntityDataForm;

type SectionPartPropTypes = {
  entity: NormalizedEntityData | null;
  entityType: string;
  section: Section;
};
const SectionPart = ({ entity, entityType, section }: SectionPartPropTypes) => {
  return (
    <div>
      <Divider orientation="left">{section.name}</Divider>
      <div className="properties-fields">
        {section.properties &&
          section.properties.length > 0 &&
          section.properties
            .slice()
            .sort(sortByOrder)
            .map((data, indexData) => (
              <PropertyItem
                data={data}
                readOnly={!!data?.settings?.template}
                disabled={!!data?.settings?.template}
                type={data?.propertyType?.title}
                entityType={entityType}
                defaultValue={
                  entity?.[`${data?.settings?.key}_keys`] ||
                  entity?.[data?.settings?.key] ||
                  ''
                }
                usedValues={entity}
                dataKey={data?.settings?.key}
                key={`property_item_${indexData}`}
                entityData={entity}
              />
            ))}
      </div>
    </div>
  );
};
