import { useMutation } from '@apollo/client';
import { message } from 'antd';
import produce from 'immer';
import _ from 'lodash';

import { Section } from '@marketreach/model/section/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ATTRIBUTE_QUERY_NAME } from '@marketreach/services/apollo/attributes';
import { CATEGORY_QUERY_NAME } from '@marketreach/services/apollo/categories';
import {
  ADD_SECTION,
  CLIENTS_QUERY,
  GET_SINGLE_CLIENT_QUERY,
  UPDATE_CLIENTS,
} from '@marketreach/services/apollo/clients';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import { CREATE_HISTORY } from '@marketreach/services/apollo/history';
import { capitalize, sortByOrder } from '@marketreach/utils/common';

const clientUndefinedError = () => {
  throw new Error('Client is undefined');
};

/**
 * Notes:
 * - Client is taken from context
 */

const useSaveSections = (entityType: string) => {
  const [updateClients, mutationResults] = useMutation(UPDATE_CLIENTS);

  const client = useSelectedClient();
  if (!client) return clientUndefinedError();

  const saveSections = (newSections: Section[]) => {
    const newClient = produce(client, (draftState) => {
      draftState.sections[entityType] = [...newSections].sort(sortByOrder);
    });

    return updateClients({
      variables: { clients: [newClient] },
      refetchQueries: [
        GET_SINGLE_CLIENT_QUERY,
        ATTRIBUTE_QUERY_NAME,
        CATEGORY_QUERY_NAME,
        ENTITY_DATA_QUERY,
        CLIENTS_QUERY,
      ],
      awaitRefetchQueries: true,
    })
      .then((data) => {
        if (data?.data?.updateClients?.success === true) {
          message.info('Updated successfully');
        } else {
          message.error(
            `Error on update: ${
              data?.data?.updateClients?.error?.toString() as string
            }`,
            parseInt(process.env.REACT_APP_MESSAGE_ERROR_TIMING ?? '10', 10)
          );
        }
        return true;
      })
      .catch((e) => {
        message.error(e.toString());
      });
  };

  return [saveSections, mutationResults] as const;
};

// Improvement: Handle this with its own endpoint.
// Endpoint exist but we need to handle remapping the order on backend before this can happen
export const useDeleteSection = (entityType: string) => {
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [saveSections, mutationResults] = useSaveSections(entityType);

  const client = useSelectedClient();
  if (!client) return clientUndefinedError();

  const deleteSection = async (section: Section) => {
    const newSections = client.sections[entityType]
      .filter((sec) => sec.key !== section.key)
      // Remap section order
      .map((sec, index) => ({
        ...sec,
        order: index,
      }));

    await saveSections(newSections);

    await createHistory({
      variables: {
        clientCode: client.apiId,
        action: `Section ${section?.name} was deleted from ${capitalize(
          entityType
        )}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: entityType,
      },
    });
  };

  return [deleteSection, mutationResults] as const;
};

export const useAddSection = (entityType: string) => {
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [addSectionAPICall, mutationResults] = useMutation(ADD_SECTION);

  const client = useSelectedClient();
  if (!client) return clientUndefinedError();

  const sections = client.sections[entityType];

  const nextSectionOrder =
    !!sections && Object.keys(sections).length > 0
      ? (_.maxBy(sections, 'order')?.order ?? 0) + 1
      : 0;

  const addSection = async (section: Pick<Section, 'key' | 'name'>) => {
    const newSection: Section = {
      ...section,
      properties: [],
      order: nextSectionOrder,
    };

    await addSectionAPICall({
      variables: {
        clientCode: client.apiId,
        section: newSection,
        type: entityType,
      },
      refetchQueries: [
        GET_SINGLE_CLIENT_QUERY,
        ATTRIBUTE_QUERY_NAME,
        CATEGORY_QUERY_NAME,
        ENTITY_DATA_QUERY,
        CLIENTS_QUERY,
      ],
    });

    await createHistory({
      variables: {
        clientCode: client.apiId,
        action: `Section ${section?.name} was added in ${capitalize(
          entityType
        )}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: entityType,
      },
    });
  };

  return [addSection, mutationResults] as const;
};

export const useUpdateSection = (entityType: string) => {
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [saveSections, mutationResults] = useSaveSections(entityType);

  const client = useSelectedClient();
  if (!client) return clientUndefinedError();

  const sections = client.sections[entityType];

  const updateSection = async (
    sectionKey: Section['key'],
    newSectionData: Pick<Section, 'key' | 'name'>
  ) => {
    const oldSection: Section | undefined = sections.find(
      (sec) => sec.key === sectionKey
    );
    if (!oldSection) throw new Error('Cannot find selected section error');

    const newSection = produce(oldSection, (draftState) => {
      draftState.key = newSectionData.key;
      draftState.name = newSectionData.name;

      // Update the connection inside properties
      draftState.properties = oldSection.properties.map((item) => {
        return {
          ...item,
          section: {
            ...item.section,
            ...newSectionData,
          },
        };
      });
    });

    const newSections = sections
      .filter((sec) => sec.key !== sectionKey)
      .concat(newSection);

    await saveSections(newSections);

    await createHistory({
      variables: {
        clientCode: client.apiId,
        action: `Section ${oldSection?.name} was updated in ${capitalize(
          entityType
        )}`,
        account: 'Admin',
        ipAddress: '127.0.0.1',
        type: entityType,
      },
    });
  };

  return [updateSection, mutationResults] as const;
};
