/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, DownOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Checkbox, Dropdown, Menu, Spin } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';

import CustomProTable from '@marketreach/components/protable';
import { showTotal } from '@marketreach/components/protable/components/pagination';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  PRODUCTS_BY_PAGE,
  PRODUCTS_CSV,
} from '@marketreach/services/apollo/products';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import { sortByField, sortByOrder } from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

import ProductsImageView from './ProductImageView';
import ProductGridView from './ProductsGridView';
import './styles.scss';

const ProductsTable = (props) => {
  const {
    showManagement,
    rule,
    rules,
    skuArray,
    previewSwitch,
    handleShowDrawer,
    onDataLoad,
    onLoading,
  } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    getAccessTokenSilently()
      .then((r) => {
        return setAccessToken(r);
      })
      .catch(() => {});
  }, [getAccessTokenSilently, setAccessToken]);

  const client = useSelectedClient();
  const [keys, setKeys] = useState([]);

  const [openGridView, setOpenGridView] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleShowGridView = (show) => {
    setOpenGridView(show);
  };

  // Search values from table search form
  const [searchValues, setSearchValues] = useState(null);
  const handleClickGridView = (product) => {
    setSelectedProduct(product);
    setOpenGridView(true);
  };

  const sections = (client ? client?.sections?.product || [] : [])
    .slice()
    .sort(sortByOrder);
  const productColumns = getSectionColumns(sections);
  const columns = productColumns;

  // Products filters from search values
  const searchFilters = searchValues
    ? Object.keys(searchValues).map((key) => ({
        [key]:
          typeof searchValues[key] === 'string'
            ? {
                $regex: searchValues[key],
                $options: 'gi',
              }
            : searchValues[key],
      }))
    : [];

  const [nestedProductIds, setNestedProductIds] = useState([]);
  const [nested, setNested] = useState(false);
  const [loadProductIds, setLoadProductIds] = useState(true);
  const handleCheckNest = (e) => {
    setNested(e.target.checked);
  };

  const [expandedRow, setExpandedRow] = useState(null);
  const handleExpandRow = (expanded, row) => {
    setExpandedRow(expanded ? row : null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [orderField, setSortField] = useState('_id');
  const [orderDirection, setSortDirection] = useState('ASC');
  const [imageView, setImageView] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);

  // Image view settings
  const imageViewKey = 'product_table_image_view';
  const { data: imageViewData, loading: imageViewDataLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        key: imageViewKey,
        clientCode: client?.apiId,
      },
    }
  );
  const [setSetting, { loading: setSettingsLoading }] =
    useMutation(SET_SETTING);

  const handleImageView = async (view) => {
    await setSetting({
      variables: {
        key: imageViewKey,
        value: view,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  useEffect(() => {
    setImageView(imageViewData?.getSetting?.data?.value ?? false);
  }, [imageViewData, setImageView]);

  const andCondition = [
    skuArray?.length > 0 ? { sku: { $in: skuArray } } : {},
    ...searchFilters,
  ];

  const getAll = !(skuArray?.length > 0 || searchFilters.length > 0);

  const commonVariables = {
    clientCode: client?.apiId,
    rule:
      (rule?.simpleMode && rule?.key && rule?.criteria?.length > 0) ||
      (!rule?.simpleMode && rule?.advancedQuery)
        ? rule
        : null,
    rules,
    orderField,
    orderDirection,
  };

  const options = {
    variables: {
      ...commonVariables,
      pageNumber: currentPage,
      pageSize: rowsPerPage,
      groupByProduct: nested,
      filterParams: {
        $and: andCondition,
      },
      productIds: nested ? nestedProductIds : [],
      orderField,
      orderDirection,
      getAll,
    },
  };
  const { loading, data, refetch } = useQuery(PRODUCTS_BY_PAGE, options);

  const { data: expandedData, refetch: refetchExpanded } = useQuery(
    PRODUCTS_BY_PAGE,
    {
      variables: {
        ...commonVariables,
        pageNumber: 1,
        pageSize: 100,
        groupByProduct: false,

        filterParams: {
          $and: [
            skuArray?.length > 0 ? { sku: { $in: skuArray } } : {},
            { product_id: expandedRow?.product_id },
          ],
        },
      },
    }
  );

  useEffect(() => {
    if (expandedRow?.product_id) {
      refetchExpanded();
    }
  }, [expandedRow?.product_id]);

  useEffect(() => {
    setLoadingGlobal(imageViewDataLoading || setSettingsLoading || loading);
  }, [setSettingsLoading, imageViewDataLoading, setSettingsLoading, loading]);

  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setRowsPerPage(size);
    refetch();
  };

  const handleSortChange = (field, direction) => {
    setSortField(field);
    setSortDirection(direction);
    refetch();
  };

  const products = data ? data.getProductsByPage.data : [];

  const expandedProducts = expandedData
    ? expandedData.getProductsByPage.data
    : [];
  const totalCount = data ? data.getProductsByPage.totalCount : 0;
  const pageSize = data ? data.getProductsByPage.pageSize : rowsPerPage;
  const pageNumber = data ? data.getProductsByPage.pageNumber : currentPage;
  const productIds = data ? data.getProductsByPage.productIds : [];
  const skus = data ? data.getProductsByPage.skus : [];

  useEffect(() => {
    if (keys.length === 0 && products.length > 0) {
      setKeys(
        products.length > 0
          ? Object.keys(products[0])
              .map((key) => ({
                value: key,
                label: key,
                type: typeof products[0][key],
              }))
              .sort(sortByField('label'))
          : []
      );
    }
  }, [keys, products]);

  useEffect(() => {
    if (!loading) {
      // Call parent after new data load
      onDataLoad(productIds, skus, keys);

      if (loadProductIds === true) {
        setNestedProductIds(productIds);
        setLoadProductIds(false);
      }
    }
  }, [loading, productIds, skus, keys, loadProductIds]);

  const expandedRowRender = useCallback(
    (record) =>
      expandedRow &&
      expandedRow?._id === record?._id && (
        <CustomProTable
          key={`${record?._id}expandedRow`}
          className="expand-products-table"
          dataSource={expandedProducts}
          toolbar={false}
          columns={columns}
          onRow={(row) => ({
            onClick: () => handleClickGridView(row),
          })}
        />
      ),
    [expandedRow, expandedProducts, columns]
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleImageView(true)}>
        Image view
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URI}/reports`,
              {
                clientCode: client?.apiId,
                query: PRODUCTS_CSV,
                responseName: 'getProductsByPageReport',
                ...options,
                fields: ['product_id', 'sku', 'name'],
              },
              {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'report.csv');
              document.body.appendChild(link);
              link.click();
            });
        }}
      >
        CSV
      </Menu.Item>
    </Menu>
  );

  const toolbar = () => [
    previewSwitch,
    <Checkbox key="nest_product" onChange={handleCheckNest} checked={nested}>
      Group by Product ID
    </Checkbox>,
    <Dropdown overlay={menu} key="table_list">
      <Button>
        Table list <DownOutlined />
      </Button>
    </Dropdown>,
    <Dropdown overlay={exportMenu} key="export">
      <Button type="link">
        Export <DownOutlined />
      </Button>
    </Dropdown>,
    <>
      {showManagement && (
        <Button type="link" onClick={() => handleShowDrawer(true)}>
          Manage properties
        </Button>
      )}
    </>,
  ];

  const productDetailTitle = (
    <div className="product-detail-header">
      <div className="product-detail-header-title">
        <strong>Product Details:</strong> {selectedProduct?.displayName}
      </div>
      <>
        {showManagement && (
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
        )}
      </>
    </div>
  );

  const expandable = nested
    ? {
        onExpand: handleExpandRow,
        expandedRowRender,
        rowExpandable: () => true,
      }
    : {};

  return (
    <div className="ant-pro-grid-content">
      <Spin spinning={loadingGlobal}>
        {imageView ? (
          <>
            <div className="products-result-banner">
              <div>
                Product IDs: <strong>{productIds?.length || 0}</strong>
                <ArrowDownOutlined />
                SKUs: <strong>{skus?.length || 0}</strong>
              </div>
            </div>
            <ProductsImageView
              loading={loading}
              showDrawer={showManagement}
              nested={nested}
              products={products}
              totalCount={totalCount}
              pageNumber={currentPage}
              pageSize={rowsPerPage}
              productColumns={productColumns}
              handleImageView={handleImageView}
              handlePageChange={handlePageChange}
              handleShowDrawer={handleShowDrawer}
              handleCheckNest={handleCheckNest}
              handleClickBack={() => handleImageView(false)}
              previewSwitch={previewSwitch}
            />
          </>
        ) : (
          <div className="products-table-container">
            <div className="products-result-banner">
              <div>
                Product IDs: <strong>{productIds?.length || 0}</strong>
                <ArrowDownOutlined />
                SKUs: <strong>{skus?.length || 0}</strong>
              </div>
            </div>
            <CustomProTable
              className="products-table"
              dataSource={products}
              columns={columns}
              loading={loading}
              toolbar={toolbar}
              handleRowClick={handleClickGridView}
              pagination={{
                current: pageNumber,
                total: totalCount,
                pageSize,
                showSizeChanger: true,
                onChange: (page, size) => {
                  handlePageChange(page, size);
                },
                showTotal: showTotal(nested),
              }}
              expandable={expandable}
              searchable
              onChange={(pagination, filters, sorter) => {
                setSearchValues((prev) => ({
                  ...prev,
                  ...Object.fromEntries(
                    Object.entries(filters)
                      .filter(([key, val]) => !!val)
                      .map(([key, val]) => [key, val.toString()])
                  ),
                }));
                handleSortChange(
                  sorter.field,
                  sorter.order === 'ascend' ? 'ASC' : 'DESC'
                );
              }}
              rowKey={'_id'}
            />
          </div>
        )}
        {openGridView && (
          <ProductGridView
            title={productDetailTitle}
            visible={openGridView}
            product={selectedProduct}
            handleShowGridView={handleShowGridView}
          />
        )}
      </Spin>
    </div>
  );
};

ProductsTable.propTypes = {
  type: PropTypes.string,
  showManagement: PropTypes.bool,
  rule: PropTypes.instanceOf(Object),
  rules: PropTypes.arrayOf(Object),
  skuArray: PropTypes.arrayOf(PropTypes.string),
  previewSwitch: PropTypes.node,
  handleShowDrawer: PropTypes.func,
  onDataLoad: PropTypes.func,
  onLoading: PropTypes.func,
  realSearch: PropTypes.bool,
  // Indicate that condition exists even if query data is empty
  conditionExists: PropTypes.bool,
};

ProductsTable.defaultProps = {
  type: 'product',
  showManagement: false,
  rule: null,
  rules: [],
  skuArray: null,
  previewSwitch: null,
  handleShowDrawer: () => {},
  onDataLoad: () => {},
  onLoading: () => {},
  realSearch: true,
  conditionExists: false,
};

export default memo(ProductsTable);
