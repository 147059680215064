import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import AddRulesPopup from '@marketreach/components/rule/AddRulesPopup';
import ModalAutoGenerationFilter from '@marketreach/pages/taxonomy/sidebar/ModalAutoGenerationFilter';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  BULK_CREATE_CATEGORY,
  CATEGORIES,
  CATEGORIES_QUERY_NAME,
  CATEGORY_QUERY_NAME,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from '@marketreach/services/apollo/categories';
import { ENTITY_DATA_QUERY } from '@marketreach/services/apollo/entity';
import {
  CREATE_HISTORY,
  HISTORIES_BY_PAGE_QUERY_NAME,
  HISTORIES_QUERY_NAME,
} from '@marketreach/services/apollo/history';
import { GET_PRODUCT_KEYS } from '@marketreach/services/apollo/products';
import { getLeafs } from '@marketreach/utils/common';

import './styles.scss';

const { Text } = Typography;
const { Option } = Select;

const SidebarCategoryModal = ({
  title,
  mode,
  entity,
  rootId,
  open,
  handleOk,
  handleCancel,
  setSelectedEntityId,
  setExpandedKeys,
  expandedKeys,
}) => {
  const client = useSelectedClient();

  const { data: baseFieldsData } = useQuery(GET_PRODUCT_KEYS, {
    variables: {
      clientCode: client.apiId,
    },
  });
  const [addCategory] = useMutation(CREATE_CATEGORY);
  const [massCategoryCreate] = useMutation(BULK_CREATE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const [createHistory] = useMutation(CREATE_HISTORY);
  const [form] = Form.useForm();

  const [categoryInProcess, setCategoryInProcess] = useState(false);
  const [isAutoGenerated, setIsAutoGenerated] = useState(false);
  const [isTokenized, setIsTokenized] = useState(false);
  const [productField, setProductField] = useState([]);
  const [showRulesPopup, setShowRulesPopup] = useState(false);
  const [linkedRules, setLinkedRules] = useState([]);

  const initAutoGenerationState = {
    wordMatch: 'whole',
    caseSensitive: false,
    basis: 'include',
    key: 'name',
    match: 'any',
  };

  const [autoGenerationState, setAutoGenerationState] = useState(
    initAutoGenerationState
  );

  const input = useRef(null);
  const formRef = useRef(null);
  const category = entity;
  const categoryId = entity?.key ?? null;

  const childs = useMemo(() => {
    if (!category) return [];
    return getLeafs(category.children).map((it) => it.key);
  }, [categoryId]);

  useEffect(() => {
    if (open && input.current) {
      setTimeout(() => {
        input.current.focus();
      });
    }
  }, [open, input]);

  useEffect(() => {
    if (baseFieldsData?.getProductKeys?.data) {
      setProductField(
        baseFieldsData?.getProductKeys?.data.filter(
          (item) => item?.mainType.toLowerCase() === 'string'
        )
      );
    }
  }, [baseFieldsData]);

  useEffect(() => {
    if (category && mode === 'edit') {
      form.setFieldsValue({
        name: category.name || category.title,
      });
    } else {
      form.setFieldsValue({
        name: '',
        autoGenerated: false,
        isTokenized: false,
        productField: null,
      });
      setIsAutoGenerated(false);
      setIsTokenized(false);
    }
  }, [category, form, mode]);

  const handleAutoGenerationChange = (param, value) => {
    setAutoGenerationState({
      ...autoGenerationState,
      [param]: value,
    });
  };

  const onOk = async () => {
    const formValue = form.getFieldsValue();

    if (
      !isAutoGenerated &&
      !formValue?.name &&
      mode !== 'delete' &&
      mode !== 'bulk_create' &&
      mode !== 'root'
    ) {
      message.error('Please input category name');

      return;
    }

    if (
      !isAutoGenerated &&
      !formValue?.names &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please input categories names');

      return;
    }

    if (
      isAutoGenerated &&
      (!formValue?.productField || (!formValue?.names && !categoryId)) &&
      (mode === 'bulk_create' || mode === 'root')
    ) {
      message.error('Please select base field and enter name');
      return;
    }

    if (
      (!categoryId || mode === 'create') &&
      mode !== 'root' &&
      mode !== 'delete'
    ) {
      setCategoryInProcess(true);
      await addCategory({
        variables: {
          ...formValue,
          ...autoGenerationState,
          type: 'category',
          clientCode: client?.apiId,
          parentId: categoryId,
          rootId,
          isAutoGenerated,
          isTokenized,
        },
        refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORY_QUERY_NAME],
        awaitRefetchQueries: true,
      });

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: category
            ? `${formValue?.name} was added as a subcategory of ${category?.name}`
            : `${formValue?.name} was added as a root category`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'category',
          parentId: category?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });
      form.setFieldsValue({
        name: '',
        autoGenerated: false,
        productField: null,
      });
      setCategoryInProcess(false);
      setIsAutoGenerated(false);
      setIsTokenized(false);
      handleOk(category?._id);
    }

    if (categoryId && mode === 'edit') {
      setCategoryInProcess(true);
      await updateCategory({
        variables: {
          ...formValue,
          type: 'category',
          clientCode: client?.apiId,
          _id: categoryId,
        },
        refetchQueries: [
          CATEGORIES_QUERY_NAME,
          CATEGORY_QUERY_NAME,
          ENTITY_DATA_QUERY,
        ],
        awaitRefetchQueries: true,
      });

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: `Category name ${category?.name} was changed to ${formValue?.name}`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'category',
          parentId: category?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });
      setCategoryInProcess(false);
      handleOk(category?._id);
    }

    if (categoryId && mode === 'delete') {
      if (category) {
        setCategoryInProcess(true);
        await deleteCategory({
          variables: {
            clientCode: client?.apiId,
            _id: categoryId,
            categories: [categoryId, ...(childs || [])],
            associations: category.associations,
          },
          refetchQueries: [
            CATEGORIES_QUERY_NAME,
            CATEGORY_QUERY_NAME,
            ENTITY_DATA_QUERY,
          ],
          awaitRefetchQueries: true,
        });

        setSelectedEntityId(null);

        await createHistory({
          variables: {
            clientCode: client?.apiId,
            action: `Category ${category?.name} was deleted`,
            account: 'Admin',
            ipAddress: '127.0.0.1',
            type: 'category',
            parentId: category?._id,
          },
          refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
          awaitRefetchQueries: true,
        });
        setCategoryInProcess(false);
        handleOk();
      }
    }

    if (
      (categoryId && mode === 'bulk_create') ||
      (!categoryId && mode === 'root')
    ) {
      setCategoryInProcess(true);
      await massCategoryCreate({
        variables: {
          ...formValue,
          ...autoGenerationState,
          type: 'category',
          clientCode: client?.apiId,
          parentId: categoryId,
          rootId,
          isAutoGenerated,
          isTokenized,
          linkedRules,
        },
        refetchQueries: [CATEGORIES_QUERY_NAME, CATEGORIES],
        awaitRefetchQueries: true,
      });

      setExpandedKeys([...expandedKeys, categoryId]);

      await createHistory({
        variables: {
          clientCode: client?.apiId,
          action: `Mass category creation ${formValue?.names}`,
          account: 'Admin',
          ipAddress: '127.0.0.1',
          type: 'category',
          parentId: category?._id,
        },
        refetchQueries: [HISTORIES_QUERY_NAME, HISTORIES_BY_PAGE_QUERY_NAME],
        awaitRefetchQueries: true,
      });
      form.setFieldsValue({
        names: '',
        autoGenerated: false,
        productField: null,
      });
      setCategoryInProcess(false);
      setIsAutoGenerated(false);
      setIsTokenized(false);
      setAutoGenerationState(initAutoGenerationState);
      handleOk(category?._id);
    }
  };

  const onCancel = () => {
    form.setFieldsValue({
      name: '',
      names: '',
      autoGenerated: false,
      productField: null,
    });
    setIsAutoGenerated(false);
    setIsTokenized(false);
    setAutoGenerationState(initAutoGenerationState);
    setLinkedRules([]);
    handleCancel();
  };

  const onFormLayoutChange = () => {};

  function handleKeyUp(event) {
    if (mode !== 'bulk_create' && mode !== 'root') {
      if (event.keyCode === 13) {
        onOk();
      }
    }
  }

  const handleAddRulesPopupOk = (rulesIds) => {
    setLinkedRules(rulesIds);
    setShowRulesPopup(false);
  };

  const mainInputLabel = () => {
    if (isAutoGenerated && !categoryId) {
      return 'Root category name';
    }

    return 'Name';
  };

  return (
    <Modal
      className="taxonomy-sidebar-category-modal"
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      width={450}
      getContainer={false}
      forceRender
    >
      <Spin spinning={categoryInProcess}>
        <Form
          ref={formRef}
          form={form}
          labelCol={{ span: 4 }}
          onValuesChange={onFormLayoutChange}
          onKeyUp={(event) => handleKeyUp(event)}
        >
          {mode === 'delete' && (
            <>
              <div>Are sure want to delete this category?</div>
              {childs.length > 0 && (
                <>
                  <br />
                  <Text type="danger">
                    <b>
                      Warning: This will also delete {childs?.length}{' '}
                      {childs?.length === 1 ? 'subcategory' : 'subcategories'}
                    </b>
                  </Text>
                </>
              )}
            </>
          )}
          {(mode === 'bulk_create' || mode === 'root') && (
            <>
              <Form.Item
                label={mainInputLabel()}
                name="names"
                required
                className={'flex-name'}
              >
                <TextArea
                  ref={input}
                  placeholder="input names"
                  autoFocus
                  tabIndex={0}
                  autoSize={{ minRows: 1 }}
                />
              </Form.Item>
              <div>
                <Form.Item label="" name="autoGenerated">
                  <Checkbox
                    checked={isAutoGenerated}
                    onChange={() => setIsAutoGenerated(!isAutoGenerated)}
                  >
                    Auto Generated
                  </Checkbox>
                </Form.Item>
              </div>
              {isAutoGenerated && (
                <div>
                  <Form.Item label="" name="tokenized">
                    <Checkbox
                      checked={isTokenized}
                      onChange={() => setIsTokenized(!isTokenized)}
                    >
                      Tokenized rule
                    </Checkbox>
                  </Form.Item>
                  <Form.Item label="" name="productField" required>
                    <Select
                      required
                      showSearch
                      placeholder="Select a base field"
                      optionFilterProp="children"
                      filterOption={(inputProductField, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(inputProductField.toLowerCase()) >= 0
                      }
                    >
                      {_.sortBy(productField, ['key']).map((item) => (
                        <Option value={item.key} key={item.id}>
                          {item.key}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <ModalAutoGenerationFilter
                    autoGenerationState={autoGenerationState}
                    handleAutoGenerationChange={handleAutoGenerationChange}
                  />
                  <Button
                    type={'primary'}
                    onClick={() => setShowRulesPopup(true)}
                  >
                    {linkedRules.length === 0
                      ? 'Add more rules'
                      : `Update rules linking (${linkedRules.length})`}
                  </Button>
                  {showRulesPopup && (
                    <AddRulesPopup
                      visible={showRulesPopup}
                      onOk={handleAddRulesPopupOk}
                      linkedRulesIds={linkedRules}
                      onCancel={() => setShowRulesPopup(false)}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {(mode === 'create' || mode === 'edit') && (
            <>
              <Form.Item label="Name" name="name" required>
                <Input
                  ref={input}
                  placeholder="input name"
                  autoFocus
                  tabIndex={0}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

SidebarCategoryModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  categoryId: PropTypes.string,
  rootId: PropTypes.string,
  open: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  setExpandedKeys: PropTypes.func,
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  childs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

SidebarCategoryModal.defaultProps = {
  title: '',
  categoryId: null,
  rootId: null,
  mode: '',
  open: false,
  handleOk: () => {},
  handleCancel: () => {},
  setExpandedKeys: () => {},
  expandedKeys: [],
  childs: [],
};

export default memo(SidebarCategoryModal);
