import { Modal, ModalProps, Spin, Typography } from 'antd';
import React, { memo } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';

import { useDeleteEntityData } from '../api';

export type DeleteEntityDataModalPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entityType: string;
    id: string;
    childCountWarning?: number;
  };

const DeleteEntityDataModal = ({
  entityType,
  id,
  childCountWarning,
  onOk,
  onToggle,
  onFail,
  ...props
}: DeleteEntityDataModalPropTypes) => {
  const client = useSelectedClient();

  const [deleteEntityData, { loading }] = useDeleteEntityData(entityType);

  return (
    <Modal
      title={`Delete ${
        client.entities.find((entity) => entity.slug === entityType)?.label
      }`}
      onOk={async () => {
        try {
          onOk?.();
          await deleteEntityData({ id });
          onToggle?.();
        } catch {
          onFail?.();
        }
      }}
      width={400}
      {...props}
    >
      <Spin spinning={loading}>
        <div>Are sure you want to delete this row?</div>
        {!!childCountWarning && (
          <>
            <br />
            <Typography.Text type="danger">
              <b>Warning: This will also delete {childCountWarning} subitems</b>
            </Typography.Text>
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default memo(DeleteEntityDataModal);
