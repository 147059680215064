import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const { Text } = Typography;

const CustomTable = (props) => {
  const { className, columns, data } = props;

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={data}
      bordered
      size="small"
      scroll={{ x: '100%' }}
      pagination={{
        defaultPageSize: 5,
        pageSize: 5,
      }}
    />
  );
};

CustomTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(Object),
  data: PropTypes.arrayOf(Object),
};

CustomTable.defaultProps = {
  className: '',
  columns: [
    {
      title: 'Rank',
      dataIndex: 'rank',
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      render: (text) => <a href="/dashboard">{text}</a>,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      sorter: (a, b) => a.users - b.users,
    },
    {
      title: 'Weekly Range',
      dataIndex: 'weeklyRange',
      render: (value) => (
        <div>
          <Text className="change-value">{`${Math.abs(value)}%`}</Text>
          {value > 0 ? (
            <CaretUpOutlined
              style={{
                color: '#f5222d',
                marginRight: 12,
              }}
            />
          ) : (
            <CaretDownOutlined style={{ color: '#52c41a' }} />
          )}
        </div>
      ),
      sorter: (a, b) => a.weeklyRange - b.weeklyRange,
    },
  ],
  data: [
    {
      key: 'key_1',
      rank: 1,
      keyword: 'Shirt',
      users: 600,
      weeklyRange: -95,
    },
    {
      key: 'key_2',
      rank: 2,
      keyword: 'Blue',
      users: 400,
      weeklyRange: 87,
    },
    {
      key: 'key_3',
      rank: 3,
      keyword: 'Man',
      users: 345,
      weeklyRange: -70,
    },
    {
      key: 'key_4',
      rank: 4,
      keyword: 'Top Man',
      users: 300,
      weeklyRange: -65,
    },
    {
      key: 'key_5',
      rank: 5,
      keyword: 'Top Female',
      users: 286,
      weeklyRange: 59,
    },
    {
      key: 'key_6',
      rank: 6,
      keyword: 'Blue Shirt',
      users: 222,
      weeklyRange: 50,
    },
    {
      key: 'key_7',
      rank: 7,
      keyword: 'Blue Man Shirt',
      users: 197,
      weeklyRange: -40,
    },
  ],
};

export default memo(CustomTable);
