import { Box } from '@chakra-ui/react';
import { Button, Empty, Form, Row, Space, Spin, Typography } from 'antd';
import React, { Key, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUpdateEntityData } from '@marketreach/model/entity/data/api';
import EntityDataForm from '@marketreach/model/entity/data/EntityDataForm';
import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { getApiIdField } from '@marketreach/utils/common';

type EntityContentPropTypes = {
  data: NormalizedEntityData[];
  selectedEntityId: Key | null;
};

export const EntityContent = ({
  data,
  selectedEntityId,
}: EntityContentPropTypes) => {
  const client = useSelectedClient();
  const { type } = useParams();

  const [form] = Form.useForm();

  const [updateEntityData, { loading }] = useUpdateEntityData(type ?? '');

  // Handle reset
  const onReset = useCallback(() => form.resetFields(), [form]);

  // Handle save
  const onSave = useCallback(async () => {
    await form.validateFields().then((values) => {
      return updateEntityData({
        id: selectedEntityId?.toString() ?? '',
        data: values,
      });
    });
  }, [form, selectedEntityId, updateEntityData]);

  const entity = useMemo(
    () => data.find((d) => d._id === selectedEntityId) ?? null,
    [data, selectedEntityId]
  );

  return (
    <>
      {selectedEntityId && (
        <Box className="properties-content" px={6} overflow="auto">
          <Spin spinning={loading}>
            <Row className="properties-heading" justify="space-between">
              <Typography.Title level={4}>
                Editing {entity?.[getApiIdField(client, type)]}
              </Typography.Title>
              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button onClick={onSave} type="primary">
                  Save
                </Button>
              </Space>
            </Row>
            <EntityDataForm
              form={form}
              entity={entity}
              entityType={type ?? ''}
            />
          </Spin>
        </Box>
      )}
      {!selectedEntityId && <Empty>Select entity at sidebar</Empty>}
    </>
  );
};
