import { Drawer, DrawerProps } from 'antd';
import React, { memo, useCallback, useState } from 'react';

import './styles.scss';

export type CustomDrawerPropTypes = DrawerProps & {
  content: React.ReactNode;
  handleShowDrawer: (val: boolean) => void;
};

const CustomDrawer = (props: CustomDrawerPropTypes) => {
  const {
    className,
    title,
    footer,
    content,
    visible,
    handleShowDrawer,
    zIndex,
  } = props;

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
    handleShowDrawer(false);
  }, [handleShowDrawer]);

  return (
    <Drawer
      className={className}
      title={title}
      width={1100}
      onClose={onClose}
      visible={visible ?? open}
      footer={footer}
      getContainer={false}
      zIndex={zIndex}
    >
      {content}
    </Drawer>
  );
};

export default memo(CustomDrawer);
