import { SearchOutlined } from '@ant-design/icons';
import { Input, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useRef } from 'react';

import { CustomProTablePropTypes } from '../';
import FilterDropDown from '../components/FilterDropDown';

export type UseColumnSearchProps = Pick<
  CustomProTablePropTypes,
  'columns' | 'searchable'
>;

export const useColumnSearch = ({
  columns,
  searchable,
}: UseColumnSearchProps): Partial<TableProps<any>> => {
  const searchInput = useRef<Input>(null);

  const getColumnSearchProps: (
    dataIndex: ColumnType<any>['dataIndex']
  ) => Partial<ColumnType<any>> = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FilterDropDown
        searchInput={searchInput}
        dataIndex={dataIndex}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: undefined,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return {
    ...(searchable
      ? {
          columns: columns?.map((column) => ({
            ...column,
            ...getColumnSearchProps((column as ColumnType<any>).dataIndex),
          })),
        }
      : { columns }),
  };
};
