import { FilterValue, SorterResult } from 'antd/lib/table/interface';

/**
 * Prepare data for customProTable display
 *
 * @param data
 * @return {*}
 */
export const buildDataSource = (data: any) => {
  return data?.map((r: any, i: number) => {
    return r?.key
      ? { ...r, index: i }
      : {
          ...r,
          key: null,
          index: i,
        };
  });
};

export const formatSearchParams = (
  searchParams: Record<string, FilterValue | null>
) => {
  return Object.fromEntries(
    Object.entries(searchParams)
      .map(([key, val]) => [key, Array.isArray(val) ? val?.join(',') : val])
      .filter(([key, val]) => !!val)
  );
};

export const formatSortParams = (sortParams: SorterResult<any>) => {
  return {
    field: sortParams?.field,
    direction: sortParams?.order === 'ascend' ? 'ASC' : 'DESC',
  };

  // For multi sort:
  // (!Array.isArray(sortParams)
  //   ? [sortParams]
  //   : sortParams
  // )?.reduce(
  //   (acc, val) => ({
  //     ...acc,
  //     ...(val?.field ? { [val.field.toString()]: val?.order } : {}),
  //   }),
  //   {}
  // )
};
