import { Form, FormInstance, FormProps, Input } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { buildSlug } from '@marketreach/utils/common';

export type SectionFormValues = {
  name: string;
  key: string;
};

const defaultValues: SectionFormValues = {
  name: '',
  key: '',
};

export type SectionFormPropTypes = FormProps & {
  form: FormInstance<SectionFormValues>;
  initialValues?: SectionFormValues;
  entityType: string;
};

const SectionForm = ({
  form,
  initialValues,
  entityType,
  ...props
}: SectionFormPropTypes) => {
  const client = useSelectedClient();

  const sections = client?.sections[entityType] ?? [];

  // Focus on input
  const focusElement = useRef<Input>(null);
  useEffect(() => {
    if (focusElement.current) {
      focusElement.current?.focus();
    }
  }, [focusElement]);

  const onChange = useCallback(
    (e) =>
      form.setFieldsValue({
        ...form.getFieldsValue(),
        key: buildSlug(e.target.value),
      }),
    [form]
  );

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      initialValues={initialValues ?? defaultValues}
      {...props}
    >
      <Form.Item label="Name" name="name">
        <Input
          ref={focusElement}
          placeholder="Input Name..."
          autoFocus
          onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        label="API ID"
        name="key"
        rules={[
          {
            validator: (_, value) => {
              return (
                !sections
                  // Filter out the previous entry (from initialValues) so we don't check itself
                  .filter((sec) => sec.key !== initialValues?.key)
                  .find((sec) => buildSlug(sec?.key) === buildSlug(value))
                  ? Promise.resolve()
                  : Promise.reject()
              );
            },
            message:
              'Duplicate Section key detected. Please use a different key.',
          },
        ]}
      >
        <Input placeholder="Input API ID..." onChange={onChange} />
      </Form.Item>
    </Form>
  );
};

export default SectionForm;
