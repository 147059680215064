import { Form, Modal, ModalProps, Spin } from 'antd';
import React, { memo } from 'react';

import { DisclosureToggleComponentProps } from '@marketreach/components/disclosureToggle';

import { useAddSection } from '../api';
import SectionForm, { SectionFormValues } from '../SectionForm';

export type AddSectionModalPropTypes = ModalProps &
  Partial<DisclosureToggleComponentProps> & {
    entityType: string;
  };

const AddSectionModal = ({
  entityType,
  onOk,
  onToggle,
  onFail,
  ...props
}: AddSectionModalPropTypes) => {
  const [addSection, { loading }] = useAddSection(entityType);

  const [form] = Form.useForm<SectionFormValues>();

  return (
    <Modal
      title="Add Section"
      onOk={async () => {
        try {
          onOk?.();
          await form.validateFields().then((values) => {
            return addSection(values);
          });

          form.resetFields();
          onToggle?.();
        } catch {
          onFail?.();
        }
      }}
      width={400}
      {...props}
    >
      <Spin spinning={loading}>
        <SectionForm form={form} entityType={entityType} />
      </Spin>
    </Modal>
  );
};

export default memo(AddSectionModal);
