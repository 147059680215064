import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { message, Switch, Typography } from 'antd';
import React, { memo, useContext, useState } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTES } from '@marketreach/services/apollo/attributes';
import {
  ADD_ASSOCIATITON_ATTRIBUTE,
  CATEGORY_QUERY_NAME,
  REMOVE_ASSOCIATITON_ATTRIBUTE,
} from '@marketreach/services/apollo/categories';

import TaxonomyEntityTree from '../entityTree';
import TaxonomyAttributesPreview from './AttributesPreview';
import './styles.scss';

const { Text } = Typography;

const TaxonomyAttributes = () => {
  const { selectedCategory, attributes, setAttributes } =
    useContext(TaxonomyContext);
  const client = useSelectedClient();

  const [previewMode, setPreviewMode] = useState(false);
  const [previewFilters, setPreviewFilters] = useState([]);
  const [addAssociationAttribute] = useMutation(ADD_ASSOCIATITON_ATTRIBUTE);

  const [removeAssociationAttribute] = useMutation(
    REMOVE_ASSOCIATITON_ATTRIBUTE
  );

  return (
    <div className="ant-pro-grid-content attributes-content">
      <div className="attributes-content-tree-container">
        <TaxonomyEntityTree
          preview={previewMode}
          onPreviewEntityChange={setPreviewFilters}
          query={ATTRIBUTES}
          entities={attributes}
          setEntities={setAttributes}
          baseEntity={selectedCategory}
          entityName="attributes"
          onAddAssociation={(checkedKeys) => {
            addAssociationAttribute({
              variables: {
                clientCode: client?.apiId,
                _id: selectedCategory?._id,
                attributeIds: checkedKeys,
              },
              refetchQueries: [CATEGORY_QUERY_NAME],
              awaitRefetchQueries: true,
            })
              .then(() => {
                message.info('Updated successfully');
              })
              .catch(() => {});
          }}
          onRemoveAssociation={(checkedKeys) => {
            removeAssociationAttribute({
              variables: {
                clientCode: client?.apiId,
                _id: selectedCategory?._id,
                attributeIds: checkedKeys,
              },
              refetchQueries: [CATEGORY_QUERY_NAME],
              awaitRefetchQueries: true,
            })
              .then(() => {
                message.info('Updated successfully');
              })
              .catch(() => {});
          }}
        />
      </div>
      <div className="attributes-content-preview-container">
        <div className="attributes-content-preview">
          <div className="preview-switch">
            <Text className="attributes-content-preview-switch-label">
              Preview mode
            </Text>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={previewMode}
              onChange={setPreviewMode}
            />
          </div>
          {previewMode && (
            <TaxonomyAttributesPreview
              onPreviewChange={setPreviewMode}
              previewFilters={previewFilters}
              rawAttributes={attributes}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TaxonomyAttributes.propTypes = {};

TaxonomyAttributes.defaultProps = {};

export default memo(TaxonomyAttributes);
