import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Row, Space } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import DisclosureToggle from '@marketreach/components/disclosureToggle';
import CustomProTable from '@marketreach/components/protable';
import { showTotal } from '@marketreach/components/protable/components/pagination';
import {
  formatSearchParams,
  formatSortParams,
} from '@marketreach/components/protable/helpers';
import AddEntityDataDrawer from '@marketreach/model/entity/data/disclosure/AddEntityDataDrawer';
import DeleteEntityDataModal from '@marketreach/model/entity/data/disclosure/DeleteEntityDataModal';
import UpdateEntityDataDrawer from '@marketreach/model/entity/data/disclosure/UpdateEntityDataDrawer';
import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { Entity } from '@marketreach/model/entity/definition/types';
import ManagePropertiesControl from '@marketreach/pages/entities/components/ManagePropertiesControl';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import {
  getAllChildren,
  normalizeEntity,
  sortByOrder,
} from '@marketreach/utils/common';
import { getSectionColumns } from '@marketreach/utils/products';

import EntityTableViewExportModal from './export';

/**
 * Display custom entities in table view
 * Each row is clickable
 * On click opened editor of current custom entity
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */

export type TableViewPropTypes = {
  entityInfo: Entity;
  handleShowDrawer: (val: boolean) => void;
  showInvite: boolean;
};

const TableView = (props: TableViewPropTypes) => {
  const { entityInfo, handleShowDrawer, showInvite } = props;

  // This is here to handle on row click. Null if nothing to show
  const [entityToEdit, setEntityToEdit] = useState<NormalizedEntityData | null>(
    null
  );

  const client = useSelectedClient();
  const { type } = useParams();

  const sections = (
    'sections' in client ? client.sections[type as string] || [] : []
  )
    .slice()
    .sort(sortByOrder);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    total: 0,
    pageSize: 30,
    showSizeChanger: true,
    showTotal: showTotal(),
  });
  const [searchParams, setSearchParams] = useState({});
  const [sortParams, setSortParams] = useState({});

  const { loading, data: rawData } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pagination.current,
      pageSize: pagination.pageSize,
      searchParams: formatSearchParams(searchParams),
      sortParams: formatSortParams(sortParams),
    },
    onCompleted: (newData) => {
      setPagination((prev) => ({
        ...prev,
        total: newData.entityData.totalCount,
      }));
    },
    fetchPolicy: 'cache-and-network',
    skip: !type,
  });

  const data: NormalizedEntityData[] = useMemo(() => {
    return rawData?.entityData?.data
      ? normalizeEntity(rawData?.entityData?.data, true)
      : [];
  }, [rawData?.entityData?.data]);

  const columns = useMemo(
    () =>
      getSectionColumns(sections).concat({
        title: 'Operation',
        dataIndex: 'operation',
        sorter: false,
        width: 10,
        render: (val: any, row: any) => (
          <Row wrap={false}>
            <Button
              key="edit"
              type="text"
              shape="circle"
              onClick={() => setEntityToEdit(row)}
            >
              <EditOutlined />
            </Button>
            <span onClick={(e) => e.stopPropagation()}>
              <DisclosureToggle
                toggler={({ onToggle }) => (
                  <Button
                    key="delete"
                    type="text"
                    shape="circle"
                    onClick={(e) => {
                      e.preventDefault();
                      onToggle();
                      e.stopPropagation();
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              >
                <DeleteEntityDataModal
                  entityType={type as string}
                  id={row?._id}
                  childCountWarning={getAllChildren(row?._id, data).length ?? 0}
                />
              </DisclosureToggle>
            </span>
          </Row>
        ),
      }),
    [data, sections, type]
  );

  // The toolbar component
  const toolbar = useCallback(() => {
    return (
      <Space>
        <DisclosureToggle
          toggler={({ onToggle }) => (
            <Button type="primary" onClick={onToggle}>
              <PlusOutlined /> Add {entityInfo?.label}
            </Button>
          )}
        >
          <AddEntityDataDrawer entityType={type as string} />
        </DisclosureToggle>
        <DisclosureToggle
          toggler={({ onToggle }) => (
            <Button key="export" type="primary" onClick={onToggle}>
              Export
            </Button>
          )}
        >
          <EntityTableViewExportModal type={type as string} />
        </DisclosureToggle>
        <ManagePropertiesControl
          showInvite={showInvite}
          handleShowDrawer={handleShowDrawer}
        />
      </Space>
    );
  }, [entityInfo?.label, handleShowDrawer, showInvite, type]);

  // Title of the update drawer component
  const updateDrawerTitle = useCallback(() => {
    return (
      <div className="product-detail-header">
        <div className="product-detail-header-title">
          <strong>
            {_.isEmpty(entityToEdit)
              ? `Add ${entityInfo?.label}`
              : `Edit ${entityInfo?.label}`}
          </strong>
        </div>
        <div>
          <Button type="link" onClick={() => handleShowDrawer(true)}>
            Manage properties
          </Button>
        </div>
      </div>
    );
  }, [entityInfo?.label, entityToEdit, handleShowDrawer]);

  return (
    <>
      <CustomProTable
        className="products-table"
        dataSource={data}
        columns={columns}
        loading={loading}
        toolbar={toolbar}
        pagination={pagination}
        searchable
        scroll={{ x: 'max-content', y: 'calc(100vh - 280px)' }}
        onChange={(newPagination, newFilters, newSorter) => {
          setPagination((prev) => ({ ...prev, ...newPagination }));
          setSearchParams((prev) => ({ ...prev, ...newFilters }));
          setSortParams((prev) => ({ ...prev, ...newSorter }));
        }}
        selectable
        onRow={(row) => ({
          onClick: () => setEntityToEdit(row),
        })}
        rowKey="_id"
      />
      <UpdateEntityDataDrawer
        title={updateDrawerTitle()}
        visible={!!entityToEdit}
        onToggle={() => setEntityToEdit(null)}
        entity={entityToEdit}
        entityType={type as string}
      />
    </>
  );
};

export default TableView;
