import { useQuery } from '@apollo/client';
import { List, Modal, Spin, Typography } from 'antd';
import React from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { GET_PRODUCT_KEY_VALUES } from '@marketreach/services/apollo/products';

import * as styles from './styles.scss';

/**
 * SchemaValuesView component props types
 */
export interface SchemaValuesViewProps {
  keyToView: string;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Possible key values type
 */
export type KeyValues =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[];

/**
 * View schema key values
 *
 * @param {SchemaValuesViewProps} props
 * @constructor
 */
export const SchemaValuesView = React.memo((props: SchemaValuesViewProps) => {
  const { keyToView, onClose } = props;
  const client = useSelectedClient();

  const { loading, data } = useQuery(GET_PRODUCT_KEY_VALUES, {
    skip: !keyToView,
    variables: {
      clientCode: client?.apiId,
      key: keyToView,
    },
  });

  return (
    <Modal
      visible={Boolean(keyToView)}
      onCancel={onClose}
      onOk={onClose}
      getContainer={false}
      title={`View values of key '${keyToView}' (${
        data?.getProductKeyValues?.data?.values?.length || 0
      } items)`}
    >
      <Spin spinning={loading}>
        <div className={styles.schemaValuesViewListContainer}>
          <List
            dataSource={data?.getProductKeyValues?.data?.values
              ?.filter((item: KeyValues) => item !== null)
              .sort()}
            renderItem={(item: KeyValues) => (
              <List.Item>
                <Typography.Text>{item.toString()}</Typography.Text>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    </Modal>
  );
});
