import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { message, Switch, Typography } from 'antd';
import React, { memo, useContext, useState } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  ADD_ASSOCIATITON_CATEGORY,
  ATTRIBUTE_QUERY_NAME,
  REMOVE_ASSOCIATITON_CATEGORY,
} from '@marketreach/services/apollo/attributes';
import {
  CATEGORIES,
  CATEGORIES_QUERY_NAME,
} from '@marketreach/services/apollo/categories';

import TaxonomyEntityTree from '../entityTree';
import TaxonomyCategoriesPreview from './CategoriesPreview';
import './styles.scss';

const { Text } = Typography;

const TaxonomyCategories = () => {
  const { selectedAttribute, categories, setCategories, attributes } =
    useContext(TaxonomyContext);

  const getChildsAttributes = (id) => {
    const childs = [];
    for (const attr of attributes) {
      if (attr?.core?.parentId === id) {
        childs.push(attr._id);
        const subChilds = getChildsAttributes(attr._id);
        if (subChilds.length > 0) {
          childs.push(...subChilds);
        }
      }
    }

    return childs;
  };
  const [previewMode, setPreviewMode] = useState(false);
  const [previewFilters, setPreviewFilters] = useState([]);
  const client = useSelectedClient();

  const [addAssociationCategory] = useMutation(ADD_ASSOCIATITON_CATEGORY);
  const [removeAssociationCategory] = useMutation(REMOVE_ASSOCIATITON_CATEGORY);

  return (
    <div className="ant-pro-grid-content categories-content">
      <div className="categories-content-tree-container">
        <TaxonomyEntityTree
          preview={previewMode}
          onPreviewEntityChange={setPreviewFilters}
          query={CATEGORIES}
          entities={categories}
          setEntities={setCategories}
          baseEntity={selectedAttribute}
          entityName="categories"
          onAddAssociation={(checkedKeys) => {
            const attributesToApply = [
              ...new Set([
                selectedAttribute?._id,
                ...getChildsAttributes(selectedAttribute?._id),
              ]),
            ];
            addAssociationCategory({
              variables: {
                clientCode: client?.apiId,
                attributeIds: attributesToApply,
                categoryIds: checkedKeys,
              },
              refetchQueries: [CATEGORIES_QUERY_NAME, ATTRIBUTE_QUERY_NAME],
              awaitRefetchQueries: true,
            })
              .then(() => {
                message.info('Updated successfully');
              })
              .catch(() => {});
          }}
          onRemoveAssociation={(checkedKeys) => {
            const attributesToApply = [
              ...new Set([
                selectedAttribute?._id,
                ...getChildsAttributes(selectedAttribute?._id),
              ]),
            ];
            removeAssociationCategory({
              variables: {
                clientCode: client?.apiId,
                attributeIds: attributesToApply,
                categoryIds: checkedKeys,
              },
              refetchQueries: [CATEGORIES_QUERY_NAME, ATTRIBUTE_QUERY_NAME],
              awaitRefetchQueries: true,
            })
              .then(() => {
                // refetchCategories();
                message.info('Updated successfully');
              })
              .catch(() => {});
          }}
        />
      </div>
      <div className="categories-content-preview-container">
        <div className="categories-content-preview">
          <div className="preview-switch">
            <Text className="categories-content-preview-switch-label">
              Preview mode
            </Text>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={previewMode}
              onChange={setPreviewMode}
            />
          </div>
          {previewMode && (
            <TaxonomyCategoriesPreview
              onPreviewChange={setPreviewMode}
              previewFilters={previewFilters}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TaxonomyCategories.propTypes = {};

TaxonomyCategories.defaultProps = {};

export default memo(TaxonomyCategories);
