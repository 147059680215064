import limit_characters from './rules/limit_characters';
import pattern from './rules/pattern';
import range from './rules/range';
import required from './rules/required';
import unique from './rules/unique';

export const buildRule = (type, settings, usedValues = {}, systemData = {}) => {
  switch (type) {
    case 'required':
      return required(settings);
    case 'range':
      return range(settings);
    case 'limit_characters':
      return limit_characters(settings);
    case 'match_pattern':
      return pattern(settings);
    case 'restrict_pattern':
      return pattern(settings, true);
    case 'unique':
      return unique(settings, usedValues, systemData);
    // disable antd rules building for custom file size and count restriction
    case 'file_count_limit':
    case 'file_size_limit':
      return;
    default:
      return {};
  }
};
