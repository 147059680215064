import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, message, Modal, Spin } from 'antd';
import React, { memo, useState } from 'react';
import {
  CLIENTS,
  CLIENTS_QUERY,
  CREATE_CLIENT,
  DELETE_CLIENT,
} from 'services/apollo/clients';

import CustomProTable from '@marketreach/components/protable';
import PropertiesManager from '@marketreach/pages/taxonomy/properties/PropertiesManager';

import { buildRule } from '../../../utils/validation/index';
import './styles.scss';

const initFormState = {
  name: '',
  apiId: '',
};

const ClientManagement = () => {
  const { loading: loadingClients, data: clientRes } = useQuery(CLIENTS);
  const [createClient] = useMutation(CREATE_CLIENT);
  const [deleteClient] = useMutation(DELETE_CLIENT);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [localeToBeDeleted, setLocaleToBeDeleted] = useState(null);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [clientCreationInProgress, setClientCreationInProgress] =
    useState(false);
  const [openManage] = useState(false);

  const [form] = Form.useForm();

  const handleDeleteClient = (client) => {
    if (client._id) {
      deleteClient({
        variables: { _id: client._id, apiId: client.apiId },
        refetchQueries: [CLIENTS_QUERY],
      }).then(() => {
        message.info('Client was deleted successfully');
      });
    }
  };

  const onDeleteClient = (client, index) => {
    setLocaleToBeDeleted({
      client,
      index,
    });
    setOpenConfirmModal(true);
  };

  const handleDeleteClientOk = () => {
    handleDeleteClient(localeToBeDeleted?.client);
    setLocaleToBeDeleted(null);
    setOpenConfirmModal(false);
  };

  const handleDeleteLocaleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleTriggerAddClient = () => {
    setShowAddClientModal(!showAddClientModal);
  };

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'name',
    },
    {
      title: 'API ID',
      dataIndex: 'apiId',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, row) => [
        <Button
          key="delete"
          type="text"
          shape="circle"
          onClick={() => onDeleteClient(row)}
        >
          <DeleteOutlined />
        </Button>,
      ],
    },
  ];

  const toolbar = () => [
    <Button type="primary" onClick={handleTriggerAddClient}>
      <PlusOutlined /> Add New
    </Button>,
  ];

  const isFormNotValid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const handleCreateClient = () => {
    if (!isFormNotValid()) {
      setClientCreationInProgress(true);

      createClient({
        variables: form.getFieldsValue(),
        refetchQueries: [CLIENTS_QUERY],
        awaitRefetchQueries: true,
      })
        .then(() => {
          message.info('Client was created successfully');
          form.setFieldsValue(initFormState);
          setShowAddClientModal(false);
          setClientCreationInProgress(false);
        })
        .catch(() => {
          setClientCreationInProgress(false);
        });
    }
  };

  return (
    <div className="settings-content-locale">
      {openConfirmModal && (
        <Modal
          className="locale-delete-modal"
          title="Delete client"
          visible={openConfirmModal}
          onOk={handleDeleteClientOk}
          onCancel={handleDeleteLocaleCancel}
          width={400}
        >
          <div>Are sure want to delete this client?</div>
        </Modal>
      )}

      <Modal
        className="client-add-modal"
        title="Add Client"
        visible={showAddClientModal}
        onOk={handleCreateClient}
        onCancel={handleTriggerAddClient}
      >
        <Spin spinning={clientCreationInProgress}>
          <div>
            <Form form={form} name={'client_add_form'}>
              <Form.Item
                label="Domain"
                name="name"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      return clientRes?.clients?.data
                        .map((client) => client.name)
                        .indexOf(value.trim()) === -1
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                    message:
                      'Validation Error [Unique Domain]: A client configuration already exists for the domain specified.  Please revise.',
                  },
                ]}
              >
                <Input
                  // onChange={onNameChange}
                  rules={[
                    buildRule(
                      'unique',
                      true,
                      clientRes?.clients?.data.map((client) => client.apiId)
                    ),
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="API ID"
                name="apiId"
                shouldUpdate
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      return clientRes?.clients?.data
                        .map((client) => client.apiId)
                        .indexOf(value.trim()) === -1
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                    message:
                      'Validation Error [Unique API ID]: A client configuration already exists for the API ID specified.  Please revise.',
                  },
                ]}
              >
                <Input
                  rules={[
                    buildRule(
                      'unique',
                      true,
                      clientRes?.clients?.data.map((client) => client.apiId)
                    ),
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>

      <Spin spinning={loadingClients}>
        {!loadingClients && (
          <CustomProTable
            className="rules-content-main-table"
            dataSource={clientRes?.clients?.data}
            rowKey="_id"
            columns={columns}
            toolbar={toolbar}
            pagination={{ defaultPageSize: 20 }}
            selectable={false}
          />
        )}
      </Spin>
      {openManage && (
        <PropertiesManager visible={openManage} entityType={'client'} />
      )}
    </div>
  );
};

ClientManagement.propTypes = {};

ClientManagement.defaultProps = {};

export default memo(ClientManagement);
