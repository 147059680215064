import { useMutation, useQuery } from '@apollo/client';
import { Empty, Layout, Spin } from 'antd';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CustomTabs from '@marketreach/components/tabs/routeTabs';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { ATTRIBUTE } from '@marketreach/services/apollo/attributes';
import {
  CATEGORY,
  UPDATE_CATEGORY,
} from '@marketreach/services/apollo/categories';

import TaxonomyAttributes from './attributes';
import TaxonomyCategories from './categories';
import TaxonomyHistory from './history';
import TaxonomyProducts from './products';
import TaxonomyProperties from './properties';
import TaxonomyRules from './rules';
import TaxonomySidebar from './sidebar';
import './styles.scss';

const { Sider } = Layout;

const TaxonomyContent = ({ showData, children }) => {
  const { selectedType, selectedAttribute, selectedCategory } =
    useContext(TaxonomyContext);
  const isSelected = selectedAttribute || selectedCategory;
  const tabs = useMemo(
    () =>
      Object.entries({
        properties: isSelected,
        rules:
          isSelected &&
          (selectedType === 'category' ||
            (selectedType !== 'category' &&
              selectedAttribute?.core.parentId !== null)),
        entities: true,
        products: isSelected,
        history: true,
      })
        .filter(([, value]) => value)
        .map(([key]) => `${key}`),
    [selectedType, selectedCategory, selectedAttribute]
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (showData) {
    let activeTab = pathname.replace('/taxonomy', '').replace('/', '');
    activeTab = `${activeTab.slice(0, 1).toUpperCase()}${activeTab.slice(1)}`;
    if (activeTab === 'Entities') {
      activeTab = selectedType === 'category' ? 'Attributes' : 'Categories';
    }

    if (activeTab === '') {
      activeTab = 'Properties';
    }

    return (
      <CustomTabs
        className="taxonomy-tabs"
        tabs={tabs.map((tab) => {
          if (tab !== 'entities')
            return {
              title: `${tab.slice(0, 1).toUpperCase()}${tab.slice(1)}`,
              url: `/taxonomy/${tab}`,
            };
          const title =
            selectedType === 'category' ? 'Attributes' : 'Categories';
          return {
            title,
            url: `/taxonomy/entities`,
          };
        })}
        activeTab={activeTab}
        tabContent={children}
        handleChange={(tab) => {
          navigate(tab.url);
        }}
      />
    );
  }

  if (
    (selectedType === 'category' && !selectedCategory) ||
    (selectedType === 'attribute' && !selectedAttribute)
  ) {
    return (
      <Empty
        className="position-center"
        description={`Please select ${selectedType} at sidebar`}
      />
    );
  }

  return (
    <div className="position-center">
      <Spin spinning />
      <h2>Loading...</h2>
    </div>
  );
};

const Taxonomy = () => {
  const client = useSelectedClient();
  const { tab: activeTab } = useParams();

  const [{ loading: updateCategoryLoading }] = useMutation(UPDATE_CATEGORY);
  const {
    selectedCategoryId,
    setSelectedCategory,
    selectedCategory,
    selectedAttributeId,
    selectedType,
    selectedAttribute,
    setSelectedAttribute,
  } = useContext(TaxonomyContext);
  const [showData, setShowData] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { loading: loadingSelectedCategory, data: selectedCategoryData } =
    useQuery(CATEGORY, {
      variables: {
        clientCode: client?.apiId,
        id: selectedCategoryId,
      },
      fetchPolicy: 'network-only',
      skip: !selectedCategoryId,
    });

  const {
    loading: loadingSelectedAttribute,
    data: selectedAttributeDataQuery,
  } = useQuery(ATTRIBUTE, {
    variables: {
      clientCode: client?.apiId,
      id: selectedAttributeId,
    },
    fetchPolicy: 'network-only',
    skip: !selectedAttributeId,
  });

  useEffect(() => {
    if (selectedCategory !== selectedCategoryData?.category?.data) {
      setSelectedCategory(selectedCategoryData?.category?.data);
    }
  }, [selectedCategoryData]);

  useEffect(() => {
    if (selectedAttribute !== selectedAttributeDataQuery?.attribute?.data) {
      setSelectedAttribute(selectedAttributeDataQuery?.attribute?.data);
    }
  }, [selectedAttributeDataQuery]);

  const handleToggleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  useEffect(() => {
    setShowData(
      selectedType === 'category'
        ? Boolean(selectedCategory)
        : Boolean(selectedAttribute)
    );
  }, [selectedType, selectedCategory, selectedAttribute]);

  return (
    <div className="ant-pro-grid-content taxonomy-content">
      <Layout>
        <Sider
          width={350}
          collapsedWidth={15}
          collapsible
          collapsed={collapsed}
          onCollapse={handleToggleClick}
          className="side-bar-content"
        >
          {collapsed ? (
            <div className="collapsed-sider-content" />
          ) : (
            <TaxonomySidebar />
          )}
        </Sider>
        <Layout>
          <Spin
            spinning={
              updateCategoryLoading ||
              loadingSelectedCategory ||
              loadingSelectedAttribute
            }
          >
            <TaxonomyContent showData={showData}>
              {activeTab === 'properties' && <TaxonomyProperties />}
              {activeTab === 'history' && <TaxonomyHistory />}
              {activeTab === 'rules' && <TaxonomyRules />}
              {activeTab === 'products' && <TaxonomyProducts />}
              {activeTab === 'entities' &&
                (selectedType === 'category' ? (
                  <TaxonomyAttributes />
                ) : (
                  <TaxonomyCategories />
                ))}
            </TaxonomyContent>
          </Spin>
        </Layout>
      </Layout>
    </div>
  );
};

Taxonomy.propTypes = {};

Taxonomy.defaultProps = {};

export default memo(Taxonomy);
