import { gql } from '@apollo/client';

export const GET_QUEUE_STATS = gql`
  query GetStats($clientCode: String!) {
    getStats(clientCode: $clientCode) {
      data
    }
  }
`;

export const CLEAR_QUEUE = gql`
  mutation ClearQueue($queueName: String!) {
    clearQueue(queueName: $queueName) {
      data
    }
  }
`;

export const RETRY_FAILED_JOBS = gql`
  mutation RetryFailedJobs($queueName: String!) {
    retryFailedJobs(queueName: $queueName) {
      data
    }
  }
`;

export const CLEAR_ALL_QUEUES = gql`
  mutation ClearAllQueues {
    clearAllQueues {
      data
    }
  }
`;

export const RETRY_ALL_FAILED_JOBS = gql`
  mutation RetryAllFailedTasks {
    retryAllFailedTasks {
      data
    }
  }
`;
