import { useQuery } from '@apollo/client';
import { Empty, Spin } from 'antd';
import React, { memo, useContext } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { RULES } from '@marketreach/services/apollo/rules';

import ProductsTable from '../../share/products/ProductsTable';
import './styles.scss';

const TaxonomyProducts = () => {
  const client = useSelectedClient();
  const {
    selectedCategory,
    selectedAttribute,
    selectedType: type,
  } = useContext(TaxonomyContext);

  const getTitle = () =>
    type === 'category' ? selectedCategory?.name : selectedAttribute?.name;

  const { data: ruleRes, loading } = useQuery(RULES, {
    variables: {
      clientCode: client?.apiId,
      ruleIds: (type === 'category' ? selectedCategory : selectedAttribute)
        ?.associations?.rules,
    },
  });

  const ruleData = ruleRes ? ruleRes?.rules?.data : [];
  const rules = ruleData || [];

  return (
    <div className="ant-pro-grid-content taxonomy-products-content">
      <Spin spinning={loading}>
        <div className="taxonomy-products-content-title">{getTitle()}</div>
        {!loading && rules.length > 0 && (
          <ProductsTable type={type} rules={rules} showManagement={false} />
        )}
        {!loading && rules.length === 0 && (
          <div className="taxonomy-products-content-empty">
            <Empty className="position-center" />
          </div>
        )}
      </Spin>
    </div>
  );
};

TaxonomyProducts.propTypes = {};

TaxonomyProducts.defaultProps = {};

export default memo(TaxonomyProducts);
