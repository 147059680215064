import { useCallback, useMemo } from 'react';

import { useSetSetting, useSetting } from '@marketreach/model/setting/api';

export const useExpandState = (entityType: string) => {
  const expandedKeysKey = `${entityType}_tree_view_expanded_keys`;

  const expandedKeyQueryResult = useSetting(expandedKeysKey);
  const { data: expandedKeysData } = expandedKeyQueryResult;

  const [mutate, expandedKeyMutationResult] = useSetSetting(expandedKeysKey);

  const expandedKeys: string[] = useMemo(
    () => expandedKeysData?.getSetting?.data?.value ?? [],
    [expandedKeysData?.getSetting?.data?.value]
  );

  const setExpandedKeys = useCallback(
    (newExpandedKeys: string[]) => {
      mutate(newExpandedKeys);
    },
    [mutate]
  );

  return {
    expandedKeys,
    setExpandedKeys,
    expandedKeyQueryResult,
    expandedKeyMutationResult,
  };
};
