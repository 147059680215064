import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
// @ts-ignore
import Froalaeditor from 'froala-editor';
import React, { memo, useEffect, useRef, useState } from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';

import { Client } from '@marketreach/model/clients/types';
import { COMPLETE_TEXT } from '@marketreach/services/apollo/util';

import { stripHTML, toolbarButtons } from './helper';

type FroalaRichTextEditorPropTypes = {
  data: string;
  onChange: (val: string) => void;
  client: Client;
  token: string;
  disabled: boolean;
};

/**
 * Froala WYSIWYG Rich Text Editor component with OpenAI functionality
 */
const FroalaRichTextEditor = (props: FroalaRichTextEditorPropTypes) => {
  const editorRef = useRef<any>();

  const { data: propsValue, onChange, client, token, disabled } = props;

  // Loading data
  const [textToComplete, setTextToComplete] = useState();
  const { data, loading } = useQuery(COMPLETE_TEXT, {
    variables: { phrase: textToComplete, clientCode: client.apiId },
    skip: !textToComplete,
    fetchPolicy: 'network-only',
  });

  // Insert/modify data once it arrives
  useEffect(() => {
    if (data) {
      const newText = data.completeText?.data.replace(/\n/g, '<br>');

      editorRef.current?.editor.html.insert(newText, true);
    }
  }, [data]);

  // Register button to Froala
  useEffect(() => {
    Froalaeditor.DefineIcon('openai', {
      SRC: '/images/ai.svg',
      ALT: 'AI',
      template: 'image',
    });
    Froalaeditor.RegisterCommand('openai', {
      title: 'Auto Complete',
      icon: 'openai',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: function () {
        const mainText = stripHTML(editorRef.current?.editor.html.get());
        const selection = editorRef.current?.editor.selection.text();

        const isSelection = selection !== '';
        if (isSelection) {
          editorRef.current?.editor.selection.save();
        }

        setTextToComplete(isSelection ? selection : mainText);
      },
    });
  }, []);

  /**
   * Generate config file upload params
   */
  const generateUploadConfig = () => {
    const uploadConfig: any = {};

    const uploadMethod = 'POST';
    const uploadUrl = `${process.env.REACT_APP_BASE_URI}/files/upload`;
    const uploadParams = {
      clientCode: client?.apiId,
    };

    for (const type of ['file', 'image', 'video']) {
      uploadConfig[`${type}UploadMethod`] = uploadMethod;
      uploadConfig[`${type}UploadURL`] = uploadUrl;
      uploadConfig[`${type}UploadParams`] = uploadParams;
    }

    return uploadConfig;
  };

  /**
   * Compile config
   */
  const config = () => {
    return {
      ...generateUploadConfig(),
      toolbarButtons: toolbarButtons,
      requestHeaders: {
        Authorization: `Bearer ${token}`,
      },
      events: {
        initialized: () => {
          // Set initial data since the component doesn't accept
          editorRef?.current?.editor?.html?.set?.(propsValue);

          // if props disabled === true - set read only mode
          if (disabled) {
            editorRef.current?.editor.edit.off();
          }
        },
      },
    };
  };

  return (
    <Spin spinning={loading || !token}>
      {token && (
        <FroalaEditorComponent
          ref={editorRef}
          onModelChange={(newText: any) => {
            onChange(newText);
          }}
          config={config()}
          tag="textarea"
        />
      )}
    </Spin>
  );
};

export default memo(FroalaRichTextEditor);
