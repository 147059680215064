import React, { memo } from 'react';

import TrendRanking from '../ranking';
import SalesTrendChart from './SalesTrendChart';
import './styles.scss';

const SalesTrend = () => (
  <div className="ant-row">
    <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-16">
      <SalesTrendChart />
    </div>
    <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-8">
      <TrendRanking title="Sales Ranking" />
    </div>
  </div>
);

export default memo(SalesTrend);
