import { useAuth0 } from '@auth0/auth0-react';
import { Layout, message } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import './styles.scss';

const ApiAccess = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    getAccessTokenSilently()
      .then((r) => setAccessToken(r))
      .catch((e) => {
        message.error(e.toString());
      });
  }, [getAccessTokenSilently]);

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4>Token</h4>
          <span>{accessToken}</span>
        </div>
      </Layout>
    </div>
  );
};

ApiAccess.propTypes = {};

ApiAccess.defaultProps = {};

export default memo(ApiAccess);
