export const slugPatternString = '[a-z0-9]+(?:-[a-z0-9]+)*';
export const emailPatternString =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
export const urlPatternString =
  '(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?';

export const preparePattern = (pattern, settings, inverse = false) => {
  const caseSensitive = settings.case_sensitive ? '' : 'i';
  const multiLine = settings.multi_line ? 'm' : '';
  const inverseStart = inverse ? '(?:(?!' : '';
  const inverseEnd = inverse ? ').)*' : '';
  return new RegExp(
    `^${inverseStart}${pattern}${inverseEnd}$`,
    `g${caseSensitive}${multiLine}`
  );
};

export const patternTypes = {
  EMAIL: 'email',
  URL: 'url',
  SLUG: 'slug',
  CUSTOM: 'custom',
};

const build = (settings, inverse = false) => {
  const rule = {};
  if (settings?.error) {
    rule.message = settings.error;
  }
  if (settings?.type === patternTypes.EMAIL) {
    if (inverse) {
      rule.pattern = preparePattern(emailPatternString, settings, inverse);
    } else {
      rule.type = 'email';
    }
  }
  if (settings?.type === patternTypes.URL) {
    if (inverse) {
      rule.pattern = preparePattern(urlPatternString, settings, inverse);
    } else {
      rule.type = 'url';
    }
  }
  if (settings?.type === patternTypes.SLUG) {
    rule.pattern = preparePattern(slugPatternString, settings, inverse);
  }
  if (settings?.type === patternTypes.CUSTOM) {
    rule.pattern = preparePattern(settings.pattern, settings, inverse);
  }
  if (Object.keys(rule).length > 0) {
    return [rule];
  }
};

export default build;
