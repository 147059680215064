import {
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { HStack } from '@chakra-ui/react';
import { Button } from 'antd';
import React, { Key, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { EntityContextMenu } from '@marketreach/components/contextMenus/EntityContextMenu';
import DisclosureToggle from '@marketreach/components/disclosureToggle';
import { Tree } from '@marketreach/components/tree';
import AddEntityDataDrawer from '@marketreach/model/entity/data/disclosure/AddEntityDataDrawer';
import { NormalizedEntityData } from '@marketreach/model/entity/data/types';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  getAllExpandableChildrenIds,
  getApiIdField,
  useTreeData,
} from '@marketreach/utils/common';

import { TreeViewPropTypes } from '.';
import { useExpandState } from './useExpandState';

type LeftSiderPropTypes = Pick<TreeViewPropTypes, 'entityInfo'> & {
  data: NormalizedEntityData[];
  selectedKey: Key | null;
  onSelect: (value: Key[]) => void;
};

export const LeftSider = ({
  entityInfo,
  data,
  selectedKey,
  onSelect,
}: LeftSiderPropTypes) => {
  const client = useSelectedClient();
  const { type } = useParams();

  const entityType = type ?? '';

  const { expandedKeys, setExpandedKeys } = useExpandState(entityType);

  const [, treeNodesDictionary] = useTreeData(
    data,
    getApiIdField(client, entityType) || 'name'
  );

  /* ----------------------------- Expanding Logic ---------------------------- */

  // Handle expanding all
  const onExpandAll = useCallback(() => {
    setExpandedKeys(data.map((item) => item._id));
  }, [data, setExpandedKeys]);

  // Handle collapsing all
  const onCollapseAll = useCallback(() => {
    setExpandedKeys([]);
  }, [setExpandedKeys]);

  // Handle manual expand
  const onExpand = useCallback(
    (val: Key[]) => {
      setExpandedKeys(val.map((v) => v.toString()));
    },
    [setExpandedKeys]
  );

  const onExpandChildren = useCallback(
    (val: Key) => {
      const id = val.toString();

      const newIds = Array.from(
        new Set([
          ...expandedKeys,
          id,
          ...getAllExpandableChildrenIds(treeNodesDictionary[id]),
        ])
      );
      setExpandedKeys(newIds);
    },
    [expandedKeys, setExpandedKeys, treeNodesDictionary]
  );

  const onCollapseChildren = useCallback(
    (val: Key) => {
      const id = val.toString();

      const idsTofilter = [
        id,
        ...getAllExpandableChildrenIds(treeNodesDictionary[id]),
      ];
      const newIds =
        expandedKeys?.filter((idToCheck) => !idsTofilter.includes(idToCheck)) ??
        [];
      setExpandedKeys(newIds);
    },
    [expandedKeys, setExpandedKeys, treeNodesDictionary]
  );

  /* --------------------------- End Expanding Logic -------------------------- */

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DisclosureToggle
        toggler={({ onToggle }) => (
          <Button
            className="categories-tree-add-btn"
            type="dashed"
            onClick={onToggle}
          >
            <PlusOutlined /> Add {entityInfo?.label}
          </Button>
        )}
      >
        <AddEntityDataDrawer entityType={entityType} />
      </DisclosureToggle>

      <HStack px={10}>
        <Button role="expand-all" block onClick={onExpandAll}>
          <PlusSquareOutlined /> Expand All
        </Button>
        <Button role="collapse-all" block onClick={onCollapseAll}>
          <MinusSquareOutlined /> Collapse All
        </Button>
      </HStack>

      <Tree
        containerClass="taxonomy-sidebar-tree-content"
        entities={data}
        totalCountLabel={entityType}
        isLoading={false}
        onSelect={onSelect}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        selectedKeys={selectedKey ? [selectedKey] : []}
        showLeafIcon
        entityType={entityType}
        entityInfo={entityInfo}
        contextMenuComponent={
          <EntityContextMenu
            entityType={entityType}
            data={data}
            label={entityInfo?.label}
            onExpandChildren={onExpandChildren}
            onCollapseChildren={onCollapseChildren}
          />
        }
      />
    </div>
  );
};
