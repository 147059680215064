import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Spin } from 'antd';
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
} from '@marketreach/utils/common';

import RightMenu from './RightMenu';
import './styles.scss';

const navigation = [
  {
    label: 'Taxonomy',
    path: '/taxonomy',
  },
  {
    label: 'Products',
    path: '/products',
  },
  {
    label: 'Feeds',
    path: '/feeds',
  },
  {
    label: 'Rules',
    path: '/rules',
  },
];

const TopBar = () => {
  const { isAuthenticated } = useAuth0();

  const client = useSelectedClient();

  const hardcodedLabels = {
    [ENTITY_TYPE_CATEGORY]: {
      label: 'Categories',
    },
    [ENTITY_TYPE_ATTRIBUTE]: {
      label: 'Attributes',
    },
  };

  const { pathname } = useLocation();

  const customEntities = () => {
    return client?.entities?.map((item) => (
      <Menu.Item key={`/entities/${item.slug}`}>
        <Link to={`/entities/${item.slug}`}>
          <span>{item.label_navigation || item.label}</span>
        </Link>
      </Menu.Item>
    ));
  };

  const hardcodedItems = () => {
    return [ENTITY_TYPE_CATEGORY, ENTITY_TYPE_ATTRIBUTE].map((item) => (
      <Menu.Item key={`/entities/${item}`}>
        <Link to={`/entities/${item}`}>
          <span>{hardcodedLabels[item]?.label || item}</span>
        </Link>
      </Menu.Item>
    ));
  };

  return (
    <nav className="menuBar">
      <div className="logo">MarketReach</div>

      <div className="leftMenu">
        {isAuthenticated && (
          <Spin spinning={!client}>
            <Menu mode="horizontal" defaultSelectedKeys={[pathname]}>
              {navigation.map(({ path, label }) => {
                return path ? (
                  <Menu.Item key={path}>
                    <Link to={path}>
                      <span>{label}</span>
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                );
              })}

              {hardcodedItems()}
              {customEntities()}
            </Menu>
          </Spin>
        )}
      </div>

      <div className="menuCon">
        <div className="rightMenu">
          <RightMenu />
        </div>
      </div>
    </nav>
  );
};

export default memo(TopBar);
