import { List, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './styles.scss';

const TrendRanking = (props) => {
  const { data, title } = props;

  return (
    <div className="trend-ranking-content">
      <List
        header={<div>{title}</div>}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                lineHeight: '20px',
                textAlign: 'center',
                background: item.position < 4 ? '#314659' : 'white',
                color: item.position < 4 ? 'white' : '#314659',
              }}
            >
              {item.position}
            </Typography.Text>
            <Typography.Text>{item.title}</Typography.Text>
            <Typography.Text>{item.value}</Typography.Text>
          </List.Item>
        )}
      />
    </div>
  );
};

TrendRanking.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(Object),
};

TrendRanking.defaultProps = {
  title: 'Ranking',
  data: [
    { position: 1, title: 'Company No.1 shop', value: 323234 },
    { position: 2, title: 'Company No.2 shop', value: 323234 },
    { position: 3, title: 'Company No.3 shop', value: 323234 },
    { position: 4, title: 'Company No.4 shop', value: 323234 },
    { position: 5, title: 'Company No.5 shop', value: 323234 },
    { position: 6, title: 'Company No.6 shop', value: 323234 },
    { position: 7, title: 'Company No.7 shop', value: 323234 },
  ],
};

export default memo(TrendRanking);
