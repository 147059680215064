import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dropdown, Menu, message, Modal, Spin, Typography } from 'antd';
import * as _ from 'lodash';
import React, { memo, useContext, useState } from 'react';

import { Chips } from '@marketreach/components/chips';
import CustomProTable from '@marketreach/components/protable';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import {
  ATTRIBUTE_QUERY_NAME,
  ATTRIBUTES,
  ATTRIBUTES_QUERY_NAME,
} from '@marketreach/services/apollo/attributes';
import {
  CATEGORIES,
  CATEGORIES_QUERY_NAME,
  CATEGORY_QUERY_NAME,
} from '@marketreach/services/apollo/categories';
import { GET_PRODUCT_BY_PAGE_QUERY } from '@marketreach/services/apollo/products';
import {
  CREATE_RULE,
  DELETE_RULE,
  RULES,
  RULES_BY_PAGE_QUERY,
  RULES_QUERY,
} from '@marketreach/services/apollo/rules';
import { capitalize } from '@marketreach/utils/common';

import RuleEdit from '../../rules/components/rule-form/RuleForm';
import ProductsTable from '../../share/products/ProductsTable';
import './styles.scss';

const { Text } = Typography;

const refetchQueriesList = [
  ATTRIBUTES_QUERY_NAME,
  GET_PRODUCT_BY_PAGE_QUERY,
  RULES_QUERY,
  RULES_BY_PAGE_QUERY,
  CATEGORIES_QUERY_NAME,
  CATEGORY_QUERY_NAME,
  ATTRIBUTE_QUERY_NAME,
];

const basisCell = (basis) => {
  const color = basis === 'include' ? '#2cae2e' : '#ff1f2b';
  return (
    <div style={{ color }} className="rule-basis">
      {basis === 'include' ? <PlusCircleOutlined /> : <MinusCircleOutlined />}
      <Text
        className="rule-basis-title"
        style={{
          color,
          marginLeft: 8,
        }}
      >
        {capitalize(basis)}
      </Text>
    </div>
  );
};

const columns = (
  categoryData,
  attributeData,
  handleDeleteRule,
  handleEditRule
) => [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Basis',
    dataIndex: 'basis',
    render: (text, row) => basisCell(row.basis),
  },
  {
    title: 'Match',
    dataIndex: 'match',
  },
  {
    title: 'Word',
    dataIndex: 'word',
  },
  {
    title: 'Case sensitive',
    dataIndex: 'case_sensitive',
    // eslint-disable-next-line camelcase
    render: (text, row) => <span>{(!!row?.case_sensitive).toString()}</span>,
  },
  {
    title: 'Key',
    // dataIndex: 'key',
    render: (text, row) => (
      <span>{row?.simpleMode ? row.key : 'advanced mode'}</span>
    ),
  },
  {
    title: 'Criteria',
    dataIndex: 'criteria',
    render: (text, row) =>
      row?.simpleMode ? <Chips entities={row.criteria} /> : row.advancedQuery,
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    render: (text, row) => (
      <span>
        {row?.associations?.categories?.filter((item) =>
          _.find(categoryData, (o) => o._id === item)
        ).length || 0}
      </span>
    ),
  },
  {
    title: 'Attributes',
    dataIndex: 'attributes',
    render: (text, row) => (
      <span>
        {row?.associations?.attributes?.filter((item) =>
          _.find(attributeData, (o) => o._id === item)
        ).length || 0}
      </span>
    ),
  },
  {
    title: 'Product IDs',
    dataIndex: 'associatedProductIds',
    render: (text, row) => <span>{row?.productIds?.length || 0}</span>,
  },
  {
    title: 'SKUs',
    dataIndex: 'skus',
    render: (text, row) => <span>{row?.skus?.length || 0}</span>,
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    render: (text, row) => [
      <Button
        key="delete"
        type="text"
        shape="circle"
        onClick={() => handleDeleteRule(row)}
      >
        <DeleteOutlined />
      </Button>,
      <Button
        key="edit"
        type="text"
        shape="circle"
        onClick={() => handleEditRule(row)}
      >
        <EditOutlined />
      </Button>,
    ],
  },
];

const menu = (
  <Menu>
    <Menu.Item key="1">Menu 1</Menu.Item>
    <Menu.Item key="2">Menu 2</Menu.Item>
  </Menu>
);

const TaxonomyRules = () => {
  const client = useSelectedClient();
  const {
    selectedCategory,
    selectedAttribute,
    selectedType: type,
  } = useContext(TaxonomyContext);

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL CATEGORIES                             */
  /* -------------------------------------------------------------------------- */
  const { data: categoryRes, loading: categoriesLoading } = useQuery(
    CATEGORIES,
    {
      variables: { clientCode: client?.apiId },
    }
  );
  const categoryData = categoryRes ? categoryRes.categories?.data : [];

  /* -------------------------------------------------------------------------- */
  /*                             GET ALL ATTRIBUTES                             */
  /* -------------------------------------------------------------------------- */
  const { data: attributeRes, loading: attributesLoading } = useQuery(
    ATTRIBUTES,
    {
      variables: { clientCode: client?.apiId },
    }
  );
  const attributeData = attributeRes ? attributeRes.attributes?.data : [];

  const [createRule] = useMutation(CREATE_RULE);
  const [deleteRule] = useMutation(DELETE_RULE);
  const { data: ruleRes, loading: rulesLoading } = useQuery(RULES, {
    variables: {
      clientCode: client?.apiId,
      ruleIds: (type === 'category' ? selectedCategory : selectedAttribute)
        ?.associations?.rules,
    },
    fetchPolicy: 'network-only',
  });

  const ruleData = ruleRes ? ruleRes?.rules?.data : [];
  const rules = ruleData || [];

  const [isEdit, setIsEdit] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const handleSaveClick = async (values) => {
    const createResult = await createRule({
      variables: {
        ...values,
        clientCode: client?.apiId,
        categoryIds: [type === 'category' ? selectedCategory?._id : null],
        attributeIds: [type === 'attribute' ? selectedAttribute?._id : null],
        associations: {
          categories: type === 'category' ? [selectedCategory?._id] : [],
          attributes: type === 'attribute' ? [selectedAttribute?._id] : [],
        },
        selectedRuleId: selectedRule?._id,
        criteria: (values?.criteria || []).map((item) => item.toString()),
      },
      awaitRefetchQueries: true,
      refetchQueries: refetchQueriesList,
    });

    if (createResult?.data?.createRule?.success === true) {
      message.info('Rule created successfully');
    } else {
      message.error(createResult?.data?.createRule?.error);
    }
    setIsEdit(false);
  };

  const handleEditRule = (rule) => {
    setSelectedRule(rule);
    setIsEdit(true);
  };

  const [preview, setPreview] = useState(false);
  const onClickPreview = () => {
    setPreview(!preview);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleDeleteRuleOk = () => {
    deleteRule({
      variables: {
        _id: selectedRule?._id,
        clientCode: client?.apiId,
        categoryId: type === 'category' ? selectedCategory?._id : null,
        attributeId: type === 'attribute' ? selectedAttribute?._id : null,
      },
      refetchQueries: refetchQueriesList,
    }).then(() => {
      setOpenConfirmModal(false);
      setSelectedRule(null);
    });
  };

  const handleDeleteRuleCancel = () => {
    setOpenConfirmModal(false);
    setSelectedRule(null);
  };

  const handleDeleteRule = (rule) => {
    setSelectedRule(rule);
    setOpenConfirmModal(true);
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    setSelectedRule(null);
  };

  const onClickEdit = () => {
    setIsEdit(true);
  };

  const toolbar = () => {
    const buttons = [];
    buttons.push(
      <Button type="primary" onClick={onClickEdit}>
        <PlusOutlined /> Add New
      </Button>
    );
    if (rules.length > 0) {
      buttons.push(
        <Button type="link" onClick={onClickPreview}>
          Preview {preview ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </Button>
      );
    }

    buttons.push(
      <Dropdown overlay={menu} key="export">
        <Button type="link">
          Export <DownOutlined />
        </Button>
      </Dropdown>
    );
    return buttons;
  };

  const getTitle = () =>
    type === 'category' ? selectedCategory?.name : selectedAttribute?.name;

  return (
    <div className="ant-pro-grid-content taxonomy-rules-content">
      <Spin spinning={rulesLoading || attributesLoading || categoriesLoading}>
        <div className="taxonomy-rules-content-title">{getTitle()}</div>
        {!isEdit ? (
          <CustomProTable
            className="taxonomy-rules-content-table"
            dataSource={rules}
            rowKey="_id"
            columns={columns(
              categoryData,
              attributeData,
              handleDeleteRule,
              handleEditRule
            )}
            toolbar={toolbar}
            pagination={{ defaultPageSize: 20 }}
            selectable={false}
          />
        ) : (
          <RuleEdit
            type={type}
            rule={selectedRule}
            client={client}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {preview && rules.length > 0 && !isEdit && (
          <ProductsTable client={client} type={type} rules={rules} />
        )}
        {openConfirmModal && (
          <Modal
            className="rule-delete-modal"
            title="Delete rule"
            visible={openConfirmModal}
            onOk={handleDeleteRuleOk}
            onCancel={handleDeleteRuleCancel}
            width={400}
          >
            <div>Are sure want to delete this rule?</div>
          </Modal>
        )}
      </Spin>
    </div>
  );
};

TaxonomyRules.propTypes = {};

TaxonomyRules.defaultProps = {};

export default memo(TaxonomyRules);
