import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { Reducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { Client, defaultEmptyClient } from '@marketreach/model/clients/types';
import { PrimaryKey } from '@marketreach/model/types';
import { CLIENTS } from '@marketreach/services/apollo/clients';

type ClientState = {
  allData: Client[] | null;
  loading: boolean;
  selectedClient: PrimaryKey | null;
};

const defaultState: {
  state: ClientState;
  dispatch: React.Dispatch<ClientsProviderDispatch>;
} = {
  state: { allData: null, loading: true, selectedClient: null },
  dispatch: () => {
    // Intentional blank default
  },
};

const ClientsContext = createContext(defaultState);

export const CLIENTS_ACTIONS = {
  updateClients: 'UPDATE_CLIENTS',
  setSelected: 'SET_CLIENT',
} as const;

type ValueOf<T> = T[keyof T];
export type ClientsProviderDispatch = Partial<ClientState> & {
  type: ValueOf<typeof CLIENTS_ACTIONS>;
};

const clientsReducer: Reducer<ClientState, ClientsProviderDispatch> = (
  state,
  action
) => {
  switch (action.type) {
    case CLIENTS_ACTIONS.updateClients: {
      return {
        ...state,
        allData: action.allData ?? state.allData,
        loading: false,
      };
    }
    case CLIENTS_ACTIONS.setSelected: {
      return {
        ...state,
        selectedClient: action.selectedClient ?? state.selectedClient,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

type ClientsProviderPropTypes = { children: React.ReactNode };
export function ClientsProvider({ children }: ClientsProviderPropTypes) {
  const { isLoading, isAuthenticated } = useAuth0();

  const [state, dispatch] = React.useReducer(clientsReducer, {
    allData: null,
    selectedClient: null,
    loading: true,
  });

  useQuery<{ clients: { data: Client[] } }>(CLIENTS, {
    skip: isLoading || !isAuthenticated,
    onCompleted: (data) => {
      // Copy the data over to our redux state
      dispatch({
        type: CLIENTS_ACTIONS.updateClients,
        allData: data.clients.data,
      });

      // On load, set the first client as selected
      if (data.clients.data.length > 0 && state.selectedClient === null) {
        dispatch({
          type: CLIENTS_ACTIONS.setSelected,
          selectedClient: data.clients.data[0]._id,
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  return (
    <ClientsContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientsContext.Provider>
  );
}

// Access Methods

const checkProvider = (val: any) => {
  if (val === undefined) {
    throw new Error('useClientsState must be used within the ClientsProvider');
  }
};

export const useClientsState = () => {
  const state = useContextSelector(ClientsContext, (val) => val.state);
  checkProvider(state);

  return {
    all: state.allData,
    selected: state.allData?.find((x) => x._id === state.selectedClient),
  };
};
export const useClientsDispatch = () => {
  const dispatch = useContextSelector(ClientsContext, (val) => val.dispatch);
  checkProvider(dispatch);

  return dispatch;
};

export const useAllClientData = () => {
  const state = useContextSelector(ClientsContext, (val) => val.state?.allData);
  checkProvider(state);

  return state;
};
export const useSelectedClient = (): Client => {
  const state = useContextSelector(ClientsContext, (val) => val.state);
  checkProvider(state);

  return (
    state.allData?.find((x) => x._id === state.selectedClient) ??
    // When we use this, it's almost always going to be populated already.
    // The empty value will only be there while client data is still loading.
    defaultEmptyClient
  );
};
