import React from 'react';

import {
  PROPERTY_TYPE_CODE_EDIT,
  PROPERTY_TYPE_COLOR,
  PROPERTY_TYPE_DATE,
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_INTEGER,
  PROPERTY_TYPE_LIST,
  PROPERTY_TYPE_RADIOBUTTON,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_RICH_TEXT,
  PROPERTY_TYPE_TOGGLE,
  PROPERTY_TYPE_UPLOAD,
} from '@marketreach/components/section/PropertyItem';

import CodeEdit from './codeEdit';
import Color from './color';
import DateItem from './date';
import Decimal from './decimal';
import Integer from './integer';
import List from './list';
import RadioButton from './radioButton';
import Relation from './relation';
import RichText from './richText';
import Text from './text';
import Toggle from './toggle';
import Upload from './upload';

const PropertyInput = ({ type, ...props }) => {
  switch (type) {
    case PROPERTY_TYPE_INTEGER:
      return <Integer {...props} />;
    case PROPERTY_TYPE_DECIMAL:
      return <Decimal {...props} />;
    case PROPERTY_TYPE_TOGGLE:
      return <Toggle {...props} />;
    case PROPERTY_TYPE_LIST:
      return <List {...props} />;
    case PROPERTY_TYPE_DATE:
      return <DateItem {...props} />;
    case PROPERTY_TYPE_COLOR:
      return <Color {...props} />;
    case PROPERTY_TYPE_UPLOAD:
      return <Upload {...props} />;
    case PROPERTY_TYPE_CODE_EDIT:
      return <CodeEdit {...props} />;
    case PROPERTY_TYPE_RICH_TEXT:
      return <RichText {...props} />;
    case PROPERTY_TYPE_RELATION:
      return <Relation {...props} />;
    case PROPERTY_TYPE_RADIOBUTTON:
      return <RadioButton {...props} />;
    default:
      return <Text {...props} />;
  }
};

export default React.memo(PropertyInput);
