import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Select, Spin } from 'antd';
import Layout from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';
import { GET_OPENAI_ENGINES_LIST } from '@marketreach/services/apollo/util';

import './styles.scss';

const { Option } = Select;

/**
 * Page for OpenAI service settings
 * @return {JSX.Element}
 * @constructor
 */
export const GPT3Settings = () => {
  const client = useSelectedClient();
  const [form] = Form.useForm();

  const [enginesList, setEnginesList] = useState([]);

  const defaultSettings = {
    engine: 'davinci',
    maxTokens: 2048,
    temperature: 0.9,
    topP: 1,
    presencePenalty: 0,
    frequencyPenalty: 0,
    bestOf: 1,
    n: 1,
  };

  const { data: userSettings, loading: userSettingsLoading } = useQuery(
    GET_SETTING,
    {
      variables: {
        clientCode: client.apiId,
        key: 'openai_settings',
      },
    }
  );

  const { data: enginesListData, loading: loadEnginesList } = useQuery(
    GET_OPENAI_ENGINES_LIST
  );

  const [setSettings, { loading: setSettingsLoading }] =
    useMutation(SET_SETTING);

  useEffect(() => {
    if (userSettings?.getSetting?.data) {
      form.setFieldsValue(
        userSettings?.getSetting?.data?.value || defaultSettings
      );
    } else {
      form.setFieldsValue(defaultSettings);
    }
  }, [userSettings]);

  useEffect(() => {
    if (enginesListData?.getOpenAIEnginesList?.data) {
      setEnginesList(enginesListData?.getOpenAIEnginesList?.data);
    }
  }, [enginesListData, setEnginesList]);

  /**
   * Save settings
   * @param values
   * @return {Promise<void>}
   */
  const onFinish = async (values) => {
    await setSettings({
      variables: {
        key: 'openai_settings',
        value: values,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
    });
  };

  return (
    <div className="ant-pro-grid-content settings-content-locale">
      <Layout className="api-access-main">
        <div>
          <h4 className="capitalized">GPT3 settings</h4>

          <Spin
            spinning={
              userSettingsLoading || setSettingsLoading || loadEnginesList
            }
          >
            <div>
              <Form
                form={form}
                onFinish={onFinish}
                initialValues={defaultSettings}
              >
                <Form.Item label="Engine" name="engine">
                  <Select>
                    {enginesList.map((item) => (
                      <Option value={item.key} key={item.key}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Max words count" name="maxTokens">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="Temperature" name="temperature">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="topP" name="topP">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="presencePenalty" name="presencePenalty">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="frequencyPenalty" name="frequencyPenalty">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="bestOf" name="bestOf">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item label="n" name="n">
                  <Input type={'number'} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Button type="reset" htmlType="reset">
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </div>
      </Layout>
    </div>
  );
};
