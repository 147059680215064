import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { TaxonomyContext } from '@marketreach/providers/TaxonomyProvider';
import { CATEGORIES } from '@marketreach/services/apollo/categories';

import ProductsTable from '../../share/products/ProductsTable';
import './styles.scss';

const TaxonomyCategoriesPreview = (props) => {
  const { previewFilters } = props;

  const client = useSelectedClient();

  const {
    selectedAttribute,
    associations,
    categories: rawCategories,
  } = useContext(TaxonomyContext);

  const [skus, setSkus] = useState([]);
  const { data: previewCategoriesInfo, loading: previewCategoriesLoading } =
    useQuery(CATEGORIES, {
      variables: {
        clientCode: client?.apiId,
        fullMode: true,
        filterParams: {
          _id: {
            $in: rawCategories
              .map((item) => item._id)
              .filter((item) => {
                if (previewFilters.length > 0) {
                  return previewFilters.indexOf(item) !== -1;
                }
                return true;
              }),
          },
        },
      },
    });

  useEffect(() => {
    if (previewCategoriesInfo?.categories?.data?.length > 0) {
      const skusObject = previewCategoriesInfo.categories.data.reduce(
        (acc, item) => {
          acc[item.core.rootId] = acc[item.core.rootId] || [];
          acc[item.core.rootId].push(item.skus);
          return acc;
        },
        {}
      );

      const intersectedGroupSkus = Object.values(skusObject).map(
        (skusGroup) => {
          return _.intersection(skusGroup.flat(), selectedAttribute.skus);
        }
      );

      const intersectedSkus = _.intersection(...intersectedGroupSkus);

      setSkus(intersectedSkus);
    } else {
      setSkus(selectedAttribute?.skus || []);
    }
  }, [previewCategoriesInfo, selectedAttribute]);

  const categories = associations?.categories || [];

  return (
    <div className="categories-content-preview">
      <Spin spinning={previewCategoriesLoading}>
        <ProductsTable
          key={previewFilters}
          type="attribute"
          skuArray={skus}
          realSearch={categories.length > 0}
          conditionExists={previewFilters.length > 0}
        />
      </Spin>
    </div>
  );
};

TaxonomyCategoriesPreview.propTypes = {
  previewFilters: PropTypes.arrayOf(Object),
};

TaxonomyCategoriesPreview.defaultProps = {
  previewFilters: [],
};

export default memo(TaxonomyCategoriesPreview);
