import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { PROPERTY_TYPE_UPLOAD } from '@marketreach/components/section/PropertyItem';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import { ENTITY_DATA } from '@marketreach/services/apollo/entity';
import {
  CORE_NAME_PROPERTY,
  getSystemEntities,
} from '@marketreach/utils/common';
import { getFileUploadImage } from '@marketreach/utils/files';

import './styles.scss';

/**
 * Component for display entity by ids
 *
 * @param props
 * @return {JSX.Element|*|string|string}
 * @constructor
 */
const EntityTableDisplay = (props) => {
  const { type, labelField, labelFieldType, values } = props;
  const client = useSelectedClient();

  const [pageNumber] = useState(1);
  const [pageSize] = useState(5000);

  const [searchParams] = useState({ _id: values });
  const [sortParams] = useState({});

  const [entities, setEntities] = useState([]);

  const { loading, data: entityData } = useQuery(ENTITY_DATA, {
    variables: {
      clientCode: client?.apiId,
      type,
      page: pageNumber,
      pageSize,
      searchParams,
      sortParams,
    },
    fetchPolicy: 'cache-and-network',
    skip: !type,
  });

  useEffect(() => {
    if (entityData && entityData?.entityData?.data) {
      setEntities(entityData?.entityData?.data);
    }
  }, [entityData, setEntities]);

  /**
   * Get entity data
   * @param entity
   * @param field
   * @return {boolean|*}
   */
  const getEntityData = (entity, field) => {
    if (entity && entity?.properties) {
      if (getSystemEntities().includes(entity?.core?.type)) {
        if (field === CORE_NAME_PROPERTY) {
          return entity.name;
        }
      }
      for (const sectionName of Object.keys(entity.properties)) {
        if (typeof entity?.properties[sectionName][field] !== 'undefined') {
          return entity?.properties[sectionName][field];
        }
      }
    }

    return false;
  };

  return (
    <Spin spinning={loading}>
      {entities.length > 0 &&
        entities.map((entity) => {
          const entityDataInfo = getEntityData(entity, labelField);

          const data = [];
          if (!_.isArray(entityDataInfo)) {
            data.push(entityDataInfo);
          } else {
            data.push(...entityDataInfo);
          }

          if (labelFieldType === PROPERTY_TYPE_UPLOAD) {
            return data.map((file) => {
              const imageUrl = getFileUploadImage(file);
              return (
                <p>
                  <img src={imageUrl} className={'tableImage'} />
                </p>
              );
            });
          }
          return data?.map((text) => <p>{text?.toString()}</p>);
        })}
      {entities.length === 0 && <p />}
    </Spin>
  );
};

EntityTableDisplay.propTypes = {
  type: PropTypes.string,
  labelField: PropTypes.string,
  labelFieldType: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
};

EntityTableDisplay.defaultProps = {
  type: null,
  labelField: null,
  labelFieldType: null,
  values: null,
};

export default memo(EntityTableDisplay);
