import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import RuleFormPopup from '@marketreach/components/rule/RuleFormPopup';
import RulesTable from '@marketreach/components/rule/RulesTable';

/**
 * Popup for dynamic rules creation
 * On `onOk` returned list with ids of created rules
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const AddRulesPopup = (props) => {
  const { visible, onOk, onCancel, linkedRulesIds } = props;

  // States
  const [rulesIds, setRulesIds] = useState(linkedRulesIds);
  const [loading, setLoading] = useState(false);
  const [showRuleForm, setShowRuleForm] = useState(false);

  // Queries

  // Effects

  // Handlers
  const onAddRuleClick = () => {
    setShowRuleForm(true);
  };

  const closeRuleFormPopup = () => {
    setLoading(false);
    setShowRuleForm(false);
  };

  const onRuleFormPopupOk = (idsFromPopup) => {
    setRulesIds([...rulesIds, ...idsFromPopup]);
    closeRuleFormPopup();
  };

  const handleOkClick = () => {
    onOk(rulesIds);
  };

  const removeRule = (id) => {
    setRulesIds([...rulesIds.filter((ruleId) => ruleId !== id)]);
  };

  return (
    <Modal
      title="Rules"
      centered
      visible={visible}
      onOk={handleOkClick}
      onCancel={onCancel}
      width={'80%'}
    >
      <Spin spinning={loading}>
        <RulesTable
          rulesIds={rulesIds}
          setLoading={setLoading}
          onAddRuleClick={onAddRuleClick}
          removeRule={removeRule}
          allowEdit={false}
        />
        {showRuleForm && (
          <RuleFormPopup
            visible={showRuleForm}
            onOk={onRuleFormPopupOk}
            onCancel={closeRuleFormPopup}
          />
        )}
      </Spin>
    </Modal>
  );
};

AddRulesPopup.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  linkedRulesIds: PropTypes.arrayOf(PropTypes.string),
};

AddRulesPopup.defaultProps = {
  visible: false,
  onOk: () => null,
  onCancel: () => null,
  linkedRulesIds: [],
};

export default AddRulesPopup;
