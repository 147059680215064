import { Form, Input, Modal, Spin, Switch, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import React from 'react';

import { SYSTEM_COLLECTIONS } from '@marketreach/pages/auth/settings/CustomEntity';
import { buildSlug } from '@marketreach/utils/common';
import { buildRule } from '@marketreach/utils/validation';

const CustomEntityModal = (props) => {
  const {
    client,
    entityToEdit,
    form,
    handleCreateCustomEntity,
    onNameChange,
    handleTriggerAddEntity,
    clientCreationInProgress,
    showAddNewModal,
  } = props;

  /**
   * At least one view should be selected
   * By default should be enabled table view
   */
  const checkEnabledViews = () => {
    const formData = form.getFieldsValue();
    if (!formData.allowTableView && !formData.allowTreeView) {
      form.setFieldsValue({
        ...formData,
        allowTableView: true,
      });
    }
  };

  return (
    <Modal
      className="custom-entity-edit-modal"
      title={entityToEdit ? 'Edit Custom Entity' : 'Add New Custom Entity'}
      visible={showAddNewModal}
      onOk={handleCreateCustomEntity}
      onCancel={handleTriggerAddEntity}
      getContainer={false}
    >
      <Spin spinning={clientCreationInProgress}>
        <div>
          <Form form={form} name={'entity_add_form'}>
            <Typography className="custom-entity-edit-modal-label">
              Name
            </Typography>
            <Form.Item
              name="label"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    if (!client?.entities) {
                      return Promise.resolve();
                    }
                    return client?.entities
                      ?.map((entity) => entity.slug)
                      .indexOf(buildSlug(value)) === -1
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: 'This value already used',
                },
              ]}
            >
              <Input
                onChange={onNameChange}
                rules={[
                  buildRule(
                    'unique',
                    true,
                    client?.entities?.map((entity) => entity.slug)
                  ),
                ]}
                placeholder={
                  'Descriptive name (singular) for the new custom entity (ex: Post)'
                }
              />
            </Form.Item>

            <Typography className="custom-entity-edit-modal-label">
              Name (Plural)
            </Typography>
            <Form.Item name="label_plural" rules={[{ required: true }]}>
              <Input
                placeholder={
                  'Descriptive name (plural) for the new custom entity (ex: Posts)'
                }
              />
            </Form.Item>

            <Typography className="custom-entity-edit-modal-label">
              Name (Navigation)
            </Typography>
            <Form.Item name="label_navigation" shouldUpdate>
              <Input
                placeholder={
                  'Name displayed in the top navigation menu (ex: Blog)'
                }
              />
            </Form.Item>

            <Typography className="custom-entity-edit-modal-label">
              Description (optional)
            </Typography>
            <Form.Item name="description" shouldUpdate>
              <TextArea
                autoSize={{ minRows: 2 }}
                placeholder={
                  'Displays a text hint for content editors and API users (ex: Manage Blog Post'
                }
              />
            </Form.Item>

            <Typography className="custom-entity-edit-modal-label">
              API ID
            </Typography>
            <Form.Item
              name="slug"
              shouldUpdate
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    if (!client?.entities) {
                      return Promise.resolve();
                    }
                    return client?.entities
                      ?.map((entity) => entity.slug.toLowerCase())
                      .indexOf(buildSlug(value)) === -1
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: 'This value already used',
                },
                {
                  validator: (_, value) => {
                    return SYSTEM_COLLECTIONS.indexOf(buildSlug(value)) === -1
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: 'This value is not allowed',
                },
              ]}
            >
              <Input
                rules={[
                  buildRule(
                    'unique',
                    true,
                    client?.entities?.map((entity) => entity.slug)
                  ),
                ]}
                placeholder={
                  'ID used when accessing this custom entity via the API'
                }
              />
            </Form.Item>

            <Typography className="custom-entity-edit-modal-label">
              Enabled views
            </Typography>
            <Form.Item
              name="allowTableView"
              label="Table view"
              shouldUpdate
              hasFeedback
              valuePropName="checked"
            >
              <Switch onChange={checkEnabledViews} />
            </Form.Item>
            <Form.Item
              name="allowTreeView"
              label="Tree view"
              shouldUpdate
              hasFeedback
              valuePropName="checked"
            >
              <Switch onChange={checkEnabledViews} />
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

CustomEntityModal.propTypes = {
  client: PropTypes.objectOf(PropTypes.any),
  entityToEdit: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.objectOf(PropTypes.any),
  handleCreateCustomEntity: PropTypes.func,
  onNameChange: PropTypes.func,
  handleTriggerAddEntity: PropTypes.func,
  clientCreationInProgress: PropTypes.bool,
  showAddNewModal: PropTypes.bool,
};

CustomEntityModal.defaultProps = {
  client: null,
  entityToEdit: null,
  form: null,
  handleCreateCustomEntity: () => null,
  onNameChange: () => null,
  handleTriggerAddEntity: () => null,
  clientCreationInProgress: false,
  showAddNewModal: false,
};

export default CustomEntityModal;
