import { Form } from 'antd';
import React from 'react';

import PropertiesTemplateUpload from '@marketreach/components/section/PropertiesTemplateUpload';

const Upload = (props) => {
  const {
    preDefinedVal,
    hide,
    readOnly,
    rules,
    settings,
    client,
    onChange,
    validation,
  } = props;
  const { key } = settings;

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(val) => {
        return val;
      }}
    >
      <PropertiesTemplateUpload
        readOnly={readOnly}
        imageUrl={preDefinedVal}
        client={client}
        onChange={onChange}
        validation={validation}
      />
    </Form.Item>
  );
};

export default React.memo(Upload);
