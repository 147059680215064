import { useAuth0 } from '@auth0/auth0-react';
import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { BasePropertyProps } from '@marketreach/@types/properties';
import FroalaRichTextEditor from '@marketreach/components/froala';
import { useSelectedClient } from '@marketreach/providers/ClientsProvider';

/**
 * Rich text editor input control
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const RichEdit = (props: BasePropertyProps) => {
  const client = useSelectedClient();
  const { getAccessTokenSilently } = useAuth0();

  const { preDefinedVal, hide, rules, settings, onChange, disabled } = props;
  const { key } = settings;

  const [accessToken, setAccessToken] = useState<string>('');

  const handleChange = useCallback(
    (data) => {
      return onChange(data);
    },
    [onChange]
  );

  useEffect(() => {
    const isMounted = true; // note mutable flag

    getAccessTokenSilently()
      .then((token: string) => {
        if (isMounted) {
          return setAccessToken(token);
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  }, [getAccessTokenSilently, setAccessToken]);

  return (
    <Form.Item
      name={key}
      rules={rules}
      initialValue={preDefinedVal?.toString()}
      hidden={hide ?? false}
      hasFeedback
      getValueFromEvent={(data) => {
        return data;
      }}
    >
      <FroalaRichTextEditor
        key={preDefinedVal?.toString()}
        data={preDefinedVal?.toString()}
        onChange={handleChange}
        client={client}
        token={accessToken}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default RichEdit;
