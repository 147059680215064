import {
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
} from '@marketreach/utils/common';

import { Entity } from './types';

/**
 * Get info for the hardcoded/system entities
 */
export const getHardCodedTypesInfo = (entityType: string): Entity => {
  switch (entityType) {
    case ENTITY_TYPE_CATEGORY:
      return {
        label: 'Category',
        label_plural: 'Categories',
        allowTableView: true,
        allowTreeView: true,
      };
    case ENTITY_TYPE_ATTRIBUTE:
      return {
        label: 'Attribute',
        label_plural: 'Attributes',
        allowTableView: true,
        allowTreeView: true,
      };
    default:
      return {
        label: entityType,
        label_plural: entityType,
        allowTableView: true,
        allowTreeView: true,
      };
  }
};

/**
 * Function to get the correct entityInfo from list of entities
 */
export const getEntityInfo = (entities: Entity[], entityType: string) => {
  return (
    entities.find((item) => item.slug === entityType) ||
    getHardCodedTypesInfo(entityType)
  );
};
