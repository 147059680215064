import { isArray, isObject } from 'lodash';
import * as _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import slugify from 'slugify';

import {
  PROPERTY_TYPE_DECIMAL,
  PROPERTY_TYPE_RELATION,
  PROPERTY_TYPE_UPLOAD,
} from '@marketreach/components/section/PropertyItem';
import EntityTableDisplay from '@marketreach/pages/taxonomy/properties/extra/EntityTableDisplay';
import { getFileUploadImage } from '@marketreach/utils/files';

const CORE_NAME_PROPERTY = 'core.name';

const sortByOrder = ({ order: orderA = 0 }, { order: orderB = 0 }) =>
  orderA - orderB;

const sortByField = (field) => (a, b) => {
  return a[field]?.toUpperCase().localeCompare(b[field]?.toUpperCase());
};

const capitalize = (str) => {
  if (typeof str !== 'string' || !str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const buildSlug = (str) => {
  // you can read the doc here - https://github.com/simov/slugify
  return slugify(str.replace(/^https?:\/\//, ''), {
    locale: 'en',
    lower: true,
    replacement: '_',
    remove: /[*+~.()'"!:@\\/<>?]/g,
  });
};

const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
};

const normalizeEntity = (data, simpleView = false) => {
  return data.map((item) => {
    const insideProp = {};

    if (item?.properties) {
      for (const section of Object.keys(item.properties)) {
        for (const key of Object.keys(item.properties[section])) {
          insideProp[key] = item?.properties[section][key];
        }
      }
    }
    for (const prop of Object.keys(item)) {
      if (prop !== 'properties' && prop !== 'relatedFieldsData') {
        insideProp[prop] = item[prop];
      }
    }

    if (getSystemEntities().includes(item?.core?.type)) {
      insideProp[CORE_NAME_PROPERTY] = item.name;
    }

    const preparedData = {
      ...insideProp,
      _id: item._id,
      parentId: item?.core?.parentId,
      core: item?.core,
    };
    if (item.relatedFieldsData && simpleView) {
      for (const field in item.relatedFieldsData) {
        preparedData[`${field}_keys`] = preparedData[field];
        preparedData[field] = item.relatedFieldsData[field].map((fieldData) => {
          const fieldDataArray = [
            ...(!isArray(fieldData) ? [fieldData] : fieldData),
          ];
          return fieldDataArray.map((dataItem) => {
            if (isObject(dataItem)) {
              const imageUrl = getFileUploadImage(dataItem);
              return (
                <p>
                  <img src={imageUrl} alt="pic" className={'tableImage'} />
                </p>
              );
            }
            return <p>{dataItem?.toString()}</p>;
          });
        });
      }
    }
    return preparedData;
  });
};

export const getAllExpandableChildrenIds = (leafEntity) => {
  const stack = [...leafEntity.children];
  const result = [];
  while (stack.length > 0) {
    const currItem = stack.pop();
    if (Boolean(currItem.children) && currItem.children.length > 0) {
      stack.push(...currItem.children);
      result.push(currItem.key);
    }
  }
  return result;
};

const getSubTree = (entityDataList, titleField, parentId = null) => {
  if (typeof entityDataList?.length === 'undefined') {
    return [];
  }

  const idMapping = entityDataList.reduce((acc, el, i) => {
    acc[el._id] = i;
    return acc;
  }, {});
  let roots = [];
  const dictionary = {};
  entityDataList
    .map((entityInfo) => ({
      title: entityInfo[titleField],
      key: entityInfo._id,
      parentId: entityInfo.parentId,
      children: [],
      associations: entityInfo.associations ?? [],
      'data-treenode-id': entityInfo._id,
    }))
    .forEach((entityInfo, index, array) => {
      // Handle the root element
      const isRoot =
        parentId === null
          ? typeof entityInfo.parentId === 'undefined' ||
            entityInfo.parentId === null
          : entityInfo.parentId === parentId;
      dictionary[entityInfo.key] = entityInfo;
      if (isRoot) {
        entityInfo.parent = null;
        roots = [...roots, entityInfo];
        return;
      }
      const parentEl = array[idMapping[entityInfo.parentId]];
      if (parentEl) {
        parentEl.children = [...(parentEl.children || []), entityInfo];
        entityInfo.parent = parentEl;
      }
    });
  return [roots, dictionary];
};

/**
 * @returns {[any, Record<string, NormalizedEntityData>]}
 */
const useTreeData = (entityDataList, titleField = 'name', parentId = null) => {
  return useMemo(() => {
    if (typeof entityDataList?.length === 'undefined') return [];
    return getSubTree(entityDataList, titleField, parentId);
  }, [entityDataList, parentId, titleField]);
};

/**
 * Get all childs items
 * @param rootEntities
 * @return {[]}
 */
export const getLeafs = (rootEntities) => {
  const stack = [...rootEntities];
  const results = [];
  while (stack.length > 0) {
    const currItem = stack.pop();
    if (
      typeof currItem.children === 'undefined' ||
      currItem.children.length === 0
    ) {
      results.push(currItem);
    } else {
      stack.push(...currItem.children);
      results.push(currItem);
    }
  }
  return results;
};

export const useSearch = (rootEntities, searchString) => {
  const result = useMemo(() => {
    if (!searchString) {
      return rootEntities;
    }

    const searchStringLowerCase = searchString.toLowerCase();
    rootEntities.forEach((t) => {
      t.isSearchPartially = false;
      t.isSearchFull =
        t.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
    });

    const leafs = getLeafs(rootEntities.filter((it) => !it.isSearchFull));
    const parents = [];

    leafs.forEach((leaf) => {
      const isFound =
        leaf.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
      if (isFound && leaf.parent !== null) parents.push(leaf.parent);
      leaf.isSearchFull = isFound;
    });

    while (parents.length > 0) {
      const currItem = parents.pop();
      currItem.isSearchPartially = true;
      if (currItem.parent !== null) {
        parents.push(currItem.parent);
      }
    }

    const newRootEntities = rootEntities.filter(
      (x) => x.isSearchFull || x.isSearchPartially
    );

    const newEntities = [...newRootEntities];

    while (newEntities.length > 0) {
      const currItem = newEntities.pop();
      currItem.isSearchFull =
        currItem.title.toLowerCase().indexOf(searchStringLowerCase) > -1;
      if (currItem.isSearchFull) {
        continue;
      }

      if (
        typeof currItem !== 'undefined' &&
        typeof currItem.children !== 'undefined'
      ) {
        currItem.children = currItem.children.filter(
          (x) => x.isSearchFull || x.isSearchPartially
        );
        newEntities.push(...currItem.children);
      }
    }

    return newRootEntities;
  }, [rootEntities, searchString]);
  return result;
};

const treeData = (entityDataList, titleField) =>
  getSubTree(entityDataList, titleField)[0];

/**
 * Get field that used as API ID for current entity
 *
 * @return {*}
 */
const getApiIdField = (client, type) => {
  if (type && client.sections[type]) {
    for (const section of client.sections[type]) {
      for (const property of section?.properties) {
        if (property?.settings?.fieldOptions?.isApiId === true) {
          return property?.settings?.key;
        }
      }
    }
  }
};

/**
 * Get root id for entity tree structure
 *
 * @param {string} id
 * @param {array} entityList
 * @return {*}
 */
const getRootId = (id, entityList) => {
  const item = entityList.find((entity) => entity._id === id);
  if (item.core.parentId === null) {
    return item._id;
  }
  return getRootId(item.core.parentId, entityList);
};

const ENTITY_TYPE_ATTRIBUTE = 'attribute';
const ENTITY_TYPE_CATEGORY = 'category';
const ENTITY_TYPE_PRODUCT = 'product';

const getDefaultValue = (item) => {
  if (
    item?.propertyType?.title === PROPERTY_TYPE_RELATION ||
    item?.type === PROPERTY_TYPE_RELATION
  ) {
    return (
      <EntityTableDisplay
        key={`${item?.settings?.key}ETD`}
        type={item?.settings?.entity}
        section={item?.section?.key}
        labelField={item?.settings?.entityLabelField}
        labelFieldType={item?.settings?.entityLabelFieldType}
        values={
          _.isArray(item?.settings?.defaultValue)
            ? item?.settings?.defaultValue
            : item?.settings?.defaultValue?.split(',')
        }
      />
    );
  }
  if (
    item?.propertyType?.title === PROPERTY_TYPE_UPLOAD ||
    item?.type === PROPERTY_TYPE_UPLOAD
  ) {
    return (
      _.isArray(item?.settings?.defaultValue) ? item.settings.defaultValue : []
    ).map((file) => {
      const imageUrl = getFileUploadImage(file);

      return (
        <p>
          <img src={imageUrl} alt="pic" className={'tableImage'} />
        </p>
      );
    });
  }
  if (item?.propertyType?.title === PROPERTY_TYPE_DECIMAL) {
    return 'decimal';
  }
  return item?.settings?.defaultValue?.toString();
};

/**
 * Get all children with nested
 * @param {string} id
 * @param {Object[]} entities
 * @return {*}
 */
const getAllChildren = (id, entities) => {
  if (!id) {
    return [];
  }
  const subEntities = entities.filter(
    (item) => item.core.parentId?.toString() === id.toString()
  );

  const subSub = subEntities
    .map((item) => getAllChildren(item._id, entities))
    .flat();
  return [...subEntities, ...subSub];
};

/**
 * Get list of system entities
 * @return {[string, string, string]}
 */
const getSystemEntities = () => {
  return [ENTITY_TYPE_ATTRIBUTE, ENTITY_TYPE_CATEGORY, ENTITY_TYPE_PRODUCT];
};

/**
 * System entity labels
 * @type {{"[ENTITY_TYPE_PRODUCT]": string, get: ((function(*): (*|string))|*), "[ENTITY_TYPE_ATTRIBUTE]": string, "[ENTITY_TYPE_CATEGORY]": string}}
 */
const SYSTEM_ENTITY_LABEL = {
  [ENTITY_TYPE_ATTRIBUTE]: 'Attribute',
  [ENTITY_TYPE_CATEGORY]: 'Category',
  [ENTITY_TYPE_PRODUCT]: 'Product',
  get: (type) => {
    if (SYSTEM_ENTITY_LABEL[type]) {
      return SYSTEM_ENTITY_LABEL[type];
    }
    return 'N/A';
  },
};

/**
 * Get label for entity by type
 *
 * @param type
 * @param client
 * @return {*|string}
 */
const getLabelByEntityType = (type, client) => {
  if (getSystemEntities().includes(type)) {
    return SYSTEM_ENTITY_LABEL.get(type);
  }

  return client.entities.find((entity) => entity.slug === type)?.label;
};

export {
  sortByOrder,
  sortByField,
  capitalize,
  buildSlug,
  useBeforeUnload,
  normalizeEntity,
  treeData,
  useTreeData,
  getApiIdField,
  getRootId,
  ENTITY_TYPE_ATTRIBUTE,
  ENTITY_TYPE_CATEGORY,
  ENTITY_TYPE_PRODUCT,
  getDefaultValue,
  getAllChildren,
  getSystemEntities,
  CORE_NAME_PROPERTY,
  getLabelByEntityType,
};
