import { CHECK_VALUE_EXISTS } from '@marketreach/services/apollo/entity';

const build = (settings, usedValues = {}, systemData = {}) => [
  {
    validator: async (_, value) => {
      if (!settings) return Promise.resolve();

      const { apolloClient, entity, client, type, dataKey } = systemData;

      return new Promise(async (resolve, reject) => {
        const { data } = await apolloClient.query({
          query: CHECK_VALUE_EXISTS,
          variables: {
            clientCode: client.apiId,
            entityType: type,
            key: dataKey,
            value: [value],
            id: entity?._id,
          },
          fetchPolicy: 'network-only',
        });

        return data.checkValueExists.data[0] ? reject() : resolve();
      });
    },
    message: 'This value already used',
  },
];

export default build;
