import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { ConfigProvider } from 'antd';
import enAntd from 'antd/lib/locale-provider/en_US';
import 'froala-editor/js/plugins.pkgd.min.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import PropertyManagerProvider from '@marketreach/providers/PropertyManagerProvider';
import RulesManagerProvider from '@marketreach/providers/RulesManagerProvider';
import TaxonomyProvider from '@marketreach/providers/TaxonomyProvider';

import App from './App';
import './index.less';
import { ClientsProvider } from './providers/ClientsProvider';
import UserProvider from './providers/UserProvider';
import * as serviceWorker from './serviceWorker';
import { errorLink } from './utils/errors';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

const { REACT_APP_APOLLO_URI } = process.env;

const buildApolloClient = (auth0Client) => {
  const authLink = new ApolloLink(async (operation, forward) => {
    let token = '';
    if (auth0Client.isAuthenticated) {
      try {
        token = await auth0Client.getAccessTokenSilently();
      } catch (e) {
        if (e.error === 'login_required') {
          auth0Client.loginWithRedirect();
        }
        if (e.error === 'consent_required') {
          auth0Client.loginWithRedirect();
        }
        throw e;
      }
    }

    const authHeaders = token ? { authorization: `Bearer ${token}` } : {};

    operation.setContext(({ headers }) => ({
      headers: {
        ...authHeaders,
        ...headers,
      },
    }));
    return forward(operation);
  });

  const link = from([
    authLink,
    errorLink,
    new HttpLink({
      uri: REACT_APP_APOLLO_URI,
    }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}/callback`}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    scope="profile read:current_user update:current_user_metadata"
  >
    <Auth0Context.Consumer>
      {(auth0Client) => {
        return (
          <ApolloProvider client={buildApolloClient(auth0Client)}>
            <Router>
              <ConfigProvider locale={enAntd}>
                <UserProvider>
                  <ClientsProvider>
                    <TaxonomyProvider>
                      <PropertyManagerProvider>
                        <RulesManagerProvider>
                          <App />
                        </RulesManagerProvider>
                      </PropertyManagerProvider>
                    </TaxonomyProvider>
                  </ClientsProvider>
                </UserProvider>
              </ConfigProvider>
            </Router>
          </ApolloProvider>
        );
      }}
    </Auth0Context.Consumer>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
