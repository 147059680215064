import { useMutation, useQuery } from '@apollo/client';
import produce from 'immer';

import { useSelectedClient } from '@marketreach/providers/ClientsProvider';
import {
  GET_SETTING,
  GET_SETTING_QUERY,
  SET_SETTING,
} from '@marketreach/services/apollo/user';

type SetSettingVariables = {
  key: string;
  value: any;
  clientCode: string;
};

/**
 * Hook for saving a setting to BE
 */
export const useSetSetting = (settingKey: string) => {
  const client = useSelectedClient();

  const [setSetting, mutationResults] = useMutation<any, SetSettingVariables>(
    SET_SETTING
  );
  const saveSetting = (settingData: any) => {
    setSetting({
      variables: {
        key: settingKey,
        value: settingData,
        clientCode: client.apiId,
      },
      refetchQueries: [GET_SETTING_QUERY],
      optimisticResponse: ({ value }: SetSettingVariables) => ({
        setSetting: {
          success: true,
          error: null,
          data: [
            {
              value,
            },
          ],
          __typename: 'MutationResult',
        },
      }),
      update: (proxy, _, { variables }) => {
        const queryVars = { key: settingKey, clientCode: client?.apiId };

        const oldData: any = proxy.readQuery({
          query: GET_SETTING,
          variables: queryVars,
        });

        const nextState = produce(oldData, (draftState: any) => {
          if (draftState.getSetting.data) {
            draftState.getSetting.data.value = variables?.value;
          }
        });

        proxy.writeQuery({
          query: GET_SETTING,
          variables: queryVars,
          data: nextState,
        });
      },
    });
  };

  return [saveSetting, mutationResults] as const;
};

/**
 * Hook to get a setting from BE
 */
export const useSetting = (settingKey: string) => {
  const client = useSelectedClient();

  const queryResult = useQuery(GET_SETTING, {
    variables: {
      key: settingKey,
      clientCode: client?.apiId,
    },
  });

  // Modify data to always return old data while loading
  return { ...queryResult, data: queryResult.data ?? queryResult.previousData };
};
